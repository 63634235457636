import { createSlice } from '@reduxjs/toolkit';
import { PageContextEnum } from 'features/enums';

export type ListFilterState = {
  filterInEditing: boolean | null;
  pages: {
    [key in PageContextEnum]: {
      selectedFilter: any;
      isFilterMutated: boolean;
    };
  };
};

const initialState: ListFilterState = {
  filterInEditing: null,
  pages: Object.fromEntries(
    Object.entries(PageContextEnum).map((value: [string, PageContextEnum]) => [
      value[1] as any,
      {
        selectedFilter: null,
        isFilterMutated: false,
      },
    ])
  ),
};

export const listFilterSlice = createSlice({
  name: 'listFilter',
  initialState,
  reducers: {
    selectFilter: (state, action) => {
      const { item, context } = action.payload;

      if (state.pages[context].selectedFilter?.id === item.id) {
        return state;
      }

      state.pages[context].selectedFilter = item;
      state.pages[context].isFilterMutated = false;
      // state.pages[context].selectedFilter.concat(item);
    },
    removeFilterItem: (state, action) => {
      const { id, context } = action.payload;

      const filters = state.pages[context].selectedFilter.filters.filter(
        (el) => el.field !== id
      );

      state.pages[context] =
        filters.length !== 0
          ? {
              selectedFilter: {
                ...state.pages[context].selectedFilter,
                filters: state.pages[context].selectedFilter.filters.filter(
                  (el) => el.field !== id
                ),
              },
              isFilterMutated: true,
            }
          : {
              selectedFilter: null,
              isFilterMutated: false,
            };
    },
    setSelectedFilterDirty: (state, action) => {
      const { context } = action.payload;

      state.pages[context].isFilterMutated = true;
    },
    resetFilters: (state, action) => {
      const { context } = action.payload;
      state.pages[context] = {
        selectedFilter: null,
        isFilterMutated: false,
      };
    },
    setFilterInEditing: (state, action) => {
      const { item } = action.payload;
      state.filterInEditing = item;
    },
    resetFilterInEditing: (state) => {
      state.filterInEditing = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  selectFilter,
  removeFilterItem,
  resetFilters,
  setFilterInEditing,
  resetFilterInEditing,
  setSelectedFilterDirty,
} = listFilterSlice.actions;

export default listFilterSlice.reducer;
