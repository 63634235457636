/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { _Includes } from '../models/_Includes';
import type { Contact } from '../models/Contact';
import type { Customer, SevDeskCustomer } from '../models/Customer';
import type { HistoryRecord } from '../models/HistoryRecord';
import type { ListResponse } from '../models/ListResponse';
import type { Note } from '../models/Note';
import type { Property } from '../models/Property';

import { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

import { UUIDService } from './UUIDService';

export class CustomersService {
    /**
     * Get a list of `Customer`s.
     * @returns any A paginated list of `Customer`s.
     * @throws ApiError
     */
    public static getCustomers({
        include,
        pageSize,
        pageNumber,
        search,
        filter,
        sort,
    }: {
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
        /** Number of items to return per page. **/
        pageSize?: number,
        /** Number of the page to load. **/
        pageNumber?: number,
        /** Search string. **/
        search?: string,
        /** Filteroptionen. **/
        filter?: any,
        /** Sortierparameter.
         *
         * Es kann nach mehreren Attributen nacheinander sortiert werden. Dazu
         * werden die zu verwendenen Attribute als Komma-getrenntes Array
         * übergeben.
         *
         * Standardmäßig wird aufsteigend sortiert. Um die Reihenfolge umzukehren,
         * kann ein Minus vor den Attributnamen gesetzt werden.
         *
         * Beispiel: `?sort=-birthday,lastname` => Jüngste zuerst, Personen mit
         * gleichem Geburtstag werden nach Name sortiert.
         *  **/
        sort?: Array<string>,
    }): CancelablePromise<(ListResponse & {
        data?: Array<Customer>;
    })> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/customers',
            query: {
                'include': include,
                'page[size]': pageSize,
                'page[number]': pageNumber,
                'search': search,
                'filter': filter,
                'sort': sort,
            },
        });
    }

    /**
     * Get a list of `Customer`s from SevDesk.
     * @returns any A list of `Customer`s.
     * @throws ApiError
     */
    public static getSevDeskCustomers(): CancelablePromise<(ListResponse & {
        data?: Array<any>;
    })> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sevdesk/customers',
        });
    }

    public static getCombinedCustomers(): CancelablePromise<
    ListResponse & {
      data?: Array<any>;
    }
  > {
    return new CancelablePromise(async (resolve, reject, onCancel) => {
      try {
        const sevDeskCustomers: { data:  SevDeskCustomer[] } = await __request(OpenAPI, {
          method: 'GET',
          url: '/sevdesk/customers',
          mediaType: 'application/json',
        });

        // Format the customersResponse
        const formattedSevDeskCustomersResponse = {
          ...sevDeskCustomers,
          data: sevDeskCustomers.data?.map((customer: SevDeskCustomer) => ({
            id: UUIDService.generateUUIDFromID(customer.id),
            referenceNumber: customer.id,
            name1: customer.name ?? '/',
            firstName: customer.surename ?? '/',
            lastName: customer.familyname ?? '/',
            street: customer.addresses?.[0].street ?? '/',
            postcode: customer.addresses?.[0].zip ?? '/',
            city: customer.addresses?.[0].city ?? '/',
            tags: [
              {
                id: customer.id,
                name: 'SevDesk Customer',
              },
            ],
          })),
        };

        // Make another request to '/customers'
        const customers: { data: Customer[] } = await __request(OpenAPI, {
          method: 'GET',
          url: '/customers',
          mediaType: 'application/json',
        });

        // Combine the responses
        const combinedResponse = {
          ...customers,
          data: [...formattedSevDeskCustomersResponse.data, ...customers.data],
        };

        resolve(combinedResponse);
      } catch (error) {
        reject(error);
      }
    });
  }
    

    /**
     * Create a new `Customer`.
     * @returns any A single `Customer`.
     * @throws ApiError
     */
    public static addCustomer({
        requestBody,
    }: {
        /** A `Customer` JSON object. **/
        requestBody?: Customer,
    }): CancelablePromise<{
        data?: Customer;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/customers',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get a single `Customer` identified by the given ID.
     * @returns any A single `Customer`.
     * @throws ApiError
     */
    public static getCustomerById({
        id,
        include,
    }: {
        /** ID of the resource. **/
        id: string,
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
    }): CancelablePromise<{
        data?: Customer;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/customers/{id}',
            path: {
                'id': id,
            },
            query: {
                'include': include,
            },
        });
    }

    /**
     * Update the Customer identified by the given ID.
     * @returns any A single `Customer`.
     * @throws ApiError
     */
    public static updateCustomer({
        id,
        requestBody,
    }: {
        /** ID of the resource. **/
        id: string,
        /** The `Customer` object data to update.
         * - Send changed attributes only to minimize the risk of collisions during parallel updates. **/
        requestBody?: Customer,
    }): CancelablePromise<{
        data?: Customer;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/customers/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete the Customer identified by the given ID.
     * @returns void
     * @throws ApiError
     */
    public static deleteCustomer({
        id,
        force,
    }: {
        /** ID of the resource. **/
        id: string,
        /** Erzwingt die Durchführung des Requests, zum Beispiel endgültige Löschung
         * von soft deleted Entitäten.
         *
         * Aktiviert durch die Werte `true`, `1` oder `yes`. **/
        force?: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/customers/{id}',
            path: {
                'id': id,
            },
            query: {
                'force': force,
            },
        });
    }

    /**
     * @deprecated
     * Get a paginated list of `Contact`s.
     * @returns any A paginated list of `Contact`s.
     * @throws ApiError
     */
    public static getCustomerContacts({
        id,
        include,
        pageSize,
        pageNumber,
        filter,
        sort,
    }: {
        /** ID of the resource. **/
        id: string,
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
        /** Number of items to return per page. **/
        pageSize?: number,
        /** Number of the page to load. **/
        pageNumber?: number,
        /** Filteroptionen. **/
        filter?: any,
        /** Sortierparameter.
         *
         * Es kann nach mehreren Attributen nacheinander sortiert werden. Dazu
         * werden die zu verwendenen Attribute als Komma-getrenntes Array
         * übergeben.
         *
         * Standardmäßig wird aufsteigend sortiert. Um die Reihenfolge umzukehren,
         * kann ein Minus vor den Attributnamen gesetzt werden.
         *
         * Beispiel: `?sort=-birthday,lastname` => Jüngste zuerst, Personen mit
         * gleichem Geburtstag werden nach Name sortiert.
         *  **/
        sort?: Array<string>,
    }): CancelablePromise<(ListResponse & {
        data?: Array<Contact>;
    })> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/customers/{id}/contacts',
            path: {
                'id': id,
            },
            query: {
                'include': include,
                'page[size]': pageSize,
                'page[number]': pageNumber,
                'filter': filter,
                'sort': sort,
            },
        });
    }

    /**
     * Get a paginated list of `HistoryRecord`s.
     * @returns any A paginated list of `HistoryRecord`s.
     * @throws ApiError
     */
    public static getCustomerHistory({
        id,
        include,
        pageSize,
        pageNumber,
        filter,
        sort,
    }: {
        /** ID of the resource. **/
        id: string,
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
        /** Number of items to return per page. **/
        pageSize?: number,
        /** Number of the page to load. **/
        pageNumber?: number,
        /** Filteroptionen. **/
        filter?: any,
        /** Sortierparameter.
         *
         * Es kann nach mehreren Attributen nacheinander sortiert werden. Dazu
         * werden die zu verwendenen Attribute als Komma-getrenntes Array
         * übergeben.
         *
         * Standardmäßig wird aufsteigend sortiert. Um die Reihenfolge umzukehren,
         * kann ein Minus vor den Attributnamen gesetzt werden.
         *
         * Beispiel: `?sort=-birthday,lastname` => Jüngste zuerst, Personen mit
         * gleichem Geburtstag werden nach Name sortiert.
         *  **/
        sort?: Array<string>,
    }): CancelablePromise<(ListResponse & {
        data?: Array<HistoryRecord>;
    })> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/customers/{id}/history',
            path: {
                'id': id,
            },
            query: {
                'include': include,
                'page[size]': pageSize,
                'page[number]': pageNumber,
                'filter': filter,
                'sort': sort,
            },
        });
    }

    /**
     * @deprecated
     * Get a paginated list of `Note`s.
     * @returns any A paginated list of `Note`s.
     * @throws ApiError
     */
    public static getCustomerNotes({
        id,
        include,
        pageSize,
        pageNumber,
        filter,
        sort,
    }: {
        /** ID of the resource. **/
        id: string,
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
        /** Number of items to return per page. **/
        pageSize?: number,
        /** Number of the page to load. **/
        pageNumber?: number,
        /** Filteroptionen. **/
        filter?: any,
        /** Sortierparameter.
         *
         * Es kann nach mehreren Attributen nacheinander sortiert werden. Dazu
         * werden die zu verwendenen Attribute als Komma-getrenntes Array
         * übergeben.
         *
         * Standardmäßig wird aufsteigend sortiert. Um die Reihenfolge umzukehren,
         * kann ein Minus vor den Attributnamen gesetzt werden.
         *
         * Beispiel: `?sort=-birthday,lastname` => Jüngste zuerst, Personen mit
         * gleichem Geburtstag werden nach Name sortiert.
         *  **/
        sort?: Array<string>,
    }): CancelablePromise<(ListResponse & {
        data?: Array<Note>;
    })> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/customers/{id}/notes',
            path: {
                'id': id,
            },
            query: {
                'include': include,
                'page[size]': pageSize,
                'page[number]': pageNumber,
                'filter': filter,
                'sort': sort,
            },
        });
    }

    /**
     * @deprecated
     * Get a paginated list of `Property`s.
     * @returns any A paginated list of `Property`s.
     * @throws ApiError
     */
    public static getCustomerProperties({
        id,
        include,
        pageSize,
        pageNumber,
        filter,
        sort,
    }: {
        /** ID of the resource. **/
        id: string,
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
        /** Number of items to return per page. **/
        pageSize?: number,
        /** Number of the page to load. **/
        pageNumber?: number,
        /** Filteroptionen. **/
        filter?: any,
        /** Sortierparameter.
         *
         * Es kann nach mehreren Attributen nacheinander sortiert werden. Dazu
         * werden die zu verwendenen Attribute als Komma-getrenntes Array
         * übergeben.
         *
         * Standardmäßig wird aufsteigend sortiert. Um die Reihenfolge umzukehren,
         * kann ein Minus vor den Attributnamen gesetzt werden.
         *
         * Beispiel: `?sort=-birthday,lastname` => Jüngste zuerst, Personen mit
         * gleichem Geburtstag werden nach Name sortiert.
         *  **/
        sort?: Array<string>,
    }): CancelablePromise<(ListResponse & {
        data?: Array<Property>;
    })> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/customers/{id}/properties',
            path: {
                'id': id,
            },
            query: {
                'include': include,
                'page[size]': pageSize,
                'page[number]': pageNumber,
                'filter': filter,
                'sort': sort,
            },
        });
    }

    /**
     * Get all customers in csv
     * Get a csv of `Customs`s.
     * @returns string csv file encoding ISO-8859-1
     * @throws ApiError
     */
    public static getCustomersCsv(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/export/csv/customers',
        });
    }

    /**
     * Get FiBu Customers export in csv
     * Get a csv of `FiBu`.
     * @returns string csv file encoding ISO-8859-1
     * @throws ApiError
     */
    public static getFiBuCustomersCsv({
        filterCsv,
    }: {
        /** Beispiel: `?startDate=2002-06-02&endDate=2004-06-02`
         *  **/
        filterCsv?: Array<string>,
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/export/csv/fibu/customers',
            query: {
                'filterCsv': filterCsv,
            },
        });
    }

}