export enum PageContextEnum {
  APPOINTMENT_MONITORS = 'appointmentMonitors',
  EMPLOYEES = 'employees',
  EMPLOYEE = 'employee',
  EMPLOYEE_DOCUMENTS = 'employeeDocuments',
  CUSTOMERS = 'customers',
  CUSTOMER_PROPERTIES = 'customerProperties',
  CUSTOMER_PROCESSES = 'customerProcesses',
  CUSTOMER_PRICES = 'customerPrices',
  CUSTOMERS_DOCUMENTS = 'customerDocuments',
  CUSTOMER_CONTRACTS = 'customerContracts',
  CONTACTS = 'contacts',
  CONTRACT_DRAFTS_LIST_PAGE = 'contractDraftsListPage',
  CONTRACTS_LIST_PAGE = 'contractsListPage',
  CONTRACT_DOCUMENTS_PAGE = 'contractDocumentsPage',
  CONTRACT_HISTORY_PAGE = 'contractHistoryPage',
  PROPERTIES = 'properties',
  PRODUCT_MANAGEMENT = 'productManagement',
  PRODUCT_DOCUMENTS = 'productDocuments',
  PROPERTIES_PROCESSES = 'propertiesProcesses',
  PROPERTIES_DEFICIENCIES = 'propertiesDeficiencies',
  PROPERTIES_CUSTOMER_SELECTOR = 'propertiesCustomerSelector',
  PROPERTY_DOCUMENTS = 'propertyDocuments',
  PROPERTY_CONTRACTS = 'propertyContracts',
  PROPERTY_PROCESSES = 'propertyProcesses',
  APPOINTMENTS = 'appointments',
  MONITORS = 'monitors',
  MONITORS_PROPERTY_SELECTOR = 'monitorsPropertySelector',
  PROPERTIES_MEASURES = 'propertiesMeasures',
  DEFICIENCIES_AND_INFESTATIONS = 'deficienciesAndInfestations',
  DEFICIENCIES = 'deficiencies',
  DEFICIENCIES_PROPERTY_SELECTOR = 'deficienciesPropertySelector',
  INFESTATIONS = 'infestations',
  INFESTATIONS_PROPERTY_SELECTOR = 'infestationsPropertySelector',
  DOCUMENTATION_OVERVIEW = 'documentationOverview',
  DOCUMENTATION_INCIDENTS = 'documentationIncidents',
  DOCUMENTATION_MONITORS = 'documentationMonitors',
  DOCUMENTATION_REPORTINGS = 'documentationReportings',
  SETTINGS_BRANCHES = 'settingsBranches',
  SETTINGS_SERVICEAREAS = 'settingsServiceAreas',
  SETTINGS_UNITS = 'settingsUnits',
  SETTINGS_MONITORTYPES = 'settingsMonitorTypes',
  SETTINGS_TEXTS = 'settingsTexts',
  ORDERS_LIST_PAGE = 'ordersListPage',
  OFFERS_LIST_PAGE = 'offersListPage',
  JOB_MONITOR_EVENTS = 'jobMonitorEvents',
  INCIDENTS = 'incidents',
}
