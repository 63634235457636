/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ListResponse } from '../models/ListResponse';
import type { MonitorType } from '../models/MonitorType';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MonitorTypesService {

    /**
     * Get a list of monitor types.
     * Get a list of monitor types.
     * @returns any A paginated list of `Monitor type`s.
     * @throws ApiError
     */
    public static getMonitorTypesList({
        pageSize,
        pageNumber,
        filter,
        sort,
    }: {
        /** Number of items to return per page. **/
        pageSize?: number,
        /** Number of the page to load. **/
        pageNumber?: number,
        /** Filteroptionen. **/
        filter?: any,
        /** Sortierparameter.
         *
         * Es kann nach mehreren Attributen nacheinander sortiert werden. Dazu
         * werden die zu verwendenen Attribute als Komma-getrenntes Array
         * übergeben.
         *
         * Standardmäßig wird aufsteigend sortiert. Um die Reihenfolge umzukehren,
         * kann ein Minus vor den Attributnamen gesetzt werden.
         *
         * Beispiel: `?sort=-birthday,lastname` => Jüngste zuerst, Personen mit
         * gleichem Geburtstag werden nach Name sortiert.
         *  **/
        sort?: Array<string>,
    }): CancelablePromise<(ListResponse & {
        data?: Array<MonitorType>;
    })> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/monitor-types',
            query: {
                'page[size]': pageSize,
                'page[number]': pageNumber,
                'filter': filter,
                'sort': sort,
            },
            errors: {
                400: `Bad request. The request is invalid.`,
                401: `Unauthorized.`,
            },
        });
    }

    /**
     * Create new `Monitor type`.
     * Create new monitor type.
     * @returns MonitorType Created `monitor type`
     * @throws ApiError
     */
    public static createMonitorType({
        requestBody,
    }: {
        /** A `Monitor type` to create. **/
        requestBody: MonitorType,
    }): CancelablePromise<MonitorType> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/monitor-types',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request. The request is invalid.`,
                401: `Unauthorized.`,
                422: `Unprocessable entity.`,
            },
        });
    }

    /**
     * Get the `Monitor type` identified by the given ID.
     * Get the Monitor identified by the given ID.
     * @returns MonitorType Requested `monitor type`
     * @throws ApiError
     */
    public static getMonitorTypeById({
        id,
        include,
    }: {
        /** The ID of the `Monitor type` to retrieve. **/
        id: string,
        /** Verwandte Modelle die geladen werden sollen. **/
        include?: Array<'pests'>,
    }): CancelablePromise<MonitorType> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/monitor-types/{id}',
            path: {
                'id': id,
            },
            query: {
                'include': include,
            },
            errors: {
                400: `Bad request. The request is invalid.`,
                401: `Unauthorized.`,
            },
        });
    }

    /**
     * Update `Monitor type`.
     * Update monitor type resource.
     * @returns MonitorType Updated `monitor type`
     * @throws ApiError
     */
    public static updateMonitorType({
        id,
        requestBody,
    }: {
        /** The ID of the `Monitor type` to update. **/
        id: string,
        /** A `Monitor type` to update. **/
        requestBody: MonitorType,
    }): CancelablePromise<MonitorType> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/monitor-types/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request. The request is invalid.`,
                401: `Unauthorized.`,
                404: `Not found. The requested resource does not exist.`,
                422: `Unprocessable entity.`,
            },
        });
    }

    /**
     * Delete `Monitor type`.
     * Delete monitor type from database.
     * @returns void
     * @throws ApiError
     */
    public static deleteMonitorType({
        id,
        force = false,
    }: {
        /** The ID of the `Monitor type` to delete. **/
        id: string,
        /** Force delete the `Monitor type`. **/
        force?: boolean,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/monitor-types/{id}',
            path: {
                'id': id,
            },
            query: {
                'force': force,
            },
            errors: {
                400: `Bad request. The request is invalid.`,
                401: `Unauthorized.`,
                404: `Not found. The requested resource does not exist.`,
            },
        });
    }

}