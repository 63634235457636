//const defaultUrl = 'http://api.staging-pestix.de';
const defaultUrl = process.env.REACT_APP_API_URL;

export default function apiHost() {
  // const possibleEnvs = window.apiEnvironments;

  // const currentUrl = window.localStorage.getItem('activeEnvUrl');

  // if (currentUrl) {
  //   return currentUrl;
  // } else if (!!possibleEnvs && possibleEnvs.length) {
  //   window.localStorage.setItem('activeEnvUrl', possibleEnvs[1].url);
  //   return possibleEnvs[1].url;
  // } else {
  return defaultUrl ?? '';
  // }
}
