/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FilterCollection } from '../models/FilterCollection';
import type { ListResponse } from '../models/ListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FilterCollectionsService {

    /**
     * Get a paginated list of `FilterCollection`s.
     *
     * To retrieve all `FilterCollection`s for a certain list view, set filter `listView` to its key.
     * @returns any A paginated list of `FilterCollection`s.
     * @throws ApiError
     */
    public static getFilterCollections({
        filter,
        pageNumber,
        pageSize,
        sort,
    }: {
        /** Filteroptionen. **/
        filter?: any,
        /** Number of the page to load. **/
        pageNumber?: number,
        /** Number of items to return per page. **/
        pageSize?: number,
        /** Sortierparameter.
         *
         * Es kann nach mehreren Attributen nacheinander sortiert werden. Dazu
         * werden die zu verwendenen Attribute als Komma-getrenntes Array
         * übergeben.
         *
         * Standardmäßig wird aufsteigend sortiert. Um die Reihenfolge umzukehren,
         * kann ein Minus vor den Attributnamen gesetzt werden.
         *
         * Beispiel: `?sort=-birthday,lastname` => Jüngste zuerst, Personen mit
         * gleichem Geburtstag werden nach Name sortiert.
         *  **/
        sort?: Array<string>,
    }): CancelablePromise<(ListResponse & {
        data?: Array<FilterCollection>;
    })> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/filter-collections',
            query: {
                'filter': filter,
                'page[number]': pageNumber,
                'page[size]': pageSize,
                'sort': sort,
            },
        });
    }

    /**
     * Create a new `FilterCollection`.
     * @returns any A single `FilterCollection`.
     * @throws ApiError
     */
    public static createFilterCollection({
        requestBody,
    }: {
        /** A `FilterCollection` JSON object. **/
        requestBody?: FilterCollection,
    }): CancelablePromise<{
        data?: FilterCollection;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/filter-collections',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete the filter collection identified by the given ID.
     * @returns void
     * @throws ApiError
     */
    public static deleteFilterCollection({
        id,
    }: {
        /** ID of the resource. **/
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/filter-collections/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Get the filter collection identified by the given ID.
     * @returns any A single `FilterCollection`.
     * @throws ApiError
     */
    public static getFilterCollectionById({
        id,
    }: {
        /** ID of the resource. **/
        id: string,
    }): CancelablePromise<{
        data?: FilterCollection;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/filter-collections/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Update the filter collection identified by the given ID.
     * @returns any A single `FilterCollection`.
     * @throws ApiError
     */
    public static updateFilterCollection({
        id,
        requestBody,
    }: {
        /** ID of the resource. **/
        id: string,
        /** The `FilterCollection` object data to update.
         * - Send changed attributes only to minimize the risk of collisions during parallel updates. **/
        requestBody?: FilterCollection,
    }): CancelablePromise<{
        data?: FilterCollection;
    }> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/filter-collections/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}