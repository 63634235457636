import { createSlice } from '@reduxjs/toolkit';
import { PaymentTerms } from '_build/ts';

export type Invoiceable = {
  id: string;
  amount: number;
  title: string;
  type: 'auftrag' | 'vertrag' | 'lieferung';
  claimId: number;
  date: string;
  referenceId: string;
};

export type Claim = {
  id: string;
  referenceId: string;
  isDunningBlocked?: boolean;
  dunningBlockReason?: string;
  type: 'vertrag' | 'auftrag' | 'lieferung';
  paymentTerms: PaymentTerms;
  amount: number;
  claimDate: string;
  customer: {
    name: string;
    id: number;
  };
  invoiceables: Array<Invoiceable>;
  payments: Array<{
    id: string;
    amount: number;
    claimId: string;
    date: string;
    type: 'payment';
  }>;
  dunnings: Array<{
    id: string;
    amount: number;
    claimId: string;
    date: string;
    type: 'dunning';
  }>;
  reversals: Array<{
    id: string;
    amount: number;
    claimId: string;
    date: string;
    type: 'reversal';
  }>;
};

export type OpenItemsState = {
  filter: {
    searchValue: string;
    filterValues: Array<'vertrag' | 'auftrag' | 'lieferung'>;
  };

  filteredItems: Array<Claim>;
  items: Array<Claim>;
  selectedItem?: string;
};

const initialState: OpenItemsState = {
  filter: {
    searchValue: '',
    filterValues: [],
  },
  filteredItems: [],
  items: [],
  selectedItem: undefined,
};

export const openItemsSlice = createSlice({
  name: 'invoiceProposals',
  initialState,
  reducers: {
    initOpenItems: (state, action) => {
      const { data } = action.payload;
      state.items = data;
      state.filteredItems = data;
    },
    filterOpenItems: (state, action) => {
      const { searchValue, filterValues } = action.payload;
      state.filter.searchValue = searchValue;
      state.filter.filterValues = filterValues;

      if (filterValues.length)
        state.filteredItems = state.items.filter((el) => filterValues.includes(el.type));

      if (searchValue)
        state.filteredItems = state.items.filter(
          (item) =>
            item.referenceId.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.customer.name.toLowerCase().includes(searchValue.toLowerCase())
        );

      if (!searchValue && !filterValues.length) state.filteredItems = state.items;
    },
    toggleSelectOpenItem: (state, action) => {
      const { id } = action.payload;

      state.selectedItem =
        id === state.selectedItem ? (state.selectedItem = undefined) : id;
    },
  },
});

// Action creators are generated for each case reducer function
export const { initOpenItems, filterOpenItems, toggleSelectOpenItem } =
  openItemsSlice.actions;

export default openItemsSlice.reducer;
