import { useAuth } from 'base-shell/lib/providers/Auth';
import { Actions, RequiredAbility, Subject, useGetAbility } from 'utils/ability';
import { Ability } from '@casl/ability';
import { RoleKey, useHasRole } from 'utils/useHasRole';

export const useIsAuthorized = () => {
  const { auth } = useAuth();
  const { isAuthenticated } = auth;
  const hasRole = useHasRole();

  const getAbility = useGetAbility();

  // if ability is provided, use it. Else create general ability
  // (ability from pages is dependent on passed customerId, employeeId, etc.)
  return ({
    requiredAbilities,
    ability,
    rolesWhitelist,
  }: {
    requiredAbilities: RequiredAbility[];
    ability?: Ability<[Actions, Subject]>;
    rolesWhitelist?: RoleKey[];
  }) => {
    const localAbility = ability || getAbility({});

    return (
      // is logged in
      isAuthenticated &&
      // has the required abilities
      requiredAbilities &&
      requiredAbilities.every((requiredAbility) =>
        localAbility.can(requiredAbility[0], requiredAbility[1])
      ) &&
      // if rolesWhitelist is provided, user has at least one of the roles
      (rolesWhitelist ? rolesWhitelist.some((role) => hasRole(role)) : true)
    );
  };
};
