import { useAuth } from 'base-shell/lib/providers/Auth';
import { selectMappings } from 'config/sharedConfig';

export type RoleKey = (typeof selectMappings.roleMapping)[number]['key'];

export const useHasRole = () => {
  const { auth } = useAuth();
  const roles: string[] = auth?.scope?.roles;

  return (role: RoleKey) => {
    return roles?.includes(role);
  };
};
