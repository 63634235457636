/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { _Includes } from '../models/_Includes';
import type { Appointment } from '../models/Appointment';
import type { AppointmentSummary } from '../models/AppointmentSummary';
import type { HistoryRecord } from '../models/HistoryRecord';
import type { Infestation } from '../models/Infestation';
import type { ListResponse } from '../models/ListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AppointmentsService {

    /**
     * Get all appointments
     * Get a paginated list of `Appointment`s.
     * @returns any A list of `Appointment`es.
     * @throws ApiError
     */
    public static getAppointments({
        pageSize,
        pageNumber,
        filter,
        sort,
        include,
        search,
    }: {
        /** Number of items to return per page. **/
        pageSize?: number,
        /** Number of the page to load. **/
        pageNumber?: number,
        /** Filteroptionen. **/
        filter?: any,
        /** Sortierparameter.
         *
         * Es kann nach mehreren Attributen nacheinander sortiert werden. Dazu
         * werden die zu verwendenen Attribute als Komma-getrenntes Array
         * übergeben.
         *
         * Standardmäßig wird aufsteigend sortiert. Um die Reihenfolge umzukehren,
         * kann ein Minus vor den Attributnamen gesetzt werden.
         *
         * Beispiel: `?sort=-birthday,lastname` => Jüngste zuerst, Personen mit
         * gleichem Geburtstag werden nach Name sortiert.
         *  **/
        sort?: Array<string>,
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
        /** Search string. **/
        search?: string,
    }): CancelablePromise<(ListResponse & {
        data?: Array<Appointment>;
    })> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/appointments',
            query: {
                'page[size]': pageSize,
                'page[number]': pageNumber,
                'filter': filter,
                'sort': sort,
                'include': include,
                'search': search,
            },
        });
    }

    /**
     * Create a new appointment
     * Create a new `Appointment`
     * @returns any A single `Appointment`.
     * @throws ApiError
     */
    public static createAppointment({
        requestBody,
    }: {
        requestBody?: Appointment,
    }): CancelablePromise<{
        data?: Appointment;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/appointments',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Create a new `Incidents` which belongs to `Apponiment`.
     * @returns any A single `Infestation`.
     * @throws ApiError
     */
    public static addIncidentToAppointment({
        id,
        requestBody,
    }: {
        /** ID of the resource. **/
        id: string,
        /** A `Infestation` JSON object. **/
        requestBody?: Infestation,
    }): CancelablePromise<{
        data?: Infestation;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/appointmests/{id}/incidents',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get an appointment
     * Get an `Appointment` by its ID
     * @returns any A single `Appointment`.
     * @throws ApiError
     */
    public static getAppointment({
        id,
        include,
    }: {
        /** ID of the resource. **/
        id: string,
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
    }): CancelablePromise<{
        data?: Appointment;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/appointments/{id}',
            path: {
                'id': id,
            },
            query: {
                'include': include,
            },
        });
    }

    /**
     * Update an appointment
     * Update an `Appointment` by its ID
     * @returns any A single `Appointment`.
     * @throws ApiError
     */
    public static updateAppointment({
        id,
        requestBody,
    }: {
        /** ID of the resource. **/
        id: string,
        requestBody?: Appointment,
    }): CancelablePromise<{
        data?: Appointment;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/appointments/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete an appointment
     * Delete an `Appointment` by its ID
     * @returns void
     * @throws ApiError
     */
    public static deleteAppointment({
        id,
    }: {
        /** ID of the resource. **/
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/appointments/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Get a paginated list of `HistoryRecord`s.
     * @returns any A paginated list of `HistoryRecord`s.
     * @throws ApiError
     */
    public static getAppointmentHistory({
        id,
        include,
        pageSize,
        pageNumber,
        filter,
        sort,
    }: {
        /** ID of the resource. **/
        id: string,
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
        /** Number of items to return per page. **/
        pageSize?: number,
        /** Number of the page to load. **/
        pageNumber?: number,
        /** Filteroptionen. **/
        filter?: any,
        /** Sortierparameter.
         *
         * Es kann nach mehreren Attributen nacheinander sortiert werden. Dazu
         * werden die zu verwendenen Attribute als Komma-getrenntes Array
         * übergeben.
         *
         * Standardmäßig wird aufsteigend sortiert. Um die Reihenfolge umzukehren,
         * kann ein Minus vor den Attributnamen gesetzt werden.
         *
         * Beispiel: `?sort=-birthday,lastname` => Jüngste zuerst, Personen mit
         * gleichem Geburtstag werden nach Name sortiert.
         *  **/
        sort?: Array<string>,
    }): CancelablePromise<(ListResponse & {
        data?: Array<HistoryRecord>;
    })> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/appointments/{id}/history',
            path: {
                'id': id,
            },
            query: {
                'include': include,
                'page[size]': pageSize,
                'page[number]': pageNumber,
                'filter': filter,
                'sort': sort,
            },
        });
    }

    /**
     * Get an appointment summary
     * Get an `AppointmentSummary` by Appointment ID
     * @returns any A single `Appointment`.
     * @throws ApiError
     */
    public static getAppointmentSummary({
        id,
        include,
    }: {
        /** ID of the resource. **/
        id: string,
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
    }): CancelablePromise<{
        data?: AppointmentSummary;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/appointments/{id}/summary',
            path: {
                'id': id,
            },
            query: {
                'include': include,
            },
        });
    }

    /**
     * Update an appointment summary
     * Update an `AppointmentSummary` by Appointment ID
     * @returns any A single `Appointment`.
     * @throws ApiError
     */
    public static updateAppointmentSummary({
        id,
        requestBody,
    }: {
        /** ID of the resource. **/
        id: string,
        requestBody?: AppointmentSummary,
    }): CancelablePromise<{
        data?: AppointmentSummary;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/appointments/{id}/summary',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}