/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { _Includes } from '../models/_Includes';
import type { ExtendedPrice } from '../models/ExtendedPrice';
import type { HistoryRecord } from '../models/HistoryRecord';
import type { ListResponse } from '../models/ListResponse';
import type { Manufacturer } from '../models/Manufacturer';
import type { Note } from '../models/Note';
import type { Price } from '../models/Price';
import type { Product, SevDeskProduct } from '../models/Product';
import type { Supplier } from '../models/Supplier';
import type { Unit } from '../models/Unit';

import { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import { UUIDService } from './UUIDService';

export class ProductManagementService {

    /**
     * Get a list of `ExtendedPrice`s.
     * @returns any A paginated list of `ExtendedPrice`s.
     * @throws ApiError
     */
    public static getExtendedPrices({
        include,
        pageSize,
        pageNumber,
        filter,
        sort,
    }: {
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
        /** Number of items to return per page. **/
        pageSize?: number,
        /** Number of the page to load. **/
        pageNumber?: number,
        /** Filteroptionen. **/
        filter?: any,
        /** Sortierparameter.
         *
         * Es kann nach mehreren Attributen nacheinander sortiert werden. Dazu
         * werden die zu verwendenen Attribute als Komma-getrenntes Array
         * übergeben.
         *
         * Standardmäßig wird aufsteigend sortiert. Um die Reihenfolge umzukehren,
         * kann ein Minus vor den Attributnamen gesetzt werden.
         *
         * Beispiel: `?sort=-birthday,lastname` => Jüngste zuerst, Personen mit
         * gleichem Geburtstag werden nach Name sortiert.
         *  **/
        sort?: Array<string>,
    }): CancelablePromise<(ListResponse & {
        data?: Array<ExtendedPrice>;
    })> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/product-management/extended-prices',
            query: {
                'include': include,
                'page[size]': pageSize,
                'page[number]': pageNumber,
                'filter': filter,
                'sort': sort,
            },
        });
    }

    /**
     * Create a new `ExtendedPrice`.
     * @returns any A single `Property`.
     * @throws ApiError
     */
    public static addExtendedPrice({
        requestBody,
    }: {
        /** A `ExtendedPrice` JSON object. **/
        requestBody?: ExtendedPrice,
    }): CancelablePromise<{
        data?: ExtendedPrice;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/product-management/extended-prices',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get a single `ExtendedPrice` identified by `id`.
     * @returns any A single `Property`.
     * @throws ApiError
     */
    public static getExtendedPriceById({
        id,
        include,
    }: {
        /** ID of the resource. **/
        id: string,
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
    }): CancelablePromise<{
        data?: ExtendedPrice;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/product-management/extended-prices/{id}',
            path: {
                'id': id,
            },
            query: {
                'include': include,
            },
        });
    }

    /**
     * Update the `ExtendedPrice` identified by `id`.
     * @returns any A single `Property`.
     * @throws ApiError
     */
    public static updateExtendedPrice({
        id,
        requestBody,
    }: {
        /** ID of the resource. **/
        id: string,
        /** The `ExtendedPrice` object data to update.
         * - Send changed attributes only to minimize the risk of collisions during parallel updates. **/
        requestBody?: ExtendedPrice,
    }): CancelablePromise<{
        data?: ExtendedPrice;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/product-management/extended-prices/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete the `ExtendedPrice` identified by `id`.
     * @returns void
     * @throws ApiError
     */
    public static deleteExtendedPrice({
        id,
        force,
    }: {
        /** ID of the resource. **/
        id: string,
        /** Erzwingt die Durchführung des Requests, zum Beispiel endgültige Löschung
         * von soft deleted Entitäten.
         *
         * Aktiviert durch die Werte `true`, `1` oder `yes`. **/
        force?: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/product-management/extended-prices/{id}',
            path: {
                'id': id,
            },
            query: {
                'force': force,
            },
        });
    }

    /**
     * Get a list of `Manufacturer`s.
     * @returns any A paginated list of `Manufacturer`s.
     * @throws ApiError
     */
    public static getManufacturers({
        include,
        pageSize,
        pageNumber,
        filter,
        sort,
    }: {
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
        /** Number of items to return per page. **/
        pageSize?: number,
        /** Number of the page to load. **/
        pageNumber?: number,
        /** Filteroptionen. **/
        filter?: any,
        /** Sortierparameter.
         *
         * Es kann nach mehreren Attributen nacheinander sortiert werden. Dazu
         * werden die zu verwendenen Attribute als Komma-getrenntes Array
         * übergeben.
         *
         * Standardmäßig wird aufsteigend sortiert. Um die Reihenfolge umzukehren,
         * kann ein Minus vor den Attributnamen gesetzt werden.
         *
         * Beispiel: `?sort=-birthday,lastname` => Jüngste zuerst, Personen mit
         * gleichem Geburtstag werden nach Name sortiert.
         *  **/
        sort?: Array<string>,
    }): CancelablePromise<(ListResponse & {
        data?: Array<Manufacturer>;
    })> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/product-management/manufacturers',
            query: {
                'include': include,
                'page[size]': pageSize,
                'page[number]': pageNumber,
                'filter': filter,
                'sort': sort,
            },
        });
    }

    /**
     * Create a new `Manufacturer`.
     * @returns any A single `Manufacturer`.
     * @throws ApiError
     */
    public static addManufacturer({
        requestBody,
    }: {
        /** A `Manufacturer` JSON object. **/
        requestBody?: Manufacturer,
    }): CancelablePromise<{
        data?: Manufacturer;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/product-management/manufacturers',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get a single `Manufacturer` identified by `id`.
     * @returns any A single `Manufacturer`.
     * @throws ApiError
     */
    public static getManufacturerById({
        id,
        include,
    }: {
        /** ID of the resource. **/
        id: string,
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
    }): CancelablePromise<{
        data?: Manufacturer;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/product-management/manufacturers/{id}',
            path: {
                'id': id,
            },
            query: {
                'include': include,
            },
        });
    }

    /**
     * Update the `Manufacturer` identified by `id`.
     * @returns any A single `Manufacturer`.
     * @throws ApiError
     */
    public static updateManufacturer({
        id,
        requestBody,
    }: {
        /** ID of the resource. **/
        id: string,
        /** The `Manufacturer` object data to update.
         * - Send changed attributes only to minimize the risk of collisions during parallel updates. **/
        requestBody?: Manufacturer,
    }): CancelablePromise<{
        data?: Manufacturer;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/product-management/manufacturers/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete the `Manufacturer` identified by `id`.
     * @returns void
     * @throws ApiError
     */
    public static deleteManufacturer({
        id,
        force,
    }: {
        /** ID of the resource. **/
        id: string,
        /** Erzwingt die Durchführung des Requests, zum Beispiel endgültige Löschung
         * von soft deleted Entitäten.
         *
         * Aktiviert durch die Werte `true`, `1` oder `yes`. **/
        force?: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/product-management/manufacturers/{id}',
            path: {
                'id': id,
            },
            query: {
                'force': force,
            },
        });
    }

    /**
     * Get a list of `Price`s.
     * @returns any A paginated list of `Price`s.
     * @throws ApiError
     */
    public static getPrices({
        include,
        pageSize,
        pageNumber,
        filter,
        sort,
    }: {
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
        /** Number of items to return per page. **/
        pageSize?: number,
        /** Number of the page to load. **/
        pageNumber?: number,
        /** Filteroptionen. **/
        filter?: any,
        /** Sortierparameter.
         *
         * Es kann nach mehreren Attributen nacheinander sortiert werden. Dazu
         * werden die zu verwendenen Attribute als Komma-getrenntes Array
         * übergeben.
         *
         * Standardmäßig wird aufsteigend sortiert. Um die Reihenfolge umzukehren,
         * kann ein Minus vor den Attributnamen gesetzt werden.
         *
         * Beispiel: `?sort=-birthday,lastname` => Jüngste zuerst, Personen mit
         * gleichem Geburtstag werden nach Name sortiert.
         *  **/
        sort?: Array<string>,
    }): CancelablePromise<(ListResponse & {
        data?: Array<Price>;
    })> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/product-management/prices',
            query: {
                'include': include,
                'page[size]': pageSize,
                'page[number]': pageNumber,
                'filter': filter,
                'sort': sort,
            },
        });
    }

    /**
     * Create a new `Price`.
     * @returns any A single `Property`.
     * @throws ApiError
     */
    public static addPrice({
        requestBody,
    }: {
        /** A `Price` JSON object. **/
        requestBody?: Price,
    }): CancelablePromise<{
        data?: Price;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/product-management/prices',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get a single `Price` identified by `id`.
     * @returns any A single `Property`.
     * @throws ApiError
     */
    public static getPriceById({
        id,
        include,
    }: {
        /** ID of the resource. **/
        id: string,
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
    }): CancelablePromise<{
        data?: Price;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/product-management/prices/{id}',
            path: {
                'id': id,
            },
            query: {
                'include': include,
            },
        });
    }

    /**
     * Update the `Price` identified by `id`.
     * @returns any A single `Property`.
     * @throws ApiError
     */
    public static updatePrice({
        id,
        requestBody,
    }: {
        /** ID of the resource. **/
        id: string,
        /** The `Price` object data to update.
         * - Send changed attributes only to minimize the risk of collisions during parallel updates. **/
        requestBody?: Price,
    }): CancelablePromise<{
        data?: Price;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/product-management/prices/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete the `Price` identified by `id`.
     * @returns void
     * @throws ApiError
     */
    public static deletePrice({
        id,
        force,
    }: {
        /** ID of the resource. **/
        id: string,
        /** Erzwingt die Durchführung des Requests, zum Beispiel endgültige Löschung
         * von soft deleted Entitäten.
         *
         * Aktiviert durch die Werte `true`, `1` oder `yes`. **/
        force?: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/product-management/prices/{id}',
            path: {
                'id': id,
            },
            query: {
                'force': force,
            },
        });
    }

    /**
     * Get a list of `Product`s.
     * @returns any A paginated list of `Product`s.
     * @throws ApiError
     */
    public static getProducts({
        include,
        pageSize,
        pageNumber,
        filter,
        sort,
        search,
    }: {
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
        /** Number of items to return per page. **/
        pageSize?: number,
        /** Number of the page to load. **/
        pageNumber?: number,
        /** Filteroptionen. **/
        filter?: any,
        /** Sortierparameter.
         *
         * Es kann nach mehreren Attributen nacheinander sortiert werden. Dazu
         * werden die zu verwendenen Attribute als Komma-getrenntes Array
         * übergeben.
         *
         * Standardmäßig wird aufsteigend sortiert. Um die Reihenfolge umzukehren,
         * kann ein Minus vor den Attributnamen gesetzt werden.
         *
         * Beispiel: `?sort=-birthday,lastname` => Jüngste zuerst, Personen mit
         * gleichem Geburtstag werden nach Name sortiert.
         *  **/
        sort?: Array<string>,
        /** Search string. **/
        search?: string,
    }): CancelablePromise<(ListResponse & {
        data?: Array<Product>;
    })> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/product-management/products',
            query: {
                'include': include,
                'page[size]': pageSize,
                'page[number]': pageNumber,
                'filter': filter,
                'sort': sort,
                'search': search,
            },
        });
    }

    /**
        * Get a list of `Product`s from SevDesk.
        * @returns any A list of `Product`s.
        * @throws ApiError
        */
    public static getSevDeskProducts(): CancelablePromise<
    ListResponse & {
        data?: Array<Product>;
    }
    > {
    return __request(OpenAPI, {
        method: 'GET',
        url: '/sevdesk/products',
        });
    }

    public static getCombinedProducts(): CancelablePromise<
    ListResponse & {
      data?: Array<any>;
    }
    > {
    return new CancelablePromise(async (resolve, reject, onCancel) => {
      try {
        const sevDeskProducts: { data: SevDeskProduct[] } = await __request(OpenAPI, {
          method: 'GET',
          url: '/sevdesk/products',
          mediaType: 'application/json',
        });

        const products: { data: Product[] } = await __request(OpenAPI, {
          method: 'GET',
          url: '/product-management/products',
          mediaType: 'application/json',
        });

        const formattedSevDeskProducts = {
            ...sevDeskProducts,
            data: sevDeskProducts.data?.map((product: SevDeskProduct) => ({
                id: UUIDService.generateUUIDFromID(product.id),
                description: product.description ?? "",
                name: product.name ?? ""
            })).filter((sevDeskProduct: SevDeskProduct) => products.data.every((product: Product) => product.id !== sevDeskProduct.id))
        };

        const combinedResponse = {
          ...products,
          data: [...formattedSevDeskProducts.data, ...products.data],
        };

        resolve(combinedResponse);
        } catch (error) {
            reject(error);
            }
        });
    }

    /**
     * Create a new `Product`.
     * @returns any A single `Property`.
     * @throws ApiError
     */
    public static addProduct({
        requestBody,
    }: {
        /** A `Product` JSON object. **/
        requestBody?: Product,
    }): CancelablePromise<{
        data?: Product;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/product-management/products',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get a single `Product` identified by `id`.
     * @returns any A single `Property`.
     * @throws ApiError
     */
    public static getProductById({
        id,
        include,
    }: {
        /** ID of the resource. **/
        id: string,
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
    }): CancelablePromise<{
        data?: Product;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/product-management/products/{id}',
            path: {
                'id': id,
            },
            query: {
                'include': include,
            },
        });
    }
    
     /**
     * Get a single `Product` identified by the given ID and merge sevdesk data.
     * @returns any A single `Product`.
     * @throws ApiError
     */
    public static getCombinedProductById({
        id,
        isSevDeskProduct,
        include,
    }: {
        /** ID of the resource. **/
        id: string,
        /** ID of the SevDesk resource (if available). **/
        isSevDeskProduct?: boolean | undefined,
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         *  **/
        include?: Array<string>,
    }): CancelablePromise<{
        data?: Product;
        includes?: _Includes;
    }> {
        let url = '/product-management/products/{id}'
        if(isSevDeskProduct){
            url = `/sevdesk/products/{id}`
        }
        return __request(OpenAPI, {
            method: 'GET',
            url: url,
            path: {
                'id': id,
            },
            query: {
                'include': include,
            }
        });
    }


    /**
     * Update the `Product` identified by `id`.
     * @returns any A single `Property`.
     * @throws ApiError
     */
    public static updateProduct({
        id,
        requestBody,
    }: {
        /** ID of the resource. **/
        id: string,
        /** The `Product` object data to update.
         * - Send changed attributes only to minimize the risk of collisions during parallel updates. **/
        requestBody?: Product,
    }): CancelablePromise<{
        data?: Product;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/product-management/products/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete the `Product` identified by `id`.
     * @returns void
     * @throws ApiError
     */
    public static deleteProduct({
        id,
        force,
    }: {
        /** ID of the resource. **/
        id: string,
        /** Erzwingt die Durchführung des Requests, zum Beispiel endgültige Löschung
         * von soft deleted Entitäten.
         *
         * Aktiviert durch die Werte `true`, `1` oder `yes`. **/
        force?: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/product-management/products/{id}',
            path: {
                'id': id,
            },
            query: {
                'force': force,
            },
        });
    }

    /**
     * Get a paginated list of `HistoryRecord`s.
     * @returns any A paginated list of `HistoryRecord`s.
     * @throws ApiError
     */
    public static getProductHistory({
        id,
        include,
        pageSize,
        pageNumber,
        filter,
        sort,
    }: {
        /** ID of the resource. **/
        id: string,
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
        /** Number of items to return per page. **/
        pageSize?: number,
        /** Number of the page to load. **/
        pageNumber?: number,
        /** Filteroptionen. **/
        filter?: any,
        /** Sortierparameter.
         *
         * Es kann nach mehreren Attributen nacheinander sortiert werden. Dazu
         * werden die zu verwendenen Attribute als Komma-getrenntes Array
         * übergeben.
         *
         * Standardmäßig wird aufsteigend sortiert. Um die Reihenfolge umzukehren,
         * kann ein Minus vor den Attributnamen gesetzt werden.
         *
         * Beispiel: `?sort=-birthday,lastname` => Jüngste zuerst, Personen mit
         * gleichem Geburtstag werden nach Name sortiert.
         *  **/
        sort?: Array<string>,
    }): CancelablePromise<(ListResponse & {
        data?: Array<HistoryRecord>;
    })> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/product-management/products/{id}/history',
            path: {
                'id': id,
            },
            query: {
                'include': include,
                'page[size]': pageSize,
                'page[number]': pageNumber,
                'filter': filter,
                'sort': sort,
            },
        });
    }

    /**
     * @deprecated
     * Get a paginated list of `Note`s.
     * @returns any A paginated list of `Note`s.
     * @throws ApiError
     */
    public static getProductNotes({
        id,
        include,
        pageSize,
        pageNumber,
        filter,
        sort,
    }: {
        /** ID of the resource. **/
        id: string,
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
        /** Number of items to return per page. **/
        pageSize?: number,
        /** Number of the page to load. **/
        pageNumber?: number,
        /** Filteroptionen. **/
        filter?: any,
        /** Sortierparameter.
         *
         * Es kann nach mehreren Attributen nacheinander sortiert werden. Dazu
         * werden die zu verwendenen Attribute als Komma-getrenntes Array
         * übergeben.
         *
         * Standardmäßig wird aufsteigend sortiert. Um die Reihenfolge umzukehren,
         * kann ein Minus vor den Attributnamen gesetzt werden.
         *
         * Beispiel: `?sort=-birthday,lastname` => Jüngste zuerst, Personen mit
         * gleichem Geburtstag werden nach Name sortiert.
         *  **/
        sort?: Array<string>,
    }): CancelablePromise<(ListResponse & {
        data?: Array<Note>;
    })> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/product-management/products/{id}/notes',
            path: {
                'id': id,
            },
            query: {
                'include': include,
                'page[size]': pageSize,
                'page[number]': pageNumber,
                'filter': filter,
                'sort': sort,
            },
        });
    }

    /**
     * Get a list of `Supplier`s.
     * @returns any A paginated list of `Supplier`s.
     * @throws ApiError
     */
    public static getSuppliers({
        include,
        pageSize,
        pageNumber,
        filter,
        sort,
    }: {
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
        /** Number of items to return per page. **/
        pageSize?: number,
        /** Number of the page to load. **/
        pageNumber?: number,
        /** Filteroptionen. **/
        filter?: any,
        /** Sortierparameter.
         *
         * Es kann nach mehreren Attributen nacheinander sortiert werden. Dazu
         * werden die zu verwendenen Attribute als Komma-getrenntes Array
         * übergeben.
         *
         * Standardmäßig wird aufsteigend sortiert. Um die Reihenfolge umzukehren,
         * kann ein Minus vor den Attributnamen gesetzt werden.
         *
         * Beispiel: `?sort=-birthday,lastname` => Jüngste zuerst, Personen mit
         * gleichem Geburtstag werden nach Name sortiert.
         *  **/
        sort?: Array<string>,
    }): CancelablePromise<(ListResponse & {
        data?: Array<Supplier>;
    })> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/product-management/suppliers',
            query: {
                'include': include,
                'page[size]': pageSize,
                'page[number]': pageNumber,
                'filter': filter,
                'sort': sort,
            },
        });
    }

    /**
     * Create a new `Supplier`.
     * @returns any A single `Property`.
     * @throws ApiError
     */
    public static addSupplier({
        requestBody,
    }: {
        /** A `Supplier` JSON object. **/
        requestBody?: Supplier,
    }): CancelablePromise<{
        data?: Supplier;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/product-management/suppliers',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get a single `Supplier` identified by `id`.
     * @returns any A single `Property`.
     * @throws ApiError
     */
    public static getSupplierById({
        id,
        include,
    }: {
        /** ID of the resource. **/
        id: string,
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
    }): CancelablePromise<{
        data?: Supplier;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/product-management/suppliers/{id}',
            path: {
                'id': id,
            },
            query: {
                'include': include,
            },
        });
    }

    /**
     * Update the `Supplier` identified by `id`.
     * @returns any A single `Property`.
     * @throws ApiError
     */
    public static updateSupplier({
        id,
        requestBody,
    }: {
        /** ID of the resource. **/
        id: string,
        /** The `Supplier` object data to update.
         * - Send changed attributes only to minimize the risk of collisions during parallel updates. **/
        requestBody?: Supplier,
    }): CancelablePromise<{
        data?: Supplier;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/product-management/suppliers/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete the `Supplier` identified by `id`.
     * @returns void
     * @throws ApiError
     */
    public static deleteSupplier({
        id,
        force,
    }: {
        /** ID of the resource. **/
        id: string,
        /** Erzwingt die Durchführung des Requests, zum Beispiel endgültige Löschung
         * von soft deleted Entitäten.
         *
         * Aktiviert durch die Werte `true`, `1` oder `yes`. **/
        force?: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/product-management/suppliers/{id}',
            path: {
                'id': id,
            },
            query: {
                'force': force,
            },
        });
    }

    /**
     * Get a list of `Unit`s.
     * @returns any A paginated list of `Unit`s.
     * @throws ApiError
     */
    public static getUnits({
        include,
        pageSize,
        pageNumber,
        filter,
        sort,
    }: {
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
        /** Number of items to return per page. **/
        pageSize?: number,
        /** Number of the page to load. **/
        pageNumber?: number,
        /** Filteroptionen. **/
        filter?: any,
        /** Sortierparameter.
         *
         * Es kann nach mehreren Attributen nacheinander sortiert werden. Dazu
         * werden die zu verwendenen Attribute als Komma-getrenntes Array
         * übergeben.
         *
         * Standardmäßig wird aufsteigend sortiert. Um die Reihenfolge umzukehren,
         * kann ein Minus vor den Attributnamen gesetzt werden.
         *
         * Beispiel: `?sort=-birthday,lastname` => Jüngste zuerst, Personen mit
         * gleichem Geburtstag werden nach Name sortiert.
         *  **/
        sort?: Array<string>,
    }): CancelablePromise<(ListResponse & {
        data?: Array<Unit>;
    })> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/product-management/units',
            query: {
                'include': include,
                'page[size]': pageSize,
                'page[number]': pageNumber,
                'filter': filter,
                'sort': sort,
            },
        });
    }

    /**
     * Create a new `Unit`.
     * @returns any A single `Property`.
     * @throws ApiError
     */
    public static addUnit({
        requestBody,
    }: {
        /** A `Unit` JSON object. **/
        requestBody?: Unit,
    }): CancelablePromise<{
        data?: Unit;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/product-management/units',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get a single `Unit` identified by `id`.
     * @returns any A single `Property`.
     * @throws ApiError
     */
    public static getUnitById({
        id,
        include,
    }: {
        /** ID of the resource. **/
        id: string,
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
    }): CancelablePromise<{
        data?: Unit;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/product-management/units/{id}',
            path: {
                'id': id,
            },
            query: {
                'include': include,
            },
        });
    }

    /**
     * Update the `Unit` identified by `id`.
     * @returns any A single `Property`.
     * @throws ApiError
     */
    public static updateUnit({
        id,
        requestBody,
    }: {
        /** ID of the resource. **/
        id: string,
        /** The `Unit` object data to update.
         * - Send changed attributes only to minimize the risk of collisions during parallel updates. **/
        requestBody?: Unit,
    }): CancelablePromise<{
        data?: Unit;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/product-management/units/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete the `Unit` identified by `id`.
     * @returns void
     * @throws ApiError
     */
    public static deleteUnit({
        id,
        force,
    }: {
        /** ID of the resource. **/
        id: string,
        /** Erzwingt die Durchführung des Requests, zum Beispiel endgültige Löschung
         * von soft deleted Entitäten.
         *
         * Aktiviert durch die Werte `true`, `1` oder `yes`. **/
        force?: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/product-management/units/{id}',
            path: {
                'id': id,
            },
            query: {
                'force': force,
            },
        });
    }

}