/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Legal } from '../models/Legal';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LegalService {

    /**
     * Get legal data fields
     * Get legal data fields
     * @returns any A single `Legal`
     * @throws ApiError
     */
    public static getLegal(): CancelablePromise<{
        data?: Legal;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/legal',
        });
    }

}