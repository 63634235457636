import React, { useContext, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from 'base-shell/lib/providers/Auth';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import { useAppSelector } from 'app/hooks';
import { OpenAPI, Settings, SettingsService } from '_build/ts';
import { SystemSettingsContext } from 'app/SystemSettingsStore';
import { useDispatch } from 'react-redux';
import { setIntervalId } from 'features/heartbeat/heartbeatSlice';
import apiHost from 'api/host';
import { useQueryClient } from 'react-query';
import { useIsAuthorized } from 'utils/useIsAuthorized';
import { RequiredAbility, useGetAbility } from 'utils/ability';
import PageNotAllowed from 'components/Authorization/PageNotAllowed';
import { RoleKey } from 'utils/useHasRole';

const getSystemSettings = async () => {
  return (await SettingsService.getSettings({})) as { data: Settings };
};

/* Permissions
 * every route must have requiredAbilities set. Routes can only be accessed if the user
 * has the required abilities or requiredAbilities is an empty array.
 * Optionally, "rolesWhitelist" can be set. If set, the user must have one of the roles.
 */
export type AuthorizedRouteProps = {
  component: any;
  path: string;
  exact: boolean;
  redirectTo?: string;
  requiredAbilities: RequiredAbility[];
  rolesWhitelist?: RoleKey[];
  location?: Location;
  history?: History;
  computedMatch?: {
    params: any;
    isExact: boolean;
    path: string;
    url: string;
  };
};

const AuthorizedRoute = ({
  component: Component,
  redirectTo,
  requiredAbilities,
  rolesWhitelist,
  location,
  computedMatch,
  ...rest
}: AuthorizedRouteProps) => {
  OpenAPI.BASE = `${apiHost()}/api`;
  const { auth, setAuth } = useAuth();

  const { isAuthenticated } = auth;
  OpenAPI.TOKEN = auth.accessToken;

  const { setSystemSettings } = useContext(SystemSettingsContext);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const isAuthorized = useIsAuthorized();

  const updateSystemSettings = async () => {
    const systemSettings = await getSystemSettings();
    setSystemSettings(systemSettings?.data);
    queryClient.setQueryData('systemSettings', systemSettings);
  };

  const updatePermissions = async () => {
    setAuth({
      ...auth,
      permissions: auth.scope.permissions,
    });
  };

  useEffect(() => {
    async function asyncWrapper() {
      await updateSystemSettings();
      await updatePermissions();
    }

    if (isAuthenticated) asyncWrapper();
  }, []);

  const { id } = useAppSelector((state) => state.heartbeat);
  if (!id && isAuthenticated) {
    const newId = setInterval(async () => {
      await updateSystemSettings();
      await updatePermissions();
    }, 30000);

    dispatch(setIntervalId(newId));
  }

  /* Create page-specific Ability */
  let customerId;
  let employeeId;
  if (location?.pathname?.startsWith('/kunden')) {
    customerId = computedMatch?.params?.id;
  }
  if (location?.pathname?.startsWith('/mitarbeiter')) {
    employeeId = computedMatch?.params?.id;
  }

  const getAbility = useGetAbility();
  const ability = getAbility({ customerId, employeeId });

  return (
    <ErrorBoundary>
      <Route
        {...rest}
        render={(props) => {
          if (!auth.isAuthenticated) {
            return <Redirect to="/anmeldung" />;
          }
          if (isAuthorized({ requiredAbilities, rolesWhitelist, ability })) {
            return redirectTo ? (
              <Redirect
                to={{
                  pathname: redirectTo,
                  search: `from=${props.location.pathname}`,
                  state: { from: props.location },
                }}
              />
            ) : (
              <Component {...props} />
            );
          }
          return <PageNotAllowed />;
        }}
      />
    </ErrorBoundary>
  );
};

export default AuthorizedRoute;
