import React from 'react';
import { PestixTheme } from 'components/Input/FormikControl/ts/types';
import { darken } from '@mui/material';

const themes = [
  {
    id: 'default',
    logo: '',
    source: new (function (this: PestixTheme) {
      this.spacing = (factor) => `${8 * factor}px`;
      this.palette = {
        turquoise: '#11a8b8',
        pGray1: { main: '#f7f8fa', contrastText: '#3d5170' },
        pGray2: { main: '#EDEFF5', contrastText: '#3d5170' },
        pGray3: { main: '#ced3dc', contrastText: '#3d5170' },
        pGray4: { main: '#8493ab', contrastText: '#3d5170' },
        pGray5: { main: '#3d5170', contrastText: '#EDEFF5' },
        red: { main: '#cb3844', contrastText: '#FFF' },
        orange: { main: '#f29100' },
        pestix: {
          main: '#11a8b8',
          grey: '#ced3dc',
          grey1: '#f7f8fa',
          grey2: '#EDEFF5',
          grey3: '#ced3dc',
          grey4: '#8493ab',
          grey5: '#3d5170',
          red: '#cb3844',
          orange: '#f29100',
          turquoise: '#11a8b8',
          turquoiseLight: '#e3f9fc',
          green: '#0EAA00',
        },
        primary: {
          main: '#11a8b8',
          grey: '#ced3dc',
          grey1: '#f7f8fa',
          grey2: '#EDEFF5',
          grey3: '#ced3dc',
          grey4: '#8493ab',
          grey5: '#3d5170',
          red: '#cb3844',
          orange: '#f29100',
          turquoise: '#11a8b8',
          turquoiseLight: '#e3f9fc',
        },
        secondary: {
          main: '#cc3945',
        },
        background: {
          default: '#edeff5',
        },
        text: {
          primary: '#3d5170',
          grey: '#3d5170',
        },
        action: {
          selected: '#11a8b8',
          disabled: '#ced3dc', // grau3
        },
        deficiency: '#15A8FB',
        infestation: '#F9587F',
        contractPosition: '#0033E9',
        active: '#83D9E1',

        error: {
          main: '#cb3844',
        },
      };
      this.borderRadius = '8px';
      this.typography = {
        text: {
          color: '#3d5170',
        },
        h1: {
          fontSize: '32px',
          lineHeight: '42px',
          fontWeight: '600',
        },
        h2: {
          fontWeight: 700,
          fontSize: '20px',
          lineHeight: '24px',
          letterSpacing: '0.2%',
        },
        h3: {
          fontWeight: 500,
          fontSize: '18px',
          lineHeight: '22px',
          letterSpacing: '0.15px',
        },
        h4: {
          fontSize: '16px',
          lineHeight: '20px',
          fontWeight: 500,
        },
        h5: {
          fontSize: '14px',
          lineHeight: '18px',
          fontWeight: 500,
        },
        h6: {
          fontSize: '22px',
          color: '#3d5170 !important',
          marginTop: '29px',
          marginBottom: '21px',
        },

        body2: {
          fontSize: '14px',
        },
        body3: {
          fontSize: '12px',
          color: '#8493ab',
        },
      };

      this.components = {
        addIconButton: {
          width: 20,
          height: 20,
          background: this.palette.primary.main,
          marginLeft: this.spacing(3),
          '&:hover': {
            background: darken(this.palette.primary.main, 0.25),
          },
          '& svg': {
            fill: 'white',
            width: 18,
            height: 18,
          },
        },
        outlinedCard: {
          background: this.palette.primary.grey1,
          border: `1px solid ${this.palette.primary.grey3}`,
          borderRadius: this.borderRadius,
        },
        clearButton: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '& button': {
            padding: 0,
            height: 'max-content',
          },
        },
        formReadingMode: {
          // input type number: hide arrows
          // firefox
          '& input[type=number]': {
            '-moz-appearance': 'textfield',
          },
          // Chrome, Safari, Edge, Opera
          'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
          },

          // switch
          '& .MuiFormControlLabel-root': {
            '& .MuiTypography-root': {
              // label
              color: `${this.palette.pestix.grey5} !important`,
            },
            '& .MuiSwitch-switchBase': {
              color: `${this.palette.pestix.turquoise} !important`,
            },
          },

          '& .MuiSelect-icon': {
            display: 'none',
          },

          '& .MuiInputBase-input': {
            // input values
            textFillColor: `${this.palette.pestix.grey5} !important`,
          },
          // checkboxes
          '& .Mui-checked': {
            color: `${this.palette.pestix.grey5} !important`,
          },
          // select values
          '& .MuiSelect-standard': {
            textFillColor: `${this.palette.pestix.grey5} !important`,
            color: `${this.palette.pestix.grey5} !important`,
          },
          // hide input buttons, e.g. calender icon in date picker
          '& .MuiIconButton-root': {
            display: 'none !important',
          },
          // hide empty input fields in search autocomplete (= empty row)
          '& .MuiInputBase-inputTypeSearch': {
            display: 'none !important',
          },
          '& .MuiAutocomplete-root': {
            // values in autocomplete
            '& li': {
              color: this.palette.pestix.grey5,
            },
            // tags
            '& .MuiChip-filled': {
              opacity: 1,
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            root: {
              // Als Alternative zu sizeMedium
              // maxHeight: '32px',
              // minHeight: '32px',
              // fontWeight: 500,
              // fontSize: '13px',
              // lineHeight: '19.5px',
              boxShadow: 'none',
            },
            sizeSmall: {
              fontWeight: 500,
              fontSize: '12px',
              lineHeight: '18px',
            },
            sizeLarge: {
              fontWeight: 500,
              fontSize: '15px',
              lineHeight: '26px',
              maxHeight: '40px',
              minHeight: '40px',
            },
            containedPrimary: {
              color: '#ffffff',
              '&:hover': {
                backgroundColor: '#069aa9', // türkis dunkel
              },
              '&:disabled': {
                backgroundColor: '#edeff5', // grau2
              },
            },
            outlinedPrimary: {
              '&:hover': {
                backgroundColor: '#e3f9fc', // türkis hell
              },
              '&:disabled': {
                backgroundColor: '#ffffff',
              },
            },
            textPrimary: {
              '&:hover': {
                backgroundColor: '#e3f9fc', // türkis hell
              },
            },
          },
        },
        MuiDivider: {
          styleOverrides: {
            root: {
              height: '5px',
              borderRadius: '2.5px',
            },
          },
        },
        MuiTypography: {
          styleOverrides: {
            h2: {
              fontWeight: 700,
              fontSize: '20px',
              lineHeight: '24px',
              letterSpacing: '0.2%',
            },
            h3: {
              fontWeight: 500,
              fontSize: '18px',
              lineHeight: '22px',
              letterSpacing: '0.15px',
            },
            h6: {
              fontSize: '22px',
              color: '#3d5170 !important',
              marginTop: '29px',
              marginBottom: '21px',
            },
          },
        },
        MuiToolbar: {
          styleOverrides: {
            root: {
              background: '#ffffff',
              height: '72px',
              padding: '20px 24px',
            },
          },
        },
        MuiAppBar: {
          styleOverrides: {
            colorPrimary: { backgroundColor: 'unset' },
          },
        },
        MuiListItem: {
          styleOverrides: {
            gutters: {
              padding: '12px 32px',
            },
          },
        },
        MuiListItemIcon: {
          styleOverrides: {
            root: {
              minWidth: '24px',
              marginRight: '12px',
            },
          },
        },
        MuiTooltip: {
          styleOverrides: {
            tooltip: {
              color: 'white',
              backgroundColor: '#11a8b8',
              boxShadow: '1px 1px rgba(0,0,0,0.12)',
            },
          },
        },
        MuiAccordion: {
          styleOverrides: {
            rounded: {
              borderRadius: '4px',
            },
          },
        },
        MuiAccordionSummary: {
          styleOverrides: {
            root: {
              paddingLeft: 0,
              height: '24px',
              marginBottom: '32px',
              '&$expanded': {
                minHeight: '24px',
              },
            },
          },
        },
        MuiAccordionDetails: {
          styleOverrides: {
            root: {
              padding: 0,
            },
          },
        },
        MuiFormHelperText: {
          styleOverrides: {
            root: {
              color: '#cb3844',
            },
          },
        },
        MuiMenuItem: {
          styleOverrides: {
            root: {
              '&.Mui-selected': {
                backgroundColor: 'rgba(17, 168, 184, 0.5)',
              },
            },
          },
        },
        MuiChip: {
          styleOverrides: {
            root: {
              color: '#ffffff',
            },
            deleteIcon: {
              color: '#ffffff',
            },
            icon: {
              color: '#ffffff',
            },
          },
        },
      };
    })(),
  },
];

declare module '@mui/material/styles' {
  interface Palette {
    turquoise: Palette['primary'];
    pGray1: Palette['primary'];
    pGray2: Palette['primary'];
    pGray3: Palette['primary'];
    pGray4: Palette['primary'];
    pGray5: Palette['primary'];
    red: Palette['primary'];
    orange: Palette['primary'];
    green: Palette['primary'];
  }

  interface PaletteOptions {
    turquoise?: PaletteOptions['primary'];
    pGray1?: PaletteOptions['primary'];
    pGray2?: PaletteOptions['primary'];
    pGray3?: PaletteOptions['primary'];
    pGray4?: PaletteOptions['primary'];
    pGray5?: PaletteOptions['primary'];
    red?: PaletteOptions['primary'];
    orange?: PaletteOptions['primary'];
    green?: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    turquoise: true;
    pGray1: true;
    pGray2: true;
    pGray3: true;
    pGray4: true;
    pGray5: true;
    red: true;
    orange: true;
    green: true;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    turquoise: true;
    pGray1: true;
    pGray2: true;
    pGray3: true;
    pGray4: true;
    pGray5: true;
    red: true;
    orange: true;
    green: true;
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    body3: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
  }
}

export default themes;
