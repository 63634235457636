/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { _Includes } from '../models/_Includes';
import type { Deficiency } from '../models/Deficiency';
import type { HistoryRecord } from '../models/HistoryRecord';
import type { Infestation } from '../models/Infestation';
import type { ListResponse } from '../models/ListResponse';
import type { Note } from '../models/Note';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DocumentationService {

    /**
     * Get a list of `Deficiency`s.
     * @returns any A paginated list of `Deficiency`s.
     * @throws ApiError
     */
    public static getDeficiencies({
        include,
        pageSize,
        search,
        pageNumber,
        filter,
        sort,
    }: {
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
        /** Number of items to return per page. **/
        pageSize?: number,
        /** Search string. **/
        search?: string,
        /** Number of the page to load. **/
        pageNumber?: number,
        /** Filteroptionen. **/
        filter?: any,
        /** Sortierparameter.
         *
         * Es kann nach mehreren Attributen nacheinander sortiert werden. Dazu
         * werden die zu verwendenen Attribute als Komma-getrenntes Array
         * übergeben.
         *
         * Standardmäßig wird aufsteigend sortiert. Um die Reihenfolge umzukehren,
         * kann ein Minus vor den Attributnamen gesetzt werden.
         *
         * Beispiel: `?sort=-birthday,lastname` => Jüngste zuerst, Personen mit
         * gleichem Geburtstag werden nach Name sortiert.
         *  **/
        sort?: Array<string>,
    }): CancelablePromise<(ListResponse & {
        data?: Array<Deficiency>;
    })> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/deficiencies',
            query: {
                'include': include,
                'page[size]': pageSize,
                'search': search,
                'page[number]': pageNumber,
                'filter': filter,
                'sort': sort,
            },
        });
    }

    /**
     * Create a new `Deficiency`.
     * @returns any A single `Deficiency`.
     * @throws ApiError
     */
    public static addDeficiency({
        requestBody,
    }: {
        /** A `Deficiency` JSON object. **/
        requestBody?: Deficiency,
    }): CancelablePromise<{
        data?: Deficiency;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/deficiencies',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get a single `Deficiency` identified by `id`.
     * @returns any A single `Deficiency`.
     * @throws ApiError
     */
    public static getDeficiencyById({
        id,
        include,
    }: {
        /** ID of the resource. **/
        id: string,
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
    }): CancelablePromise<{
        data?: Deficiency;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/deficiencies/{id}',
            path: {
                'id': id,
            },
            query: {
                'include': include,
            },
        });
    }

    /**
     * Update the `Deficiency` identified by `id`.
     * @returns any A single `Deficiency`.
     * @throws ApiError
     */
    public static updateDeficiency({
        id,
        requestBody,
    }: {
        /** ID of the resource. **/
        id: string,
        /** The `Deficiency` object data to update.
         * - Send changed attributes only to minimize the risk of collisions during parallel updates. **/
        requestBody?: Deficiency,
    }): CancelablePromise<{
        data?: Deficiency;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/deficiencies/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete the `Deficiency` identified by `id`.
     * @returns void
     * @throws ApiError
     */
    public static deleteDeficiency({
        id,
        force,
    }: {
        /** ID of the resource. **/
        id: string,
        /** Erzwingt die Durchführung des Requests, zum Beispiel endgültige Löschung
         * von soft deleted Entitäten.
         *
         * Aktiviert durch die Werte `true`, `1` oder `yes`. **/
        force?: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/deficiencies/{id}',
            path: {
                'id': id,
            },
            query: {
                'force': force,
            },
        });
    }

    /**
     * Get a paginated list of `HistoryRecord`s.
     * @returns any A paginated list of `HistoryRecord`s.
     * @throws ApiError
     */
    public static getDeficiencyHistory({
        id,
        include,
        pageSize,
        pageNumber,
        filter,
        sort,
    }: {
        /** ID of the resource. **/
        id: string,
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
        /** Number of items to return per page. **/
        pageSize?: number,
        /** Number of the page to load. **/
        pageNumber?: number,
        /** Filteroptionen. **/
        filter?: any,
        /** Sortierparameter.
         *
         * Es kann nach mehreren Attributen nacheinander sortiert werden. Dazu
         * werden die zu verwendenen Attribute als Komma-getrenntes Array
         * übergeben.
         *
         * Standardmäßig wird aufsteigend sortiert. Um die Reihenfolge umzukehren,
         * kann ein Minus vor den Attributnamen gesetzt werden.
         *
         * Beispiel: `?sort=-birthday,lastname` => Jüngste zuerst, Personen mit
         * gleichem Geburtstag werden nach Name sortiert.
         *  **/
        sort?: Array<string>,
    }): CancelablePromise<(ListResponse & {
        data?: Array<HistoryRecord>;
    })> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/deficiencies/{id}/history',
            path: {
                'id': id,
            },
            query: {
                'include': include,
                'page[size]': pageSize,
                'page[number]': pageNumber,
                'filter': filter,
                'sort': sort,
            },
        });
    }

    /**
     * Sends a PDF report file as binary alongside the email to which
     * the report will be sent by the API.
     * @returns Simple object with a string message.
     * @throws ApiError
     */
    public static sendDeficiencyReportEmail({
        id,
        email,
        subject,
        pdfBase,
    }: {
        /** Document ID of the specific deficiency that the reports belongs to. */
        id: string,
        /** Email to which the report will be sent. */
        email: string,
        /** Subject of the email that will be sent. */
        subject: string,
        /** PDF file as binary encoded. **/
        pdfBase: any,
    }): CancelablePromise<(ListResponse & {
        data?: Array<HistoryRecord>;
    })> {
        const formData = new FormData();
        formData.append('to_email', email);
        formData.append('subject', subject);
        formData.append('pdf', new Blob([ pdfBase ], { type : 'application/pdf'}), `Report_${(new Date()).toISOString()}.pdf`);

        return __request(OpenAPI, {
            method: 'POST',
            url: '/deficiencies/{id}/defReportMail',
            path: {
                'id': id,
            },
            body: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @deprecated
     * Get a paginated list of `Note`s.
     * @returns any A paginated list of `Note`s.
     * @throws ApiError
     */
    public static getDeficiencyNotes({
        id,
        include,
        pageSize,
        pageNumber,
        filter,
        sort,
    }: {
        /** ID of the resource. **/
        id: string,
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
        /** Number of items to return per page. **/
        pageSize?: number,
        /** Number of the page to load. **/
        pageNumber?: number,
        /** Filteroptionen. **/
        filter?: any,
        /** Sortierparameter.
         *
         * Es kann nach mehreren Attributen nacheinander sortiert werden. Dazu
         * werden die zu verwendenen Attribute als Komma-getrenntes Array
         * übergeben.
         *
         * Standardmäßig wird aufsteigend sortiert. Um die Reihenfolge umzukehren,
         * kann ein Minus vor den Attributnamen gesetzt werden.
         *
         * Beispiel: `?sort=-birthday,lastname` => Jüngste zuerst, Personen mit
         * gleichem Geburtstag werden nach Name sortiert.
         *  **/
        sort?: Array<string>,
    }): CancelablePromise<(ListResponse & {
        data?: Array<Note>;
    })> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/deficiencies/{id}/notes',
            path: {
                'id': id,
            },
            query: {
                'include': include,
                'page[size]': pageSize,
                'page[number]': pageNumber,
                'filter': filter,
                'sort': sort,
            },
        });
    }

    /**
     * Get a list of `Incident`s.
     * @returns any A paginated list of `Infestation`s.
     * @throws ApiError
     */
    public static getIncidents({
        include,
        pageSize,
        pageNumber,
        filter,
        sort,
        search,
    }: {
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
        /** Number of items to return per page. **/
        pageSize?: number,
        /** Number of the page to load. **/
        pageNumber?: number,
        /** Filteroptionen. **/
        filter?: any,
        /** Sortierparameter.
         *
         * Es kann nach mehreren Attributen nacheinander sortiert werden. Dazu
         * werden die zu verwendenen Attribute als Komma-getrenntes Array
         * übergeben.
         *
         * Standardmäßig wird aufsteigend sortiert. Um die Reihenfolge umzukehren,
         * kann ein Minus vor den Attributnamen gesetzt werden.
         *
         * Beispiel: `?sort=-birthday,lastname` => Jüngste zuerst, Personen mit
         * gleichem Geburtstag werden nach Name sortiert.
         *  **/
        sort?: Array<string>,
        /** Search string. **/
        search?: string,
    }): CancelablePromise<(ListResponse & {
        data?: Array<(Deficiency | Infestation)>;
    })> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/incidents',
            query: {
                'include': include,
                'page[size]': pageSize,
                'page[number]': pageNumber,
                'filter': filter,
                'sort': sort,
                'search': search,
            },
        });
    }

    /**
     * Get a list of `Infestation`s.
     * @returns any A paginated list of `Infestation`s.
     * @throws ApiError
     */
    public static getInfestations({
        include,
        pageSize,
        pageNumber,
        filter,
        sort,
        search,
    }: {
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
        /** Number of items to return per page. **/
        pageSize?: number,
        /** Number of the page to load. **/
        pageNumber?: number,
        /** Filteroptionen. **/
        filter?: any,
        /** Sortierparameter.
         *
         * Es kann nach mehreren Attributen nacheinander sortiert werden. Dazu
         * werden die zu verwendenen Attribute als Komma-getrenntes Array
         * übergeben.
         *
         * Standardmäßig wird aufsteigend sortiert. Um die Reihenfolge umzukehren,
         * kann ein Minus vor den Attributnamen gesetzt werden.
         *
         * Beispiel: `?sort=-birthday,lastname` => Jüngste zuerst, Personen mit
         * gleichem Geburtstag werden nach Name sortiert.
         *  **/
        sort?: Array<string>,
        /** Search string. **/
        search?: string,
    }): CancelablePromise<(ListResponse & {
        data?: Array<Infestation>;
    })> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/infestations',
            query: {
                'include': include,
                'page[size]': pageSize,
                'page[number]': pageNumber,
                'filter': filter,
                'sort': sort,
                'search': search,
            },
        });
    }

    /**
     * Create a new `Infestation`.
     * @returns any A single `Infestation`.
     * @throws ApiError
     */
    public static addInfestation({
        requestBody,
    }: {
        /** A `Infestation` JSON object. **/
        requestBody?: Infestation,
    }): CancelablePromise<{
        data?: Infestation;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/infestations',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get a single `Infestation` identified by `id`.
     * @returns any A single `Infestation`.
     * @throws ApiError
     */
    public static getInfestationById({
        id,
        include,
    }: {
        /** ID of the resource. **/
        id: string,
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
    }): CancelablePromise<{
        data?: Infestation;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/infestations/{id}',
            path: {
                'id': id,
            },
            query: {
                'include': include,
            },
        });
    }

    /**
     * Update the `Infestation` identified by `id`.
     * @returns any A single `Infestation`.
     * @throws ApiError
     */
    public static updateInfestation({
        id,
        requestBody,
    }: {
        /** ID of the resource. **/
        id: string,
        /** The `Infestation` object data to update.
         * - Send changed attributes only to minimize the risk of collisions during parallel updates. **/
        requestBody?: Infestation,
    }): CancelablePromise<{
        data?: Infestation;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/infestations/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete the `Infestation` identified by `id`.
     * @returns void
     * @throws ApiError
     */
    public static deleteInfestation({
        id,
        force,
    }: {
        /** ID of the resource. **/
        id: string,
        /** Erzwingt die Durchführung des Requests, zum Beispiel endgültige Löschung
         * von soft deleted Entitäten.
         *
         * Aktiviert durch die Werte `true`, `1` oder `yes`. **/
        force?: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/infestations/{id}',
            path: {
                'id': id,
            },
            query: {
                'force': force,
            },
        });
    }

    /**
     * Get a paginated list of `HistoryRecord`s.
     * @returns any A paginated list of `HistoryRecord`s.
     * @throws ApiError
     */
    public static getInfestationHistory({
        id,
        include,
        pageSize,
        pageNumber,
        filter,
        sort,
    }: {
        /** ID of the resource. **/
        id: string,
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
        /** Number of items to return per page. **/
        pageSize?: number,
        /** Number of the page to load. **/
        pageNumber?: number,
        /** Filteroptionen. **/
        filter?: any,
        /** Sortierparameter.
         *
         * Es kann nach mehreren Attributen nacheinander sortiert werden. Dazu
         * werden die zu verwendenen Attribute als Komma-getrenntes Array
         * übergeben.
         *
         * Standardmäßig wird aufsteigend sortiert. Um die Reihenfolge umzukehren,
         * kann ein Minus vor den Attributnamen gesetzt werden.
         *
         * Beispiel: `?sort=-birthday,lastname` => Jüngste zuerst, Personen mit
         * gleichem Geburtstag werden nach Name sortiert.
         *  **/
        sort?: Array<string>,
    }): CancelablePromise<(ListResponse & {
        data?: Array<HistoryRecord>;
    })> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/infestations/{id}/history',
            path: {
                'id': id,
            },
            query: {
                'include': include,
                'page[size]': pageSize,
                'page[number]': pageNumber,
                'filter': filter,
                'sort': sort,
            },
        });
    }

    /**
     * @deprecated
     * Get a paginated list of `Note`s.
     * @returns any A paginated list of `Note`s.
     * @throws ApiError
     */
    public static getInfestationNotes({
        id,
        include,
        pageSize,
        pageNumber,
        filter,
        sort,
    }: {
        /** ID of the resource. **/
        id: string,
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
        /** Number of items to return per page. **/
        pageSize?: number,
        /** Number of the page to load. **/
        pageNumber?: number,
        /** Filteroptionen. **/
        filter?: any,
        /** Sortierparameter.
         *
         * Es kann nach mehreren Attributen nacheinander sortiert werden. Dazu
         * werden die zu verwendenen Attribute als Komma-getrenntes Array
         * übergeben.
         *
         * Standardmäßig wird aufsteigend sortiert. Um die Reihenfolge umzukehren,
         * kann ein Minus vor den Attributnamen gesetzt werden.
         *
         * Beispiel: `?sort=-birthday,lastname` => Jüngste zuerst, Personen mit
         * gleichem Geburtstag werden nach Name sortiert.
         *  **/
        sort?: Array<string>,
    }): CancelablePromise<(ListResponse & {
        data?: Array<Note>;
    })> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/infestations/{id}/notes',
            path: {
                'id': id,
            },
            query: {
                'include': include,
                'page[size]': pageSize,
                'page[number]': pageNumber,
                'filter': filter,
                'sort': sort,
            },
        });
    }

}