/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ListResponse } from '../models/ListResponse';
import type { Statistic } from '../models/Statistic';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class StatisticsService {

    /**
     * Get Properties for Statistics
     * Get Properties for statistics
     * @returns any A list of `Properti`es.
     * @throws ApiError
     */
    public static getPropertiesForStatistics({
        pageSize,
        pageNumber,
        search,
    }: {
        /** Number of items to return per page. **/
        pageSize?: number,
        /** Number of the page to load. **/
        pageNumber?: number,
        /** Search criteria. **/
        search?: string,
    }): CancelablePromise<ListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/statistics',
            query: {
                'page[size]': pageSize,
                'page[number]': pageNumber,
                'search': search,
            },
        });
    }

    /**
     * Get statistics
     * Get aggregated statistics for object
     * @returns Statistic Statistics for Documentation
     * @throws ApiError
     */
    public static getStatistics({
        id,
    }: {
        /** ID of the resource. **/
        id: string,
    }): CancelablePromise<Statistic> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/statistics/{id}',
            path: {
                'id': id,
            },
        });
    }

}