import App from 'base-shell/lib';
import merge from 'base-shell/lib/utils/config';
import MUIConfig from 'material-ui-shell/lib';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { store } from 'app/store';
import toast, { Toaster } from 'react-hot-toast';
import _config from './config';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import useErrorHandling from 'components/ErrorBoundary/useErrorHandling';
import { SystemSettingsStore } from 'app/SystemSettingsStore';
import { ApiError } from '_build/ts';
import { Divider, Grid, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
const config = merge(MUIConfig, _config);
import '@fontsource/roboto/100.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MATERIAL_UI_KEY as string);

const getErrorTitle = (statusCode: number) => {
  switch (statusCode) {
    case 400:
      return 'Fehlerhafte Anfrage';
    case 401:
      return 'Fehlende Autorisierung';
    case 403:
      return 'Kein Zugriff';
    case 404:
      return 'Daten konnten nicht gefunden werden';
    case 405:
      return 'Vorgang nicht erlaubt';
    case 415:
    case 422:
      return 'Validierungsfehler';
    default:
      return 'Fehler';
  }
};

const AppWrapper = () => {
  const { triggerError } = useErrorHandling();

  // const [data, setData] = useState([]);
  //  useEffect(() => {
  //     fetch('https://staging-pestix.de/')
  //        .then((response) => response.json())
  //        .then((data) => {
  //           console.log(data);
  //           setData(data);
  //        })
  //        .catch((err) => {
  //           console.log(err.message);
  //        });
  //  }, []);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        // onError: (error: any) => {
        onError: () => {
          // toast.error(`${error.message}`);
        },

        useErrorBoundary: (error) => {
          return triggerError(error);
        },
        refetchOnWindowFocus: false,
      },
      mutations: {
        onError: (error: any = []) => {
          const apiError = error as ApiError;

          try {
            const logIds: Array<string> = [];
            const errorStrings = apiError.body?.errors.map((err) => {
              const logId: string = err?.links?.log.split('/').pop();
              if (logId) {
                logIds.push(logId);
              }

              return (
                <Grid item xs={12}>
                  <Typography variant="subtitle1">{getErrorTitle(err.status)}</Typography>
                  <Typography variant="body2">{err.detail}</Typography>
                  <Divider style={{ marginTop: 5 }} />
                </Grid>
              );
            });

            const uniqueLogIds = Array.from(new Set(logIds));
            toast.loading(
              (t) => (
                <Grid container direction={'row'} alignItems={'center'}>
                  <Grid item xs container>
                    {errorStrings}
                    <Typography variant="overline">
                      Support-Referenz: {uniqueLogIds.join(', ')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton
                      color="primary"
                      size="small"
                      onClick={() => toast.dismiss(t.id)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              ),
              {
                icon: <ErrorIcon />,
                duration: 30000,
                style: {
                  width: 'auto',
                  minWidth: 600,
                },
              }
            );
          } catch (err: any) {
            console.log(err.body);
            toast.error(`Validation failed`);
          }
        },

        useErrorBoundary: () => {
          return false;
        },
      },
    },
  });

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <div>
            <Toaster />
          </div>
          <SystemSettingsStore>
            <App config={config} />
          </SystemSettingsStore>
          {config.debug ? <ReactQueryDevtools initialIsOpen={false} /> : null}
        </LocalizationProvider>
      </QueryClientProvider>
    </Provider>
  );
};

export default AppWrapper;
