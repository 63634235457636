import { createSlice } from '@reduxjs/toolkit';
import { Claim } from 'features/openItems/openItemsSlice';

export type PaymentProposal = {
  id: string;
  paymentDate: string;
  referenceText: string;
  iban: string;
  swift: string;
  endToEndReference: string;
  customerReference: string;
  note: string;
  proposedClaims: Claim[];
  title: string;
  amount: string;
  type: 'invoice' | 'directDebit';
};

// TODO generate payment proposal reducer and demo data
// danach weiter mit ProposalItems

export type PaymentProposalsState = {
  filter: {
    searchValue: string;
    filterValues: Array<'invoice' | 'directDebit'>;
  };
  filteredItems: Array<PaymentProposal>;
  items: Array<PaymentProposal>;
  selectedItems: Array<PaymentProposal>;
  selectedCustomerIds: Array<string>;
};

const initialState: PaymentProposalsState = {
  filter: {
    searchValue: '',
    filterValues: [],
  },
  filteredItems: [],
  items: [],
  selectedItems: [],
  selectedCustomerIds: [],
};

export const paymentProposalsSlice = createSlice({
  name: 'paymentProposals',
  initialState,
  reducers: {
    initPaymentProposals: (state, action) => {
      const { data } = action.payload;
      state.items = data;
      state.filteredItems = data;
    },
    filterPaymentProposals: (state, action) => {
      const { searchValue, filterValues } = action.payload;
      state.filter.searchValue = searchValue;
      state.filter.filterValues = filterValues;

      if (filterValues.length)
        state.filteredItems = state.items.filter((el) => filterValues.includes(el.type));

      if (searchValue)
        state.filteredItems = state.items.filter(
          (item) =>
            item.title.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.referenceText.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.note.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.amount.toLowerCase().includes(searchValue.toLowerCase())
        );

      if (!searchValue && !filterValues.length) state.filteredItems = state.items;
    },
    removePaymentProposalClaim: (state, action) => {
      const { proposalId, claimId } = action.payload;
      const paymentProposalIndex = state.items.findIndex(
        (item) => item.id === proposalId
      );

      const claimIndex = state.items[paymentProposalIndex].proposedClaims.findIndex(
        (claim) => claim.id === claimId
      );

      state.items[paymentProposalIndex].proposedClaims.splice(claimIndex, 1);

      // reset filter
      if (state.filter.filterValues.length)
        state.filteredItems = state.items.filter((el) =>
          state.filter.filterValues.includes(el.type)
        );

      if (state.filter.searchValue)
        state.filteredItems = state.items.filter(
          (item) =>
            item.title.toLowerCase().includes(state.filter.searchValue.toLowerCase()) ||
            item.referenceText
              .toLowerCase()
              .includes(state.filter.searchValue.toLowerCase()) ||
            item.note.toLowerCase().includes(state.filter.searchValue.toLowerCase()) ||
            item.amount.toLowerCase().includes(state.filter.searchValue.toLowerCase())
        );

      if (!state.filter.searchValue && !state.filter.filterValues.length)
        state.filteredItems = state.items;
    },

    // selectPaymentProposal: (state, action) => {
    //   const { id, scope } = action.payload;
    //   const elem = state[scope].items.find((item) => item.id === id);
    //   const index = state[scope].selectedItems.findIndex((item) => item.id === id);
    //   if (index < 0) state[scope].selectedItems.push(elem);
    // },
    // deselectPaymentProposal: (state, action) => {
    //   const { id, scope } = action.payload;
    //   const index = state[scope].selectedItems.findIndex((item) => item.id === id);
    //   state[scope].selectedItems.splice(index, 1);
    // },
    // assignSelectedPaymentProposals: (state) => {
    //   state.dst.items = [...state.dst.items, ...state.selectedItems];
    //   state.items = state.items.filter(
    //     (item) => !state.selectedItems.find((el) => el.id === item.id)
    //   );
    //   state.selectedItems = [];
    //   state.selectedCustomerIds = [];
    //
    //   // reset filter
    //   if (state.filter.filterValues.length)
    //     state.filteredItems = state.items.filter((el) =>
    //       state.filter.filterValues.includes(el.type)
    //     );
    //
    //   if (state.filter.searchValue)
    //     state.filteredItems = state.items.filter(
    //       (item) =>
    //         item.title.toLowerCase().includes(state.filter.searchValue.toLowerCase()) ||
    //         item.customer.name
    //           .toLowerCase()
    //           .includes(state.filter.searchValue.toLowerCase())
    //     );
    //
    //   if (!state.filter.searchValue && !state.filter.filterValues.length)
    //     state.filteredItems = state.items;
    // },
    // unassignSelectedPaymentProposals: (state) => {
    //   state.items = [...state.items, ...state.dst.selectedItems];
    //   state.dst.items = state.dst.items.filter(
    //     (item) => !state.dst.selectedItems.find((el) => el.id === item.id)
    //   );
    //
    // reset filter

    // selectAssignedInvoiceForConsolidation: (state, action) => {
    //   const { customerId, consolidationId } = action.payload;
    //
    //   if (!state.dst.consolidated[customerId]) {
    //     state.dst.consolidated[customerId] = {};
    //   }
    //
    //   if (state.dst.consolidated[customerId][consolidationId]) {
    //     state.dst.consolidated[customerId][consolidationId].push(
    //       ...state.dst.selectedItems.filter((item) => item.customer.id === customerId)
    //     );
    //   } else {
    //     state.dst.consolidated[customerId][v4()] = state.dst.selectedItems.filter(
    //       (item) => item.customer.id === customerId
    //     );
    //   }
    //
    //   state.dst.items = state.dst.items.map((item) => {
    //     if (
    //       item.customer.id === customerId &&
    //       !!state.dst.selectedItems.find((el) => el.id === item.id)
    //     ) {
    //       item.isConsolidated = true;
    //     }
    //     return item;
    //   });
    //
    //   state.dst.selectedItems = state.dst.selectedItems.filter(
    //     (el) => el.customer.id !== customerId
    //   );
    //
    //   state.dst.selectedCustomerIds = [];
    // },
    // unselectAssignedInvoiceForConsolidation: (state, action) => {
    //   const { id, customerId, consolidationId } = action.payload;
    //   state.dst.items = state.dst.items.map((item) => {
    //     if (item.id === id) {
    //       item.isConsolidated = false;
    //     }
    //     return item;
    //   });
    //
    //   state.dst.consolidated[customerId][consolidationId] = state.dst.consolidated[
    //     customerId
    //   ][consolidationId].filter((item) => item.id !== id);
    //
    //   if (state.dst.consolidated[customerId][consolidationId].length === 0) {
    //     delete state.dst.consolidated[customerId][consolidationId];
    //   }
    //
    //   if (Object.keys(state.dst.consolidated[customerId]).length === 0) {
    //     delete state.dst.consolidated[customerId];
    //   }
    // },
  },
});

// Action creators are generated for each case reducer function
export const {
  initPaymentProposals,
  filterPaymentProposals,
  removePaymentProposalClaim,
} = paymentProposalsSlice.actions;

export default paymentProposalsSlice.reducer;
