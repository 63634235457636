import { defineAbility, RawRuleOf } from '@casl/ability';
import { useAuth } from 'base-shell/lib/providers/Auth';
import { Ability } from '@casl/ability';

export type Actions = 'create' | 'show' | 'edit' | 'delete';

export type RequiredAbility = [Actions, Subject];

export type Subject =
  | 'appointment'
  | 'appointment.document'
  | 'appointment.notes'
  | 'appointment.history'
  | 'employee'
  | 'employee.authSettings'
  | 'employee.documents'
  | 'employee.history'
  | 'employee.notes'
  | 'customer'
  | 'customer.internalComment'
  | 'customer.paymentDetails'
  | 'customer.authSettings'
  | 'customer.notificationSettings'
  | 'customer.documents'
  | 'customer.history'
  | 'customer.notes'
  | 'contact'
  | 'contact.internalComment'
  | 'contact.authSettings'
  | 'contact.notificationSettings'
  | 'contact.paymentDetails'
  | 'contact.history'
  | 'property'
  | 'property.documents'
  | 'property.history'
  | 'property.notes'
  | 'propertyArea'
  | 'product'
  | 'product.documents'
  | 'orderFile'
  | 'orderFile.documents'
  | 'orderFile.notes'
  | 'orderFile.history'
  | 'orderFile.paymentState'
  | 'orderFile.processingState'
  | 'orderFile.invoiceState'
  | 'contract'
  | 'contract.documents'
  | 'contract.notes'
  | 'contract.history'
  | 'monitor'
  | 'monitor.note'
  | 'monitor.history'
  | 'infestation'
  | 'infestation.notes'
  | 'infestation.history'
  | 'deficiency'
  | 'deficiency.notes'
  | 'deficiency.history'
  | 'documentation'
  | 'accounting'
  | 'accounting.invoices'
  | 'accounting.export'
  | 'settings.general'
  | 'serviceArea'
  | 'branch'
  | 'pest'
  | 'unit'
  | 'monitorTypes'
  | 'monitorEvent'
  | 'monitorEvent.documents'
  | 'paymentTerms'
  | 'valueAddedTaxRate'
  | 'valueAddedTaxValue';

type GetAbilityProps = {
  customerId?: string;
  employeeId?: string;
  contactId?: string;
};

type AppAbilityType = Ability<[Actions, Subject]>;

// default, in case no ability is set
export const emptyAbility = defineAbility<Ability<[Actions, Subject]>>(() => {});

// helper function, determines whether a key in the permissions object is not a subject
const isNotAction = (action: string) => {
  return !(
    action === 'create' ||
    action === 'show' ||
    action === 'edit' ||
    action === 'delete'
  );
};

export const useGetAbility = () => {
  const authData = useAuth().auth;

  return ({ customerId, employeeId }: GetAbilityProps): Ability<[Actions, Subject]> => {
    try {
      const permissions = authData?.permissions;
      const { customerId: relatedCustomerId } = authData?.scope;
      const { id: userId, type: userType } = authData?.scope?.user;

      // invalid
      if (
        !permissions ||
        !userId ||
        !userType ||
        (userType === 'Contact' && !relatedCustomerId)
      ) {
        return new Ability<[Actions, Subject]>([]);
      }

      // this builds a rules array recursively from the permissions object
      // is is not used globally on the perm. object because for some subcategories
      // there a additional rules (such as: customers may only access themselves, etc.)
      // Instead, it is used on parts.
      const constructRulesArray = (permissions: Object, subject?: string) => {
        if (!permissions) {
          return [];
        }
        return Object.keys(permissions).map((permissionKey) => {
          const permission = permissions[permissionKey];

          if (isNotAction(permissionKey)) {
            return constructRulesArray(
              permission,
              subject ? `${subject}.${permissionKey}` : permissionKey
            );
          } else {
            return {
              inverted: !permission,
              action: permissionKey,
              subject: subject,
            };
          }
        });
      };

      let rules: RawRuleOf<AppAbilityType>[] = [];

      // helper func, to add a rule similar to the can(..., ...) syntax
      const addRule = (
        action: Actions,
        subject: Subject | Subject[],
        allowed: boolean
      ) => {
        rules.push({
          action,
          subject,
          inverted: !allowed,
        });
      };

      /*
       * Rules
       */

      // customer has additional conditions
      const { customer: customerPermissions, ...restPermissions } = permissions;

      rules = rules.concat(constructRulesArray(restPermissions)).flat(3);

      /* Employee */
      // employee has readonly access to his own data
      // do not move condition to addRule, sinc this would overwrite the rule set by the permissions object
      if (userType === 'Employee' && userId === employeeId) {
        addRule('show', 'employee', true);
      }

      /* Customer */
      // for customers and their contacts, acceess is only granted if requested on oneself / the related customer
      if (
        (userType === 'Customer' && userId === customerId) ||
        (userType === 'Contact' &&
          relatedCustomerId &&
          relatedCustomerId === customerId) ||
        userType === 'Employee'
      ) {
        rules = rules.concat(
          constructRulesArray(customerPermissions, 'customer').flat(3)
        );
      }

      return new Ability<[Actions, Subject]>(rules);
    } catch (error) {
      return emptyAbility;
    }
  };
};
