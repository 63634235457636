import { OptionsObject } from 'notistack';

export const selectMappings = {
  roleMapping: [
    {
      key: 'admin',
      value: 'Admin',
    },
    {
      key: 'pestix.buchhaltung',
      value: 'Buchhaltung',
    },
    {
      key: 'pestix.auftragsverwaltung',
      value: 'Auftragsverwaltung',
    },
    {
      key: 'pestix.personalbuchhaltung',
      value: 'Personalbuchhaltung',
    },
    {
      key: 'pestix.dispo',
      value: 'Disposition',
    },
    {
      key: 'pestix.service-leitung',
      value: 'Service Leitung',
    },
    {
      key: 'pestix.niederlassungsleitung',
      value: 'Niederlassungsleitung',
    },
    {
      key: 'pestix.vertrieb',
      value: 'Vertrieb',
    },
    {
      key: 'pestix.techniker',
      value: 'Techniker',
    },
    {
      key: 'kunde.admin',
      value: 'Kunde Admin',
    },
    {
      key: 'kunde.qs',
      value: 'Kunde Qualitätssicherung',
    },
    {
      key: 'kunde.buchhaltung',
      value: 'Kunde Buchhaltung',
    },
    {
      key: 'kunde.objektbetreuer',
      value: 'Kunde Objektbetreuer',
    },
    {
      key: 'kunde.technik',
      value: 'Kunde Technik',
    },
  ] as const,
  countryMapping: [
    { key: 'DE', value: 'Deutschland' },
    { key: 'AT', value: 'Österreich' },
    { key: 'CZ', value: 'Tschechien' },
    { key: 'DK', value: 'Dänemark' },
    { key: 'FR', value: 'Frankreich' },
    { key: 'LU', value: 'Luxenburg' },
    { key: 'NL', value: 'Niederlande' },
    { key: 'PL', value: 'Polen' },
    { key: 'SE', value: 'Schweden' },
  ],
  modeOfPaymentMapping: [
    { key: 'invoice', value: 'Rechnung' },
    { key: 'directDebit', value: 'Lastschrift' },
    { key: 'cash', value: 'Bar' },
  ],
  addressTypeMapping: [
    { key: 'invoice', value: 'Rechnungsadresse' },
    { key: 'beneficiary', value: 'Leistungsempfänger' },
    { key: 'other', value: 'Sonstige Adresse' },
  ],
  hearedAboutUsMapping: [
    { key: 'bus', value: 'Werbung auf Bus' },
    { key: 'radio', value: 'Radio' },
    { key: 'adwords', value: 'Google AdWords' },
  ],
  channelTypeMappings: [
    { key: 'mail', value: 'E-Mail' },
    { key: 'mobile', value: 'Mobil' },
    { key: 'other', value: 'Andere' },
    { key: 'phone', value: 'Telefon' },
    { key: 'url', value: 'URL' },
  ],
  notificationSettingSubjectMapping: [
    { key: 'default', value: 'Allgemein' },
    { key: 'accounting', value: 'Buchhaltung' },
    { key: 'deficiency', value: 'Mangel' },
    { key: 'infestation', value: 'Befall' },
    { key: 'login', value: 'Anmeldung' },
    { key: 'news', value: 'Nachrichten' },
    { key: 'offer', value: 'Angebot' },
    { key: 'order', value: 'Auftrag' },
    { key: 'report', value: 'Bericht' },
    { key: 'wirelessTrap', value: 'Funkfalle' },
  ],
  notificationSettingEmailReminderMap: [
    { key: 'none', value: 'keine Benachrichtigung' },
    { key: 'daily', value: 'täglich' },
    { key: 'weekly', value: 'wöchentlich' },
  ],
  notificationSettingEmailNotoficationMap: [
    { key: 'asap', value: 'sofort' },
    { key: 'summary', value: 'zusammengefasst' },
  ],
  paymentDetailsMapping: [
    { key: '22', value: '15 Tage netto' },
    { key: '25', value: '2% Skonto, 15 Tage netto' },
    { key: '26', value: '3 % Skonto, 15 Tage netto' },
    { key: '30', value: '3 % Skonto, 30 Tage netto' },
    { key: '23', value: '30 Tage netto' },
    { key: '29', value: '45 Tage netto' },
    { key: '28', value: '60 Tage netto' },
    { key: '27', value: '90 Tage netto' },
    { key: '21', value: 'Bankeinzug' },
    { key: '24', value: 'Bar' },
  ],
  offerStatusMapping: [
    { key: 'offen', value: 'offen' },
    { key: 'eingelastet', value: 'eingelastet' },
    { key: 'bearbeitet', value: 'bearbeitet' },
    { key: 'Abgeschlossen', value: 'Abgeschlossen' },
    { key: 'Teilberechnet', value: 'Teilberechnet' },
    { key: 'Berechnet', value: 'Berechnet' },
  ],
  vatRateMapping: [
    { key: 'DE', value: 'Inland steuerpflichtig' },
    { key: 'EU', value: 'EU Ausland steuerfrei' },
    { key: 'NO_VAT', value: 'Inland / Ausland' },
    { key: 'steuerfrei', value: 'steuerfrei' },
  ],
  processTypeMapping: [
    { key: 'Order', value: 'Auftrag' },
    { key: 'Contract', value: 'Vertrag' },
  ],
  certificationMapping: [
    { key: '1', value: 'AIB - AIB' },
    { key: '7', value: 'Bio - Bio Zertifizierung' },
    { key: '2', value: 'BRC - BRC' },
    { key: '5', value: '9001 - DIN ISO 9001' },
    { key: '3', value: 'IFS Food - IFS Food' },
    { key: '6', value: 'IFS Log - IFS Logistic' },
  ],
  genderMapping: [
    { key: 'M', value: 'männlich' },
    { key: 'F', value: 'weiblich' },
    { key: 'D', value: 'divers' },
  ],
  infestationStateMapping: [
    { key: 'open', value: 'Offen' },
    { key: 'processing', value: 'In Bearbeitung' },
    { key: 'processed', value: 'Bearbeitet' },
    { key: 'resolved', value: 'Erledigt' },
  ],
  responsibilityMapping: [
    { key: 'customer', value: 'Kunde' },
    { key: 'exterminator', value: 'Schädlingsbekämpfer' },
  ],
  deficiencyTypeMapping: [
    { key: 'hygienic', value: 'Hygienemangel' },
    { key: 'facility', value: 'Baumangel' },
    { key: 'other', value: 'Anderer Mangel' },
  ],
  weekdayMapping: [
    { key: 'monday', value: 'Montag' },
    { key: 'tuesday', value: 'Dienstag' },
    { key: 'wednesday', value: 'Mittwoch' },
    { key: 'thursday', value: 'Donnerstag' },
    { key: 'friday', value: 'Freitag' },
    { key: 'saturday', value: 'Samstag' },
    { key: 'sunday', value: 'Sonntag' },
  ],
  monthMapping: [
    { key: 'Jan', value: 'Januar' },
    { key: 'Feb', value: 'Februar' },
    { key: 'Mar', value: 'März' },
    { key: 'Apr', value: 'April' },
    { key: 'May', value: 'Mai' },
    { key: 'Jun', value: 'Juni' },
    { key: 'Jul', value: 'Juli' },
    { key: 'Aug', value: 'August' },
    { key: 'Sep', value: 'September' },
    { key: 'Oct', value: 'Oktober' },
    { key: 'Nov', value: 'November' },
    { key: 'Dec', value: 'Dezember' },
  ] as const,

  /* ORDER FILE */
  orderFileProcessingStateMapping: [
    {
      key: 'draft',
      value: 'Entwurf',
    },
    {
      key: 'offered',
      value: 'angeboten',
    },
    {
      key: 'confirmed',
      value: 'bestätigt',
    },
    {
      key: 'scheduled',
      value: 'eingeplant',
    },
    {
      key: 'processing',
      value: 'in Bearbeitung',
    },
    {
      key: 'partlyProcessed',
      value: 'teilbearbeitet',
    },
    {
      key: 'processed',
      value: 'Erledigt',
    },
    {
      key: 'canceled',
      value: 'Storniert',
    },
  ] as const,
  orderFilePaymentStateMapping: [
    {
      key: 'open',
      value: 'Offen',
    },
    {
      key: 'partlyPayed',
      value: 'Teilbezahlt',
    },
    {
      key: 'square',
      value: 'Ausgeglichen',
    },
  ] as const,
  orderFileInvoicingStateMapping: [
    {
      key: 'open',
      value: 'Offen',
    },
    {
      key: 'partlyInvoiced',
      value: 'Teilberechnet',
    },
    {
      key: 'invoiced',
      value: 'Berechnet',
    },
    {
      key: 'overdue',
      value: 'Überfällig',
    },
    {
      key: 'dunned',
      value: 'Gemahnt',
    },
    {
      key: 'encashment',
      value: 'Inkasso',
    },
    {
      key: 'canceled',
      value: 'Storniert',
    },
  ] as const,
  orderItemChargeAt: [
    { key: 'begin', value: 'Einmalig zu Beginn' },
    { key: 'end', value: 'Einmalig zu Abschluss' },
    { key: 'recurring', value: 'Bei jeder Maßnahme' },
  ] as const,
  extendedPriceTypes: [
    { key: 'fixedPrice', value: 'Festpreis' },
    { key: 'discount', value: 'Rabatt' },
  ] as const,
};

export const validationMessages = {
  CustomerData: {
    company: 'Bitte Firmenbezeichnung eintragen',
  },
  PersonCard: {
    street1: 'Bitte Straße eingeben',
    city: 'Bitte Stadt eingeben',
    postcode: 'Bitte PLZ eingeben',
    firstName: 'Bitte Vornamen eingeben',
    lastName: 'Bitte Nachname eingeben',
  },
  SignIn: {
    email: 'Bitte geben Sie Ihre E-Mail-Adresse ein.',
    validEmail: 'Bitte geben Sie eine valide E-Mail-Adresse ein.',
    password: 'Bitte geben Sie Ihr Passwort ein.',
  },
};

type SnackbarConfig = {
  [key: string]: OptionsObject;
};

export const snackbarConfig: SnackbarConfig = {
  error: {
    variant: 'error',
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
  },
};

export type OrderFilePaymentStateKeys =
  (typeof selectMappings.orderFilePaymentStateMapping)[number]['key'];

export type OrderFileInvoicingStateKeys =
  (typeof selectMappings.orderFileInvoicingStateMapping)[number]['key'];

export type OrderFileProcessingStateKeys =
  (typeof selectMappings.orderFileProcessingStateMapping)[number]['key'];

export type OrderFileProcessingStateValues =
  (typeof selectMappings.orderFileProcessingStateMapping)[number]['value'];
