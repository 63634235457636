import { createSlice } from '@reduxjs/toolkit';

export type HeartbeatState = {
  id?: number;
};

const initialState: HeartbeatState = {
  id: undefined,
};

export const heartbeatSlice = createSlice({
  name: 'heartbeat',
  initialState,
  reducers: {
    setIntervalId: (state, action) => {
      state.id = action.payload;
    },
    stopHeartBeat: (state) => {
      clearInterval(state.id);
    },
  },
});

// Action creators are generated for each case reducer function
export const { setIntervalId, stopHeartBeat } = heartbeatSlice.actions;

export default heartbeatSlice.reducer;
