/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { _Includes } from '../models/_Includes';
import type { Employee } from '../models/Employee';
import type { HistoryRecord } from '../models/HistoryRecord';
import type { ListResponse } from '../models/ListResponse';
import type { Note } from '../models/Note';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EmployeesService {

    /**
     * Get a paginated list of `Employee`s.
     * @returns any A paginated list of `Employee`s.
     * @throws ApiError
     */
    public static getEmployees({
        include,
        pageSize,
        pageNumber,
        search,
        filter,
        sort,
    }: {
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
        /** Number of items to return per page. **/
        pageSize?: number,
        /** Number of the page to load. **/
        pageNumber?: number,
        /** Search string. **/
        search?: string,
        /** Filteroptionen. **/
        filter?: any,
        /** Sortierparameter.
         *
         * Es kann nach mehreren Attributen nacheinander sortiert werden. Dazu
         * werden die zu verwendenen Attribute als Komma-getrenntes Array
         * übergeben.
         *
         * Standardmäßig wird aufsteigend sortiert. Um die Reihenfolge umzukehren,
         * kann ein Minus vor den Attributnamen gesetzt werden.
         *
         * Beispiel: `?sort=-birthday,lastname` => Jüngste zuerst, Personen mit
         * gleichem Geburtstag werden nach Name sortiert.
         *  **/
        sort?: Array<string>,
    }): CancelablePromise<(ListResponse & {
        data?: Array<Employee>;
    })> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/employees',
            query: {
                'include': include,
                'page[size]': pageSize,
                'page[number]': pageNumber,
                'search': search,
                'filter': filter,
                'sort': sort,
            },
        });
    }

    /**
     * Create a new `Employee`.
     * @returns any A single `Employee`.
     * @throws ApiError
     */
    public static addEmployee({
        requestBody,
    }: {
        /** An `Employee` JSON object. **/
        requestBody?: Employee,
    }): CancelablePromise<{
        data?: Employee;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/employees',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete the employee identified by the given ID.
     * @returns void
     * @throws ApiError
     */
    public static deleteEmployee({
        id,
        force,
    }: {
        /** ID of the resource. **/
        id: string,
        /** Erzwingt die Durchführung des Requests, zum Beispiel endgültige Löschung
         * von soft deleted Entitäten.
         *
         * Aktiviert durch die Werte `true`, `1` oder `yes`. **/
        force?: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/employees/{id}',
            path: {
                'id': id,
            },
            query: {
                'force': force,
            },
        });
    }

    /**
     * Get the employee identified by the given ID.
     * @returns any A single `Employee`.
     * @throws ApiError
     */
    public static getEmployeeById({
        id,
        include,
    }: {
        /** ID of the resource. **/
        id: string,
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
    }): CancelablePromise<{
        data?: Employee;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/employees/{id}',
            path: {
                'id': id,
            },
            query: {
                'include': include,
            },
        });
    }

    /**
     * Update an employee.
     * @returns any A single `Employee`.
     * @throws ApiError
     */
    public static updateEmployee({
        id,
        requestBody,
    }: {
        /** ID of the resource. **/
        id: string,
        /** The `Employee` object data to update.
         * - Send changed attributes only to minimize the risk of collisions during parallel updates. **/
        requestBody?: Employee,
    }): CancelablePromise<{
        data?: Employee;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/employees/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get a paginated list of `HistoryRecord`s.
     * @returns any A paginated list of `HistoryRecord`s.
     * @throws ApiError
     */
    public static getEmployeeHistory({
        id,
        include,
        pageSize,
        pageNumber,
        filter,
        sort,
    }: {
        /** ID of the resource. **/
        id: string,
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
        /** Number of items to return per page. **/
        pageSize?: number,
        /** Number of the page to load. **/
        pageNumber?: number,
        /** Filteroptionen. **/
        filter?: any,
        /** Sortierparameter.
         *
         * Es kann nach mehreren Attributen nacheinander sortiert werden. Dazu
         * werden die zu verwendenen Attribute als Komma-getrenntes Array
         * übergeben.
         *
         * Standardmäßig wird aufsteigend sortiert. Um die Reihenfolge umzukehren,
         * kann ein Minus vor den Attributnamen gesetzt werden.
         *
         * Beispiel: `?sort=-birthday,lastname` => Jüngste zuerst, Personen mit
         * gleichem Geburtstag werden nach Name sortiert.
         *  **/
        sort?: Array<string>,
    }): CancelablePromise<(ListResponse & {
        data?: Array<HistoryRecord>;
    })> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/employees/{id}/history',
            path: {
                'id': id,
            },
            query: {
                'include': include,
                'page[size]': pageSize,
                'page[number]': pageNumber,
                'filter': filter,
                'sort': sort,
            },
        });
    }

    /**
     * @deprecated
     * Get a paginated list of `Note`s.
     * @returns any A paginated list of `Note`s.
     * @throws ApiError
     */
    public static getEmployeeNotes({
        id,
        include,
        pageSize,
        pageNumber,
        filter,
        sort,
    }: {
        /** ID of the resource. **/
        id: string,
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
        /** Number of items to return per page. **/
        pageSize?: number,
        /** Number of the page to load. **/
        pageNumber?: number,
        /** Filteroptionen. **/
        filter?: any,
        /** Sortierparameter.
         *
         * Es kann nach mehreren Attributen nacheinander sortiert werden. Dazu
         * werden die zu verwendenen Attribute als Komma-getrenntes Array
         * übergeben.
         *
         * Standardmäßig wird aufsteigend sortiert. Um die Reihenfolge umzukehren,
         * kann ein Minus vor den Attributnamen gesetzt werden.
         *
         * Beispiel: `?sort=-birthday,lastname` => Jüngste zuerst, Personen mit
         * gleichem Geburtstag werden nach Name sortiert.
         *  **/
        sort?: Array<string>,
    }): CancelablePromise<(ListResponse & {
        data?: Array<Note>;
    })> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/employees/{id}/notes',
            path: {
                'id': id,
            },
            query: {
                'include': include,
                'page[size]': pageSize,
                'page[number]': pageNumber,
                'filter': filter,
                'sort': sort,
            },
        });
    }

}