/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { _Includes } from '../models/_Includes';
import type { ListResponse } from '../models/ListResponse';
import type { Monitor } from '../models/Monitor';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MonitorsService {

    /**
     * Get a paginated list of `Monitor`s.
     * @returns any A paginated list of `Monitor`s.
     * @throws ApiError
     */
    public static getMonitorList({
        filter,
        pageNumber,
        pageSize,
        sort,
        search,
        include,
    }: {
        /** Filteroptionen. **/
        filter?: any,
        /** Number of the page to load. **/
        pageNumber?: number,
        /** Number of items to return per page. **/
        pageSize?: number,
        /** Sortierparameter.
         *
         * Es kann nach mehreren Attributen nacheinander sortiert werden. Dazu
         * werden die zu verwendenen Attribute als Komma-getrenntes Array
         * übergeben.
         *
         * Standardmäßig wird aufsteigend sortiert. Um die Reihenfolge umzukehren,
         * kann ein Minus vor den Attributnamen gesetzt werden.
         *
         * Beispiel: `?sort=-birthday,lastname` => Jüngste zuerst, Personen mit
         * gleichem Geburtstag werden nach Name sortiert.
         *  **/
        sort?: Array<string>,
        /** Search string. **/
        search?: string,
        /** Verwandte Modelle die geladen werden sollen. **/
        include?: Array<'documents' | 'notes'>,
    }): CancelablePromise<(ListResponse & {
        data?: Array<Monitor>;
    })> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/monitors',
            query: {
                'filter': filter,
                'page[number]': pageNumber,
                'page[size]': pageSize,
                'sort': sort,
                'search': search,
                'include': include,
            },
            errors: {
                400: `Bad request. The request is invalid.`,
                401: `Unauthorized.`,
            },
        });
    }

    /**
     * Create new `Monitor`.
     * @returns any A single `Monitor`.
     * @throws ApiError
     */
    public static createMonitor({
        requestBody,
    }: {
        requestBody?: (Monitor & {
            manufacturerId?: string;
        }),
    }): CancelablePromise<{
        data?: Monitor;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/monitors',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized.`,
                422: `Unprocessable entity.`,
            },
        });
    }

    /**
     * Delete the monitor identified by the given ID.
     * @returns void
     * @throws ApiError
     */
    public static deleteMonitor({
        id,
        force,
    }: {
        /** ID of the resource. **/
        id: string,
        /** Erzwingt die Durchführung des Requests, zum Beispiel endgültige Löschung
         * von soft deleted Entitäten.
         *
         * Aktiviert durch die Werte `true`, `1` oder `yes`. **/
        force?: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/monitors/{id}',
            path: {
                'id': id,
            },
            query: {
                'force': force,
            },
        });
    }

    /**
     * Get the monitor identified by the given ID.
     * @returns any A single `Monitor`.
     * @throws ApiError
     */
    public static getMonitorById({
        id,
        include,
    }: {
        /** ID of the resource. **/
        id: string,
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
    }): CancelablePromise<{
        data?: Monitor;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/monitors/{id}',
            path: {
                'id': id,
            },
            query: {
                'include': include,
            },
        });
    }

    /**
     * Update an monitor.
     * @returns any A single `Monitor`.
     * @throws ApiError
     */
    public static updateMonitor({
        id,
        requestBody,
    }: {
        /** ID of the resource. **/
        id: string,
        /** The `Monitor` object data to update.
         * - Send changed attributes only to minimize the risk of collisions during parallel updates. **/
        requestBody?: Monitor,
    }): CancelablePromise<{
        data?: Monitor;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/monitors/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}