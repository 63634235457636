/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { v5 as uuidv5 } from "uuid";

export class UUIDService {

    /**
     * Generate UUID.
     */
    public static generateUUIDFromID = (id: string): string => {
        const namespace = "your-namespace"; // Replace with your own namespace
        const fullString = `${namespace}:${id}`;
        const uuid = uuidv5(fullString, uuidv5.DNS);
    
        return uuid;
    };
}