import { useConfig } from 'base-shell/lib/providers/Config';

export const DisplayFormikState = (props) => {
  const config = useConfig();
  const {
    appConfig: { debug },
  } = config;

  if (!debug) {
    return null;
  }

  return (
    <div style={{ margin: '1rem 0', display: 'flex', overflow: 'scroll' }}>
      <h3 style={{ fontFamily: 'monospace' }} />
      <pre
        style={{
          background: '#f6f8fa',
          fontSize: '.65rem',
          padding: '.5rem',
        }}
      >
        {JSON.stringify(props, null, 2)}
      </pre>
    </div>
  );
};
