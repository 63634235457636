import React, { useState } from 'react';
import { Settings } from '_build/ts';
const initialState = {};

type SystemSettingsStoreProps = {
  systemSettings: Settings | null;
  setSystemSettings: (props: Settings) => void;
};

export const SystemSettingsContext = React.createContext<SystemSettingsStoreProps>({
  systemSettings: null,
  setSystemSettings: () => {},
});

export const SystemSettingsStore = ({ children }) => {
  const [systemSettings, setSystemSettings] = useState(initialState);

  return (
    <SystemSettingsContext.Provider value={{ systemSettings, setSystemSettings }}>
      {children}
    </SystemSettingsContext.Provider>
  );
};
