import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: 'none',
    color: '#ffffff',
    padding: theme.spacing(1, 1.25),
    fontSize: 15,
    fontWeight: 500,
    lineHeight: '26px',
    height: '40px',
  },
  medium: {
    height: '32px',
    padding: theme.spacing(0.75, 1.125),
  },
  small: {
    height: '24px',
    padding: theme.spacing(0.45, 1),
  },
  primaryText: {
    color: theme.palette.primary.main,
  },
  whiteText: {
    color: '#ffffff',
  },
  onlyPlus: {
    padding: 0,
    minWidth: '24px',
    maxWidth: '24px',
    borderRadius: '12px',
    '&.MuiButton-root.Mui-disabled': {
      color: '#ffffff',
      background: theme.palette.primary.main,
    },
  },
  grey: {
    color: theme.palette.primary.grey5,
  },
}));

const UniButton = ({
  text,
  variant,
  fontColor,
  background,
  small,
  medium,
  grey,
  ...props
}) => {
  const classes = useStyles();
  let isOutlined;
  let setColorWhite;
  if (variant === 'outlined') {
    isOutlined = true;
  }
  if (variant === 'contained' && background === 'primary') {
    setColorWhite = true;
  }

  return (
    <Button
      className={clsx(classes.button, {
        [classes.small]: small,
        [classes.medium]: medium,
        [classes.primaryText]: isOutlined,
        [classes.whiteText]: setColorWhite,
        [classes.onlyPlus]: text === '+',
        [classes.grey]: grey,
      })}
      variant={variant}
      type="button"
      style={{ backgroundColor: background, color: fontColor }}
      disableElevation
      {...props}
    >
      {text}
    </Button>
  );
};

export default UniButton;
