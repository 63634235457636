/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Role } from '../models/Role';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RolesService {

    /**
     * Get a list of `Role`s.
     * @returns any A list of `Role`s.
     * @throws ApiError
     */
    public static getRoles(): CancelablePromise<{
        data?: Array<Role>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/roles',
        });
    }

    /**
     * Get a single `Role` identified by the given ID.
     * @returns any A single `Role`.
     * @throws ApiError
     */
    public static getRoleById({
        id,
    }: {
        /** ID of the resource. **/
        id: string,
    }): CancelablePromise<{
        data?: Role;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/roles/{id}',
            path: {
                'id': id,
            },
        });
    }

}