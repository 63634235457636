/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { _Includes } from '../models/_Includes';
import type { Job } from '../models/Job';
import type { ListResponse } from '../models/ListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class JobsService {

    /**
     * Get a paginated list of `Job`s.
     * @returns any A paginated list of `Job`s.
     * @throws ApiError
     */
    public static getJobs({
        pageSize,
        pageNumber,
        filter,
        sort,
        include,
    }: {
        /** Number of items to return per page. **/
        pageSize?: number,
        /** Number of the page to load. **/
        pageNumber?: number,
        /** Filteroptionen. **/
        filter?: any,
        /** Sortierparameter.
         *
         * Es kann nach mehreren Attributen nacheinander sortiert werden. Dazu
         * werden die zu verwendenen Attribute als Komma-getrenntes Array
         * übergeben.
         *
         * Standardmäßig wird aufsteigend sortiert. Um die Reihenfolge umzukehren,
         * kann ein Minus vor den Attributnamen gesetzt werden.
         *
         * Beispiel: `?sort=-birthday,lastname` => Jüngste zuerst, Personen mit
         * gleichem Geburtstag werden nach Name sortiert.
         *  **/
        sort?: Array<string>,
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
    }): CancelablePromise<(ListResponse & {
        data?: Array<Job>;
    })> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/jobs',
            query: {
                'page[size]': pageSize,
                'page[number]': pageNumber,
                'filter': filter,
                'sort': sort,
                'include': include,
            },
        });
    }

    /**
     * Create a new `Job`.
     * @returns any A single `Job`.
     * @throws ApiError
     */
    public static createJob({
        requestBody,
    }: {
        /** A `Job` to create. **/
        requestBody: Job,
    }): CancelablePromise<{
        data?: Job;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/jobs',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Update the `Job` identified by `id`.
     * @returns any A single `Job`.
     * @throws ApiError
     */
    public static patchJob({
        id,
        requestBody,
    }: {
        /** ID of the resource. **/
        id: string,
        /** The `Job` object data to update. **/
        requestBody?: Job,
    }): CancelablePromise<{
        data?: Job;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/jobs/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get the `Job` identified by `id`.
     * @returns any A single `Job`.
     * @throws ApiError
     */
    public static getJob({
        id,
        include,
    }: {
        /** ID of the resource. **/
        id: string,
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
    }): CancelablePromise<{
        data?: Job;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/jobs/{id}',
            path: {
                'id': id,
            },
            query: {
                'include': include,
            },
        });
    }

    /**
     * Delete the `Job` identified by `id`.
     * @returns void
     * @throws ApiError
     */
    public static deleteJob({
        id,
    }: {
        /** ID of the resource. **/
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/jobs/{id}',
            path: {
                'id': id,
            },
        });
    }

}