/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PersonRef } from '../models/PersonRef';
import type { Role } from '../models/Role';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AuthService {

    /**
     * Get the CSRF-Cookie required for `/auth/stateful-login`.
     *
     * See: https://laravel.com/docs/8.x/sanctum#csrf-protection
     * @returns void
     * @throws ApiError
     */
    public static getCsrfCookie(): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/auth/csrf-cookie',
        });
    }

    /**
     * Log in and get the authentication token.
     * @returns any Successfully authenticated.
     * @throws ApiError
     */
    public static login({
        requestBody,
    }: {
        requestBody?: {
            /**
             * The user's email address.
             */
            email: string;
            /**
             * The user's password.
             */
            password: string;
        },
    }): CancelablePromise<{
        /**
         * Bearer token
         */
        accessToken: string;
        /**
         * Token type `bearer` or `cookie`.
         */
        tokenType: 'bearer' | 'cookie';
        scope: {
            readonly id?: string;
            /**
             * E-Mail-Adresse des Nutzers.
             */
            email: string;
            /**
             * Name des eingeloggten Benutzers.
             * - `Contact` oder `Customer`
             * - `isCompany == true`: name1
             * - `isCompany == false`: `titlePrefix firstName lastName`
             * - `Employee`: `titlePrefix firstName lastName`
             */
            profileName: string;
            expirationDate?: string;
            readonly customerId?: string | null;
            /**
             * Entität, die den Nutzer repräsentiert (=> `Contact`, `Customer` oder `Employee`).
             */
            readonly user?: PersonRef;
            /**
             * Roles of the user.
             */
            roles?: Array<Role>;
            /**
             * Permissions of the user.
             */
            permissions?: Record<string, any>;
        };
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/login',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Log out and invalidate the authentication token.
     * @returns void
     * @throws ApiError
     */
    public static logout(): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/logout',
        });
    }

    /**
     * Request a password reset link. The link is mailed to the user.
     * @returns void
     * @throws ApiError
     */
    public static passwordRequest({
        requestBody,
    }: {
        requestBody: {
            email?: string;
        },
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/password-request',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Reset the user's password.
     * @returns any Empty response, e.g. result of `DELETE`.
     * @throws ApiError
     */
    public static passwordReset({
        token,
        requestBody,
    }: {
        /** Token from password reset link emailed to the user. **/
        token: string,
        requestBody: {
            email: string;
            password: string;
            passwordConfirmation: string;
        },
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/password-reset',
            query: {
                'token': token,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * SPA login endpoint.
     *
     * The response contains an authentication cookie which allows to bypass authentication token handling.
     *
     * Requires a valid CSRF-token.
     * @returns string Successfully authenticated. The session id is returned in a cookie named `pestix_session`. You need to include this cookie in subsequent requests.
     * @throws ApiError
     */
    public static statefulLogin({
        requestBody,
    }: {
        requestBody?: {
            /**
             * The user's email address.
             */
            email: string;
            /**
             * The user's password.
             */
            password: string;
        },
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/stateful-login',
            body: requestBody,
            mediaType: 'application/json',
            responseHeader: 'Set-Cookie',
        });
    }

    /**
     * Refresh bearer token.
     * @returns any Successfully authenticated. Returns Bearer token and current user.
     * FIXME: Authenticated or refreshed?
     *
     * @throws ApiError
     */
    public static token(): CancelablePromise<{
        token?: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/auth/token',
        });
    }

}