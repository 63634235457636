import { createSlice } from '@reduxjs/toolkit';
import { PageContextEnum } from 'features/enums';

export type ListColumnState = {
  pages: {
    [key in PageContextEnum]: {
      defaultColumns: any[];
    };
  };
};

const initialState: ListColumnState = {
  pages: Object.fromEntries(
    Object.entries(PageContextEnum).map((value: [string, PageContextEnum]) => [
      value[1] as any,
      { defaultColumns: [] },
    ])
  ),
};

export const listColumnsSlice = createSlice({
  name: 'listColumns',
  initialState,
  reducers: {
    initColumns: (state, action) => {
      const { data, context } = action.payload;
      state.pages[context].defaultColumns = data;
      state.pages[context].currentColumns = data;
    },
  },
});

// Action creators are generated for each case reducer function
export const { initColumns } = listColumnsSlice.actions;

export default listColumnsSlice.reducer;
