import React from 'react';

type ErrorBoundaryContextParams = {
  triggerError: (error: any) => boolean;
};

const ErrorBoundaryContext = React.createContext<ErrorBoundaryContextParams>({
  triggerError: () => true,
});
export default ErrorBoundaryContext;
