/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { _Includes } from '../models/_Includes';
import type { ListResponse } from '../models/ListResponse';
import type { OrderItem } from '../models/OrderItem';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OrderItemsService {

    /**
     * Get a paginated list of `OrderItem`s.
     * @returns any A list of `OrderItem`es.
     * @throws ApiError
     */
    public static getOrderItemsList(): CancelablePromise<(ListResponse & {
        data?: Array<OrderItem>;
    })> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/order-items',
            errors: {
                400: `Bad request. The request is invalid.`,
                401: `Unauthorized.`,
            },
        });
    }

    /**
     * Create new `OrderItem`.
     * @returns any A single `OrderItem`.
     * @throws ApiError
     */
    public static createOrderItem({
        requestBody,
    }: {
        /** A `ORderItem` to create. **/
        requestBody?: OrderItem,
    }): CancelablePromise<{
        data?: OrderItem;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/order-items',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized.`,
                422: `Unprocessable entity.`,
            },
        });
    }

    /**
     * Delete the OrderItem identified by the given ID.
     * @returns void
     * @throws ApiError
     */
    public static deleteOrderItem({
        id,
        force,
    }: {
        /** ID of the resource. **/
        id: string,
        /** Erzwingt die Durchführung des Requests, zum Beispiel endgültige Löschung
         * von soft deleted Entitäten.
         *
         * Aktiviert durch die Werte `true`, `1` oder `yes`. **/
        force?: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/order-items/{id}',
            path: {
                'id': id,
            },
            query: {
                'force': force,
            },
        });
    }

    /**
     * Get the OrderItem identified by the given ID.
     * @returns any A single `OrderItem`.
     * @throws ApiError
     */
    public static getOrderItemById({
        id,
        include,
    }: {
        /** ID of the resource. **/
        id: string,
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
    }): CancelablePromise<{
        data?: OrderItem;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/order-items/{id}',
            path: {
                'id': id,
            },
            query: {
                'include': include,
            },
        });
    }

    /**
     * Update an OrderItem.
     * @returns any A single `OrderItem`.
     * @throws ApiError
     */
    public static updateOrderItem({
        id,
        requestBody,
    }: {
        /** ID of the resource. **/
        id: string,
        /** The `OrderItem` object data to update.
         * - Send changed attributes only to minimize the risk of collisions during parallel updates. **/
        requestBody?: OrderItem,
    }): CancelablePromise<{
        data?: OrderItem;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/order-items/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}