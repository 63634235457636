/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { _Includes } from '../models/_Includes';
import type { Branch } from '../models/Branch';
import type { ListResponse } from '../models/ListResponse';
import type { PaymentTerms } from '../models/PaymentTerms';
import type { Pest } from '../models/Pest';
import type { ServiceArea } from '../models/ServiceArea';
import type { Settings } from '../models/Settings';
import type { SettingsDocumentation } from '../models/SettingsDocumentation';
import type { SettingsGeneral } from '../models/SettingsGeneral';
import type { ValueAddedTaxRate } from '../models/ValueAddedTaxRate';
import type { ValueAddedTaxValue } from '../models/ValueAddedTaxValue';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SettingsService {

    /**
     * Get all `Settings`.
     * @returns any Settings.
     * @throws ApiError
     */
    public static getSettings({
        include,
    }: {
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
    }): CancelablePromise<{
        data?: Settings;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/settings',
            query: {
                'include': include,
            },
        });
    }

    /**
     * Get all `Settings`.
     * @returns any Systemeinstellungen -> Allgemein.
     * @throws ApiError
     */
    public static getSettingsGeneral({
        include,
    }: {
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
    }): CancelablePromise<{
        data?: SettingsGeneral;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/settings/general',
            query: {
                'include': include,
            },
        });
    }

    /**
     * Aktualisiert den Bereich _Allgemein_ der Systemeinstellungen.
     * @returns any Systemeinstellungen -> Allgemein.
     * @throws ApiError
     */
    public static updateSettingsGeneral({
        requestBody,
    }: {
        /** The `SettingsGeneral` object data to update.
         * - Send changed attributes only to minimize the risk of collisions during parallel updates. **/
        requestBody?: SettingsGeneral,
    }): CancelablePromise<{
        data?: SettingsGeneral;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/settings/general',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get all `SettingsDocumentation`.
     * @returns any Systemeinstellungen -> Dokumentation.
     * @throws ApiError
     */
    public static getSettingsDocumentation(): CancelablePromise<{
        data?: SettingsDocumentation;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/settings/documentation',
        });
    }

    /**
     * Aktualisiert den Bereich _Dokumentation_ der Systemeinstellungen.
     * @returns any Systemeinstellungen -> Dokumentation.
     * @throws ApiError
     */
    public static updateSettingsDocumentation({
        requestBody,
    }: {
        /** The `SettingsDocumentation` object data to update.
         * - Send changed attributes only to minimize the risk of collisions during parallel updates. **/
        requestBody?: SettingsDocumentation,
    }): CancelablePromise<{
        data?: SettingsDocumentation;
    }> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/settings/documentation',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get all `Branch`es.
     * @returns any A list of `Branch`es.
     * @throws ApiError
     */
    public static getBranches({
        include,
        pageSize,
        pageNumber,
        filter,
        sort,
    }: {
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
        /** Number of items to return per page. **/
        pageSize?: number,
        /** Number of the page to load. **/
        pageNumber?: number,
        /** Filteroptionen. **/
        filter?: any,
        /** Sortierparameter.
         *
         * Es kann nach mehreren Attributen nacheinander sortiert werden. Dazu
         * werden die zu verwendenen Attribute als Komma-getrenntes Array
         * übergeben.
         *
         * Standardmäßig wird aufsteigend sortiert. Um die Reihenfolge umzukehren,
         * kann ein Minus vor den Attributnamen gesetzt werden.
         *
         * Beispiel: `?sort=-birthday,lastname` => Jüngste zuerst, Personen mit
         * gleichem Geburtstag werden nach Name sortiert.
         *  **/
        sort?: Array<string>,
    }): CancelablePromise<(ListResponse & {
        data?: Array<Branch>;
    })> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/settings/branches',
            query: {
                'include': include,
                'page[size]': pageSize,
                'page[number]': pageNumber,
                'filter': filter,
                'sort': sort,
            },
        });
    }

    /**
     * Create a new `Branch` within `Settings`.
     * @returns any A single `Branch`.
     * @throws ApiError
     */
    public static createBranch({
        requestBody,
    }: {
        /** A `Branch` JSON object. **/
        requestBody?: Branch,
    }): CancelablePromise<{
        data?: Branch;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/settings/branches',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get the `Branch` identified by `id`.
     * @returns any A single `Branch`.
     * @throws ApiError
     */
    public static getBranch({
        id,
        include,
    }: {
        /** ID of the resource. **/
        id: string,
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
    }): CancelablePromise<{
        data?: Branch;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/settings/branches/{id}',
            path: {
                'id': id,
            },
            query: {
                'include': include,
            },
        });
    }

    /**
     * Update the `Branch` identified by the given ID.
     * @returns any A single `Branch`.
     * @throws ApiError
     */
    public static updateBranch({
        id,
        requestBody,
    }: {
        /** ID of the resource. **/
        id: string,
        /** The `Branch` object data to update.
         * - Send changed attributes only to minimize the risk of collisions during parallel updates. **/
        requestBody?: Branch,
    }): CancelablePromise<{
        data?: Branch;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/settings/branches/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete the `Branch` identified by the given ID.
     * @returns void
     * @throws ApiError
     */
    public static deleteBranch({
        id,
    }: {
        /** ID of the resource. **/
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/settings/branches/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Get all `ValueAddedTaxRate`s.
     * @returns any A list of `ValueAddedTaxRate`s.
     * @throws ApiError
     */
    public static getValueAddedTaxRates({
        include,
        pageSize,
        pageNumber,
        filter,
        sort,
    }: {
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
        /** Number of items to return per page. **/
        pageSize?: number,
        /** Number of the page to load. **/
        pageNumber?: number,
        /** Filteroptionen. **/
        filter?: any,
        /** Sortierparameter.
         *
         * Es kann nach mehreren Attributen nacheinander sortiert werden. Dazu
         * werden die zu verwendenen Attribute als Komma-getrenntes Array
         * übergeben.
         *
         * Standardmäßig wird aufsteigend sortiert. Um die Reihenfolge umzukehren,
         * kann ein Minus vor den Attributnamen gesetzt werden.
         *
         * Beispiel: `?sort=-birthday,lastname` => Jüngste zuerst, Personen mit
         * gleichem Geburtstag werden nach Name sortiert.
         *  **/
        sort?: Array<string>,
    }): CancelablePromise<(ListResponse & {
        data?: Array<ValueAddedTaxRate>;
    })> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/settings/value-added-tax-rates',
            query: {
                'include': include,
                'page[size]': pageSize,
                'page[number]': pageNumber,
                'filter': filter,
                'sort': sort,
            },
        });
    }

    /**
     * Create a new `ValueAddedTaxRate` within `Settings`.
     * @returns any A single `ValueAddedTaxRate`.
     * @throws ApiError
     */
    public static createValueAddedTaxRate({
        requestBody,
    }: {
        /** A `ValueAddedTaxRate` JSON object. **/
        requestBody?: ValueAddedTaxRate,
    }): CancelablePromise<{
        data?: ValueAddedTaxRate;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/settings/value-added-tax-rates',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get the `ValueAddedTaxRate` identified by `id`.
     * @returns any A single `ValueAddedTaxRate`.
     * @throws ApiError
     */
    public static getValueAddedTaxRate({
        id,
        include,
    }: {
        /** ID of the resource. **/
        id: string,
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
    }): CancelablePromise<{
        data?: ValueAddedTaxRate;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/settings/value-added-tax-rates/{id}',
            path: {
                'id': id,
            },
            query: {
                'include': include,
            },
        });
    }

    /**
     * Update the `ValueAddedTaxRate` identified by the given ID.
     * @returns any A single `ValueAddedTaxRate`.
     * @throws ApiError
     */
    public static updateValueAddedTaxRate({
        id,
        requestBody,
    }: {
        /** ID of the resource. **/
        id: string,
        /** The `ValueAddedTaxRate` object data to update.
         * - Send changed attributes only to minimize the risk of collisions during parallel updates. **/
        requestBody?: ValueAddedTaxRate,
    }): CancelablePromise<{
        data?: ValueAddedTaxRate;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/settings/value-added-tax-rates/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete the `ValueAddedTaxRate` identified by the given ID.
     * @returns void
     * @throws ApiError
     */
    public static deleteValueAddedTaxRate({
        id,
    }: {
        /** ID of the resource. **/
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/settings/value-added-tax-rates/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Get all `ValueAddedTaxValue`s.
     * @returns any A list of `ValueAddedTaxValue`s.
     * @throws ApiError
     */
    public static getValueAddedTaxValues({
        include,
        pageSize,
        pageNumber,
        filter,
        sort,
    }: {
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
        /** Number of items to return per page. **/
        pageSize?: number,
        /** Number of the page to load. **/
        pageNumber?: number,
        /** Filteroptionen. **/
        filter?: any,
        /** Sortierparameter.
         *
         * Es kann nach mehreren Attributen nacheinander sortiert werden. Dazu
         * werden die zu verwendenen Attribute als Komma-getrenntes Array
         * übergeben.
         *
         * Standardmäßig wird aufsteigend sortiert. Um die Reihenfolge umzukehren,
         * kann ein Minus vor den Attributnamen gesetzt werden.
         *
         * Beispiel: `?sort=-birthday,lastname` => Jüngste zuerst, Personen mit
         * gleichem Geburtstag werden nach Name sortiert.
         *  **/
        sort?: Array<string>,
    }): CancelablePromise<(ListResponse & {
        data?: Array<ValueAddedTaxValue>;
    })> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/settings/value-added-tax-values',
            query: {
                'include': include,
                'page[size]': pageSize,
                'page[number]': pageNumber,
                'filter': filter,
                'sort': sort,
            },
        });
    }

    /**
     * Create a new `ValueAddedTaxValue` within `Settings`.
     * @returns any A single `ValueAddedTaxValue`.
     * @throws ApiError
     */
    public static createValueAddedTaxValue({
        requestBody,
    }: {
        /** A `ValueAddedTaxValue` JSON object. **/
        requestBody?: ValueAddedTaxValue,
    }): CancelablePromise<{
        data?: ValueAddedTaxValue;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/settings/value-added-tax-values',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get the `ValueAddedTaxValue` identified by `id`.
     * @returns any A single `ValueAddedTaxValue`.
     * @throws ApiError
     */
    public static getValueAddedTaxValue({
        id,
        include,
    }: {
        /** ID of the resource. **/
        id: string,
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
    }): CancelablePromise<{
        data?: ValueAddedTaxValue;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/settings/value-added-tax-values/{id}',
            path: {
                'id': id,
            },
            query: {
                'include': include,
            },
        });
    }

    /**
     * Update the `ValueAddedTaxValue` identified by the given ID.
     * @returns any A single `ValueAddedTaxValue`.
     * @throws ApiError
     */
    public static updateValueAddedTaxValue({
        id,
        requestBody,
    }: {
        /** ID of the resource. **/
        id: string,
        /** The `ValueAddedTaxValue` object data to update.
         * - Send changed attributes only to minimize the risk of collisions during parallel updates. **/
        requestBody?: ValueAddedTaxValue,
    }): CancelablePromise<{
        data?: ValueAddedTaxValue;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/settings/value-added-tax-values/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete the `ValueAddedTaxValue` identified by the given ID.
     * @returns void
     * @throws ApiError
     */
    public static deleteValueAddedTaxValue({
        id,
    }: {
        /** ID of the resource. **/
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/settings/value-added-tax-values/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Get all `ServiceArea`s.
     * @returns any A list of `ServiceArea`s.
     * @throws ApiError
     */
    public static getServiceAreas({
        include,
        pageSize,
        pageNumber,
        filter,
        sort,
    }: {
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
        /** Number of items to return per page. **/
        pageSize?: number,
        /** Number of the page to load. **/
        pageNumber?: number,
        /** Filteroptionen. **/
        filter?: any,
        /** Sortierparameter.
         *
         * Es kann nach mehreren Attributen nacheinander sortiert werden. Dazu
         * werden die zu verwendenen Attribute als Komma-getrenntes Array
         * übergeben.
         *
         * Standardmäßig wird aufsteigend sortiert. Um die Reihenfolge umzukehren,
         * kann ein Minus vor den Attributnamen gesetzt werden.
         *
         * Beispiel: `?sort=-birthday,lastname` => Jüngste zuerst, Personen mit
         * gleichem Geburtstag werden nach Name sortiert.
         *  **/
        sort?: Array<string>,
    }): CancelablePromise<(ListResponse & {
        data?: Array<ServiceArea>;
    })> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/settings/service-areas',
            query: {
                'include': include,
                'page[size]': pageSize,
                'page[number]': pageNumber,
                'filter': filter,
                'sort': sort,
            },
        });
    }

    /**
     * Create a new `ServiceArea` within `Settings`.
     * @returns any A single `ServiceArea`.
     * @throws ApiError
     */
    public static createServiceArea({
        requestBody,
    }: {
        /** A `ServiceArea` JSON object. **/
        requestBody?: ServiceArea,
    }): CancelablePromise<{
        data?: ServiceArea;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/settings/service-areas',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get the `ServiceArea` identified by `id`.
     * @returns any A single `ServiceArea`.
     * @throws ApiError
     */
    public static getServiceArea({
        id,
        include,
    }: {
        /** ID of the resource. **/
        id: string,
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
    }): CancelablePromise<{
        data?: ServiceArea;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/settings/service-areas/{id}',
            path: {
                'id': id,
            },
            query: {
                'include': include,
            },
        });
    }

    /**
     * Update the `ServiceArea` identified by the given ID.
     * @returns any A single `ServiceArea`.
     * @throws ApiError
     */
    public static updateServiceArea({
        id,
        requestBody,
    }: {
        /** ID of the resource. **/
        id: string,
        /** The `ServiceArea` object data to update.
         * - Send changed attributes only to minimize the risk of collisions during parallel updates. **/
        requestBody?: ServiceArea,
    }): CancelablePromise<{
        data?: ServiceArea;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/settings/service-areas/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete the `ServiceArea` identified by the given ID.
     * @returns void
     * @throws ApiError
     */
    public static deleteServiceArea({
        id,
    }: {
        /** ID of the resource. **/
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/settings/service-areas/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Get all `PaymentTerms`.
     * @returns any A list of `PaymentTerms`.
     * @throws ApiError
     */
    public static getPaymentTermsList({
        include,
        pageSize,
        pageNumber,
        filter,
        sort,
    }: {
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
        /** Number of items to return per page. **/
        pageSize?: number,
        /** Number of the page to load. **/
        pageNumber?: number,
        /** Filteroptionen. **/
        filter?: any,
        /** Sortierparameter.
         *
         * Es kann nach mehreren Attributen nacheinander sortiert werden. Dazu
         * werden die zu verwendenen Attribute als Komma-getrenntes Array
         * übergeben.
         *
         * Standardmäßig wird aufsteigend sortiert. Um die Reihenfolge umzukehren,
         * kann ein Minus vor den Attributnamen gesetzt werden.
         *
         * Beispiel: `?sort=-birthday,lastname` => Jüngste zuerst, Personen mit
         * gleichem Geburtstag werden nach Name sortiert.
         *  **/
        sort?: Array<string>,
    }): CancelablePromise<(ListResponse & {
        data?: Array<PaymentTerms>;
    })> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/settings/payment-terms',
            query: {
                'include': include,
                'page[size]': pageSize,
                'page[number]': pageNumber,
                'filter': filter,
                'sort': sort,
            },
        });
    }

    /**
     * Create a new `PaymentTerms` within `Settings`.
     * @returns any A single `PaymentTerms`.
     * @throws ApiError
     */
    public static createPaymentTerms({
        requestBody,
    }: {
        /** A `PaymentTerms` JSON object. **/
        requestBody?: PaymentTerms,
    }): CancelablePromise<{
        data?: PaymentTerms;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/settings/payment-terms',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get the `ServiceArea` identified by `id`.
     * @returns any A single `PaymentTerms`.
     * @throws ApiError
     */
    public static getPaymentTerms({
        id,
        include,
    }: {
        /** ID of the resource. **/
        id: string,
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
    }): CancelablePromise<{
        data?: PaymentTerms;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/settings/payment-terms/{id}',
            path: {
                'id': id,
            },
            query: {
                'include': include,
            },
        });
    }

    /**
     * Update the `PaymentTerms` identified by the given ID.
     * @returns any A single `PaymentTerms`.
     * @throws ApiError
     */
    public static updatePaymentTerms({
        id,
        requestBody,
    }: {
        /** ID of the resource. **/
        id: string,
        /** The `PaymentTerms` object data to update.
         * - Send changed attributes only to minimize the risk of collisions during parallel updates. **/
        requestBody?: PaymentTerms,
    }): CancelablePromise<{
        data?: PaymentTerms;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/settings/payment-terms/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete the `PaymentTerms` identified by the given ID.
     * @returns void
     * @throws ApiError
     */
    public static deletePaymentTerms({
        id,
    }: {
        /** ID of the resource. **/
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/settings/payment-terms/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Get all `Pest`s.
     * @returns any A list of `Pest`s.
     * @throws ApiError
     */
    public static getPests({
        include,
        pageSize,
        pageNumber,
        filter,
        sort,
    }: {
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
        /** Number of items to return per page. **/
        pageSize?: number,
        /** Number of the page to load. **/
        pageNumber?: number,
        /** Filteroptionen. **/
        filter?: any,
        /** Sortierparameter.
         *
         * Es kann nach mehreren Attributen nacheinander sortiert werden. Dazu
         * werden die zu verwendenen Attribute als Komma-getrenntes Array
         * übergeben.
         *
         * Standardmäßig wird aufsteigend sortiert. Um die Reihenfolge umzukehren,
         * kann ein Minus vor den Attributnamen gesetzt werden.
         *
         * Beispiel: `?sort=-birthday,lastname` => Jüngste zuerst, Personen mit
         * gleichem Geburtstag werden nach Name sortiert.
         *  **/
        sort?: Array<string>,
    }): CancelablePromise<(ListResponse & {
        data?: Array<Pest>;
    })> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/settings/pests',
            query: {
                'include': include,
                'page[size]': pageSize,
                'page[number]': pageNumber,
                'filter': filter,
                'sort': sort,
            },
        });
    }

    /**
     * Create a new `Pest` within `Settings`.
     * @returns any A single `Pest`.
     * @throws ApiError
     */
    public static createPest({
        requestBody,
    }: {
        /** A `Pest` JSON object. **/
        requestBody?: Pest,
    }): CancelablePromise<{
        data?: Pest;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/settings/pests',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get the `Pest` identified by `id`.
     * @returns any A single `Pest`.
     * @throws ApiError
     */
    public static getPest({
        id,
        include,
    }: {
        /** ID of the resource. **/
        id: string,
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
    }): CancelablePromise<{
        data?: Pest;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/settings/pests/{id}',
            path: {
                'id': id,
            },
            query: {
                'include': include,
            },
        });
    }

    /**
     * Update the `Pest` identified by the given ID.
     * @returns any A single `Pest`.
     * @throws ApiError
     */
    public static updatePest({
        id,
        requestBody,
    }: {
        /** ID of the resource. **/
        id: string,
        /** The `Pest` object data to update.
         * - Send changed attributes only to minimize the risk of collisions during parallel updates. **/
        requestBody?: Pest,
    }): CancelablePromise<{
        data?: Pest;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/settings/pests/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete the `Pest` identified by the given ID.
     * @returns void
     * @throws ApiError
     */
    public static deletePest({
        id,
    }: {
        /** ID of the resource. **/
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/settings/pests/{id}',
            path: {
                'id': id,
            },
        });
    }

}