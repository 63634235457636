import { createSlice } from '@reduxjs/toolkit';
import { FetchedBreadcrumbProps } from 'components/Layout/FetchedBreadcrumb';

export type Breadcrumb = {
  label?: string;
  pathname?: string;
} & Partial<FetchedBreadcrumbProps>;

export type AppBarState = {
  breadcrumbs: Breadcrumb[];
};

const initialState: AppBarState = {
  breadcrumbs: [],
};

export const appBarSlice = createSlice({
  name: 'appBar',
  initialState,
  reducers: {
    setBreadcrumbs: (state, action: { type: string; payload: Breadcrumb[] }) => {
      state.breadcrumbs = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setBreadcrumbs } = appBarSlice.actions;

export default appBarSlice.reducer;
