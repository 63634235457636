import AuthorizedRoute from 'components/Routing/AuthorizedRoute';
import UnauthorizedRoute from 'base-shell/lib/components/UnauthorizedRoute/UnauthorizedRoute';
import React, { lazy } from 'react';
import { Actions, Subject } from 'utils/ability';
import { Route } from 'react-router-dom';

const EmployeeListPage = lazy(() => import('pages/Employee/pages/EmployeeListPage'));
const EmployeeDetailsPage = lazy(
  () => import('pages/Employee/pages/EmployeeDetailsPage')
);
const EmployeeCreateNewPage = lazy(
  () => import('pages/Employee/pages/EmployeeCreateNewPage')
);
const AppointmentListPage = lazy(
  () => import('pages/Appointment/pages/AppointmentListPage')
);
const AppointmentDetailsPage = lazy(
  () => import('pages/Appointment/pages/AppointmentDetailsPage')
);
const AppointmentIncidentsPage = lazy(
  () => import('pages/Appointment/pages/AppointmentIncidentsPage')
);
// const AppointmentHistoryPage = lazy(
//   () => import('pages/Appointment/pages/AppointmentHistoryPage')
// );
// const AppointmentNotesPage = lazy(
//   () => import('pages/Appointment/pages/AppointmentNotesPage')
// );
// const EmployeeTimeSheets = lazy(() => import('pages/Employee/pages/EmployeeTimeSheets'));
// const EmployeeAbsences = lazy(() => import('pages/Employee/pages/EmployeeAbsences'));
// const EmployeeTimeRecording = lazy(
//   () => import('pages/Employee/pages/EmployeeTimeRecording')
// );

const EmployeeAccounts = lazy(() => import('pages/Employee/pages/EmployeeAccounts'));
const EmployeeDocuments = lazy(
  () => import('pages/Employee/pages/EmployeeDocumentsPage')
);
const EmployeeNotes = lazy(() => import('pages/Employee/pages/EmployeeNotes'));
// const EmployeeHistory = lazy(() => import('pages/Employee/pages/EmployeeHistory'));
const Dashboard = lazy(() => import('pages/Dashboard/pages/DashboardPage'));
const Documentation = lazy(
  () => import('pages/Documentation/pages/DocumentationOverviewPage')
);
const DocumentationIncidents = lazy(
  () => import('pages/Documentation/pages/DocumentationIncidentsPage')
);
const DocumentationMonitors = lazy(
  () => import('pages/Documentation/pages/DocumentationMonitorsPage')
);
const DocumentationReportings = lazy(
  () => import('pages/Documentation/pages/DocumentationReportingsPage')
);
// const Reporting = lazy(() => import('pages/Reporting/pages/ReportingPage'));
const SignIn = lazy(() => import('components/Authentication/SignIn'));
const SignUp = lazy(() => import('components/Authentication/SignUp'));
const PasswordReset = lazy(() => import('components/Authentication/PasswordReset'));
const NewPassword = lazy(() => import('components/Authentication/NewPassword'));
const ImprintPage = lazy(() => import('pages/Imprint/pages/ImprintPage'));
const PrivacyPage = lazy(() => import('pages/Privacy/pages/PrivacyPage'));
// const MyAccount = lazy(() => import('pages/MyAccount/pages/MyAccountPage'));
const CustomerListPage = lazy(() => import('pages/Customer/pages/CustomerListPage'));
const CustomerPropertiesPage = lazy(
  () => import('pages/Customer/pages/CustomerPropertiesPage')
);
const CreateCustomerPage = lazy(() => import('pages/Customer/pages/CreateCustomerPage'));
const CustomerContact = lazy(() => import('pages/Customer/pages/CustomerContactPage'));
const CustomerIncidentListPage = lazy(
  () => import('pages/Customer/pages/CustomerIncidentListPage')
);
const CustomerDetailsPage = lazy(
  () => import('pages/Customer/pages/CustomerDetailsPage')
);
const CustomerDocumentsPage = lazy(
  () => import('pages/Customer/pages/CustomerDocumentsPage')
);
const CustomerSettingsPage = lazy(
  () => import('pages/Customer/pages/CustomerSettingsPage')
);
const CustomerProcessesListPage = lazy(
  () => import('pages/Customer/pages/CustomerProcessesListPage')
);
const CustomerContractsPage = lazy(
  () => import('pages/Customer/pages/CustomerContractsPage')
);

const CustomerAuthSettingsPage = lazy(
  () => import('pages/Customer/pages/CustomerAuthSettingsPage')
);
const CustomerNotesPage = lazy(() => import('pages/Customer/pages/CustomerNotesPage'));
const ContractDraftsListPage = lazy(
  () => import('pages/Contracts/pages/ContractDraftListPage')
);
const ContractDetailsPage = lazy(
  () => import('pages/Contracts/pages/ContractDetailsPage')
);
const ContractPositionsPage = lazy(
  () => import('pages/Contracts/pages/ContractPositionsPage')
);
// const ContractHistoryPage = lazy(
//   () => import('pages/Contracts/pages/ContractsHistoryPage')
// );
const ContractDocumentsPage = lazy(
  () => import('pages/Contracts/pages/ContractDocumentsPage')
);
const ContractsListPage = lazy(() => import('pages/Contracts/pages/ContractsListPage'));
const OffersListPage = lazy(() => import('pages/Processes/pages/OffersListPage'));
const ProcessesListPage = lazy(() => import('pages/Processes/pages/OrdersListPage'));
const ProcessDetailsPage = lazy(() => import('pages/Processes/pages/ProcessDetailsPage'));
const ProcessCreatePage = lazy(() => import('pages/Processes/pages/CreateProcessPage'));
const ProcessItemsPage = lazy(() => import('pages/Processes/pages/ProcessItemsPage'));
const ProcessJobsPage = lazy(() => import('pages/Processes/pages/ProcessJobsPage'));
// const CustomerHistoryPage = lazy(
//   () => import('pages/Customer/pages/CustomerHistoryPage')
// );
const CustomerPricePage = lazy(
  () => import('pages/Customer/pages/CustomerPriceListPage')
);
const PropertyListPage = lazy(() => import('../pages/Properties/pages/PropertyListPage'));
const PropertyDetailsPage = lazy(
  () => import('../pages/Properties/pages/PropertyDetailsPage')
);
const PropertyCreatePage = lazy(
  () => import('../pages/Properties/pages/PropertyCreatePage')
);
// const PropertyContactPage = lazy(
//   () => import('../pages/Properties/pages/PropertyContactPage')
// );
const PropertyContractsPage = lazy(
  () => import('pages/Properties/pages/PropertyContractsPage')
);
const PropertyOrderFilesPage = lazy(
  () => import('pages/Properties/pages/PropertyOrderFilesPage')
);
const PropertyDeficienciesPage = lazy(
  () => import('../pages/Properties/pages/PropertyDeficienciesPage')
);
const PropertyAppointmentsPage = lazy(
  () => import('pages/Properties/pages/PropertyAppointmentsPage')
);
const PropertyDocumentsPage = lazy(
  () => import('../pages/Properties/pages/PropertyDocumentsPage')
);
const PropertyAreasPage = lazy(
  () => import('../pages/Properties/pages/PropertyAreasPage')
);
// const PropertyDocumentationPage = lazy(
//   () => import('../pages/Properties/pages/PropertyDocumentationPage')
// );
const PropertyNotesPage = lazy(
  () => import('../pages/Properties/pages/PropertyNotesPage')
);
// const PropertyHistoryPage = lazy(
//   () => import('../pages/Properties/pages/PropertyHistoryPage')
// );
const MonitorsListPage = lazy(() => import('../pages/Monitors/pages/MonitorsListPage'));
const MonitorDetailsPage = lazy(
  () => import('../pages/Monitors/pages/MonitorDetailsPage')
);
const MonitorCreatePage = lazy(() => import('../pages/Monitors/pages/MonitorCreatePage'));
// const MonitorHistoryPage = lazy(
//   () => import('../pages/Monitors/pages/MonitorHistoryPage')
// );
const DeficiencyListPage = lazy(
  () => import('../pages/Deficiencies/pages/IncidentsListPage')
);
const DeficiencyDetailsPage = lazy(
  () => import('../pages/Deficiencies/pages/DeficiencyDetailsPage')
);
const DeficiencyCreatePage = lazy(
  () => import('../pages/Deficiencies/pages/DeficiencyCreatePage')
);
const DeficiencyNotesPage = lazy(
  () => import('../pages/Deficiencies/pages/DeficiencyNotesPage')
);
// const DeficiencyHistoryPage = lazy(
//   () => import('../pages/Deficiencies/pages/DeficiencyHistoryPage')
// );
const InfestationDetailsPage = lazy(
  () => import('../pages/Infestations/pages/InfestationDetailsPage')
);
const InfestationCreatePage = lazy(
  () => import('../pages/Infestations/pages/InfestationCreatePage')
);
const InfestationNotesPage = lazy(
  () => import('../pages/Infestations/pages/InfestationNotesPage')
);
// const InfestationHistoryPage = lazy(
//   () => import('../pages/Infestations/pages/InfestationHistoryPage')
// );
const BranchListPage = lazy(
  () => import('../pages/Settings/pages/branches/BranchListPage')
);
const ServiceAreaListPage = lazy(
  () => import('../pages/Settings/pages/serviceAreas/ServiceAreaListPage')
);
const SettingsGeneralPage = lazy(
  () => import('../pages/Settings/pages/SettingsGeneralPage')
);
const SettingsPestsPage = lazy(() => import('../pages/Settings/pages/SettingsPestsPage'));
const SettingsDocumentationPage = lazy(
  () => import('../pages/Settings/pages/SettingsDocumentationPage')
);
const SettingsPaymentTermsPage = lazy(
  () => import('../pages/Settings/pages/SettingsPaymentTermsPage')
);
const SettingsVatPage = lazy(() => import('../pages/Settings/pages/SettingsVatPage'));
const SettingsUnitsPage = lazy(() => import('pages/Settings/pages/SettingsUnitsPage'));
const SettingsMonitorTypesPage = lazy(
  () => import('pages/Settings/pages/SettingsMonitorTypesPage')
);
const SettingsTextsPage = lazy(() => import('pages/Settings/pages/SettingsTextsPage'));
const ProductManagementListPage = lazy(
  () => import('pages/ProductManagement/pages/ProductManagementListPage')
);
const ProductManagementDetailsPage = lazy(
  () => import('pages/ProductManagement/pages/ProductManagementDetailsPage')
);
const ProductManagementCreateNewPage = lazy(
  () => import('pages/ProductManagement/pages/ProductManagementCreateNewPage')
);
const ProductManagementDocumentsPage = lazy(
  () => import('pages/ProductManagement/pages/ProductManagementDocumentsPage')
);
// const InvoiceProposalsPage = lazy(
//   () => import('pages/Accounting/pages/InvoiceProposalsPage')
// );
// const OpenItemsPage = lazy(() => import('pages/Accounting/pages/OpenItemsPage'));
// const PaymentProposalsPage = lazy(
//   () => import('pages/Accounting/pages/PaymentProposalsPage')
// );
const AccountingExportPage = lazy(
  () => import('pages/Accounting/pages/AccountingExportPage')
);

/* Permissions
 * every route must have requiredAbilities set. Routes can only be accessed if the user
 * has the required permissions or requiredAbilities is an empty array
 * Optionally, "rolesWhitelist" can be set. If set, the user must have one of the roles.
 */

const routes = [
  /* AUTHENTICATION */
  <UnauthorizedRoute
    key="/anmeldung"
    path="/anmeldung"
    redirectTo="/"
    exact
    component={SignIn}
    requiredAbilities={[]}
  />,
  <UnauthorizedRoute
    key="/registrierung"
    path="/registrierung"
    redirectTo="/"
    exact
    component={SignUp}
    requiredAbilities={[]}
  />,
  <UnauthorizedRoute
    key="/passwort-reset"
    path="/passwort-reset"
    redirectTo="/"
    exact
    component={PasswordReset}
    requiredAbilities={[]}
  />,
  <UnauthorizedRoute
    key="/neues-passwort"
    path="/neues-passwort"
    redirectTo="/"
    exact
    component={NewPassword}
    requiredAbilities={[]}
  />,
  <Route path={'/impressum'} key={'/impressum'} component={ImprintPage} />,
  <Route path={'/datenschutz'} key={'/datenschutz'} component={PrivacyPage} />,

  /* DASHBOARD */
  <AuthorizedRoute
    path="/"
    key="/"
    exact
    component={Dashboard}
    requiredAbilities={[]}
    // TODO replace with permissions when real data is shown in dashboard
  />,
  <AuthorizedRoute
    path="/dashboard"
    key="/dashboard"
    exact
    component={Dashboard}
    // TODO replace with permissions when real data is shown in dashboard
    requiredAbilities={[]}
  />,

  /* MITARBEITER */
  <AuthorizedRoute
    path="/mitarbeiter"
    key="/mitarbeiter"
    exact
    component={EmployeeListPage}
    requiredAbilities={[['show', 'employee']]}
  />,
  <AuthorizedRoute
    path="/mitarbeiter/neu"
    key="/mitarbeiter/neu"
    exact
    component={EmployeeCreateNewPage}
    requiredAbilities={[['create', 'employee']]}
  />,
  <AuthorizedRoute
    key="/mitarbeiter/:id/stammdaten"
    path="/mitarbeiter/:id/stammdaten"
    exact
    component={EmployeeDetailsPage}
    requiredAbilities={[['show', 'employee']]}
  />,
  // <AuthorizedRoute
  //   path="/mitarbeiter/zeitkonten"
  //   key="/mitarbeiter/zeitkonten"
  //   exact
  //   component={EmployeeTimeSheets}
  // />,
  // <AuthorizedRoute
  //   key="/mitarbeiter/:id/zeiterfassung"
  //   path="/mitarbeiter/:id/zeiterfassung"
  //   exact
  //   component={EmployeeTimeRecording}
  // />,
  // <AuthorizedRoute
  //   path="/mitarbeiter/abwesenheiten"
  //   key="/mitarbeiter/abwesenheiten"
  //   exact
  //   component={EmployeeAbsences}
  //   requiredRights={['employee.vacancies.show']}
  // />,
  <AuthorizedRoute
    key="/mitarbeiter/:id/benutzerkonten"
    path="/mitarbeiter/:id/benutzerkonten"
    exact
    component={EmployeeAccounts}
    requiredAbilities={[['show', 'employee.authSettings']]}
  />,
  <AuthorizedRoute
    key="/mitarbeiter/:id/dokumente"
    path="/mitarbeiter/:id/dokumente"
    exact
    component={EmployeeDocuments}
    requiredAbilities={[['show', 'employee.documents']]}
  />,
  <AuthorizedRoute
    key="/mitarbeiter/:id/notizen"
    path="/mitarbeiter/:id/notizen"
    exact
    component={EmployeeNotes}
    requiredAbilities={[['show', 'employee.notes']]}
  />,
  // <AuthorizedRoute
  //   key="/mitarbeiter/:id/historie"
  //   path="/mitarbeiter/:id/historie"
  //   exact
  //   component={EmployeeHistory}
  //   requiredAbilities={[['show', 'employee.history']]}
  // />,

  /* KUNDEN */
  <AuthorizedRoute
    key="/kunden"
    path="/kunden"
    exact
    component={CustomerListPage}
    requiredAbilities={[['show', 'customer']]}
  />,
  <AuthorizedRoute
    key="/kunden/neu"
    path="/kunden/neu"
    exact
    component={CreateCustomerPage}
    requiredAbilities={[['create', 'customer']]}
  />,
  <AuthorizedRoute
    key="/kunden/:id/stammdaten"
    path="/kunden/:id/stammdaten"
    exact
    component={CustomerDetailsPage}
    requiredAbilities={[['show', 'customer']]}
  />,
  <AuthorizedRoute
    key="/kunden/:id/stammdaten/einstellungen"
    path="/kunden/:id/stammdaten/einstellungen"
    exact
    component={CustomerSettingsPage}
    requiredAbilities={[['show', 'customer.notificationSettings']]}
  />,
  <AuthorizedRoute
    key="/kunden/:id/stammdaten/benutzerkonto"
    path="/kunden/:id/stammdaten/benutzerkonto"
    exact
    component={CustomerAuthSettingsPage}
    requiredAbilities={[['show', 'customer.authSettings']]}
  />,
  <AuthorizedRoute
    key="/kunden/:id/kontakte"
    path="/kunden/:id/kontakte"
    exact
    component={CustomerContact}
    requiredAbilities={[['show', 'contact']]}
  />,
  <AuthorizedRoute
    key="/kunden/:id/objekte"
    path="/kunden/:id/objekte"
    exact
    component={CustomerPropertiesPage}
    requiredAbilities={[['show', 'property']]}
  />,
  <AuthorizedRoute
    key="/kunden/:id/angebote-und-auftraege"
    path="/kunden/:id/angebote-und-auftraege"
    exact
    component={CustomerProcessesListPage}
    requiredAbilities={[['show', 'orderFile']]}
  />,
  <AuthorizedRoute
    key="/kunden/:id/vertraege"
    path="/kunden/:id/vertraege"
    exact
    component={CustomerContractsPage}
    requiredAbilities={[['show', 'contract']]}
  />,
  <AuthorizedRoute
    key="/kunden/:id/befaelleUndMaengel"
    path="/kunden/:id/befaelleUndMaengel"
    exact
    component={CustomerIncidentListPage}
    requiredAbilities={[
      ['show', 'deficiency'],
      ['show', 'infestation'],
    ]}
  />,
  <AuthorizedRoute
    key="/kunden/:id/dokumente"
    path="/kunden/:id/dokumente"
    exact
    component={CustomerDocumentsPage}
    requiredAbilities={[['show', 'customer.documents']]}
  />,
  <AuthorizedRoute
    key="/kunden/:id/preisliste"
    path="/kunden/:id/preisliste"
    exact
    component={CustomerPricePage}
    requiredAbilities={[['show', 'product']]}
  />,
  <AuthorizedRoute
    key="/kunden/:id/notizen"
    path="/kunden/:id/notizen"
    exact
    component={CustomerNotesPage}
    requiredAbilities={[['show', 'customer.notes']]}
  />,
  // <AuthorizedRoute
  //   key="/kunden/:id/historie"
  //   path="/kunden/:id/historie"
  //   exact
  //   component={CustomerHistoryPage}
  //   requiredAbilities={[['show', 'customer.history']]}
  // />,

  /* OBJEKTE */
  <AuthorizedRoute
    path="/objekte"
    exact
    component={PropertyListPage}
    requiredAbilities={[['show', 'property']]}
  />,
  <AuthorizedRoute
    key="/objekte/:id/stammdaten"
    path="/objekte/:id/stammdaten"
    exact
    component={PropertyDetailsPage}
    requiredAbilities={[['show', 'property']]}
  />,
  <AuthorizedRoute
    key="/objekte/neu"
    path="/objekte/neu"
    exact
    component={PropertyCreatePage}
    requiredAbilities={[['create', 'property']]}
  />,
  // <AuthorizedRoute
  //   key="/objekte/:id/ansprechpartner"
  //   path="/objekte/:id/ansprechpartner"
  //   exact
  //   component={PropertyContactPage}
  // />,
  <AuthorizedRoute
    key="/objekte/:id/angeboteUndAuftraege"
    path="/objekte/:id/angeboteUndAuftraege"
    exact
    component={PropertyOrderFilesPage}
    requiredAbilities={[['show', 'orderFile']]}
  />,
  <AuthorizedRoute
    key="/objekte/:id/vertraege"
    path="/objekte/:id/vertraege"
    exact
    component={PropertyContractsPage}
    requiredAbilities={[['show', 'contract']]}
  />,
  <AuthorizedRoute
    key="/objekte/:id/befaelleUndMaengel"
    path="/objekte/:id/befaelleUndMaengel"
    exact
    component={PropertyDeficienciesPage}
    requiredAbilities={[
      ['show', 'deficiency'],
      ['show', 'infestation'],
    ]}
  />,
  <AuthorizedRoute
    key="/objekte/:id/kundenbesuche"
    path="/objekte/:id/kundenbesuche"
    exact
    component={PropertyAppointmentsPage}
    requiredAbilities={[['show', 'appointment']]}
  />,
  <AuthorizedRoute
    key="/objekte/:id/dokumente"
    path="/objekte/:id/dokumente"
    exact
    component={PropertyDocumentsPage}
    requiredAbilities={[['show', 'property.documents']]}
  />,
  <AuthorizedRoute
    key="/objekte/:id/bereiche"
    path="/objekte/:id/bereiche"
    exact
    component={PropertyAreasPage}
    requiredAbilities={[['show', 'propertyArea']]}
  />,
  // <AuthorizedRoute
  //   key="/objekte/:id/dokumentation"
  //   path="/objekte/:id/dokumentation"
  //   exact
  //   component={PropertyDocumentationPage}
  // />,
  <AuthorizedRoute
    key="/objekte/:id/notizen"
    path="/objekte/:id/notizen"
    exact
    component={PropertyNotesPage}
    requiredAbilities={[['show', 'property.notes']]}
  />,
  // <AuthorizedRoute
  //   key="/objekte/:id/historie"
  //   path="/objekte/:id/historie"
  //   exact
  //   component={PropertyHistoryPage}
  //   requiredAbilities={[['show', 'property.history']]}
  // />,

  /* Appointments   */
  <AuthorizedRoute
    key="/kundenbesuche"
    path="/kundenbesuche"
    exact
    component={AppointmentListPage}
    requiredAbilities={[['show', 'appointment']]}
  />,
  <AuthorizedRoute
    key="/kundenbesuche/:id/details"
    path="/kundenbesuche/:id/details"
    exact
    component={AppointmentDetailsPage}
    requiredAbilities={[['show', 'appointment']]}
  />,
  // TODO appointments notes route not in api spec
  // <AuthorizedRoute
  //   key="/kundenbesuche/:id/notizen"
  //   path="/kundenbesuche/:id/notizen"
  //   exact
  //   component={AppointmentNotesPage}
  //   requiredAbilities={[['show', 'appointment.notes']]}
  // />,
  // <AuthorizedRoute
  //   key="/kundenbesuche/:id/historie"
  //   path="/kundenbesuche/:id/historie"
  //   exact
  //   component={AppointmentHistoryPage}
  //   requiredAbilities={[['show', 'appointment.history']]}
  // />,
  <AuthorizedRoute
    key="/kundenbesuche/:id/befaelleUndMaengel"
    path="/kundenbesuche/:id/befaelleUndMaengel"
    exact
    component={AppointmentIncidentsPage}
    requiredAbilities={[
      ['show', 'appointment'],
      ['show', 'infestation'],
      ['show', 'deficiency'],
    ]}
  />,

  /* INFESTATIONS AND DEFICIENCIES */
  <AuthorizedRoute
    key="/befaelleundmaengel"
    path="/befaelleundmaengel"
    exact
    component={DeficiencyListPage}
    requiredAbilities={[
      ['show', 'infestation'],
      ['show', 'deficiency'],
    ]}
  />,

  // Befälle
  <AuthorizedRoute
    key="/befaelle/neu"
    path="/befaelle/neu"
    exact
    component={InfestationCreatePage}
    requiredAbilities={[['create', 'infestation']]}
  />,
  <AuthorizedRoute
    key="/befaelle/:id"
    path="/befaelle/:id"
    exact
    component={InfestationDetailsPage}
    requiredAbilities={[['show', 'infestation']]}
  />,

  <AuthorizedRoute
    key="/befaelle/:id/notizen"
    path="/befaelle/:id/notizen"
    exact
    component={InfestationNotesPage}
    requiredAbilities={[['show', 'infestation.notes']]}
  />,
  // <AuthorizedRoute
  //   key="/befaelle/:id/historie"
  //   path="/befaelle/:id/historie"
  //   exact
  //   component={InfestationHistoryPage}
  //   requiredAbilities={[['show', 'infestation.history']]}
  // />,

  // Monitore
  <AuthorizedRoute
    key="/monitore"
    path="/monitore"
    exact
    component={MonitorsListPage}
    requiredAbilities={[['show', 'monitor']]}
  />,
  <AuthorizedRoute
    key="/monitore/neu"
    path="/monitore/neu"
    exact
    component={MonitorCreatePage}
    requiredAbilities={[['create', 'monitor']]}
  />,
  // <AuthorizedRoute
  //   key="/monitore/:id/historie"
  //   path="/monitore/:id/historie"
  //   exact
  //   requiredAbilities={[['show', 'monitor.history']]}
  //   component={MonitorHistoryPage}
  // />,

  // Mängel
  <AuthorizedRoute
    key="/maengel"
    path="/maengel"
    exact
    component={DeficiencyListPage}
    requiredAbilities={[['show', 'deficiency']]}
  />,
  <AuthorizedRoute
    key="/maengel/neu"
    path="/maengel/neu"
    exact
    component={DeficiencyCreatePage}
    requiredAbilities={[['create', 'deficiency']]}
  />,
  <AuthorizedRoute
    key="/maengel/:id"
    path="/maengel/:id"
    exact
    component={DeficiencyDetailsPage}
    requiredAbilities={[['show', 'deficiency']]}
  />,
  <AuthorizedRoute
    key="/maengel/:id/notizen"
    path="/maengel/:id/notizen"
    exact
    component={DeficiencyNotesPage}
    requiredAbilities={[['show', 'deficiency.notes']]}
  />,
  // <AuthorizedRoute
  //   key="/maengel/:id/historie"
  //   path="/maengel/:id/historie"
  //   exact
  //   component={DeficiencyHistoryPage}
  //   requiredAbilities={[['show', 'deficiency.history']]}
  // />,

  /* ARTIKELVERWALTUNG */
  <AuthorizedRoute
    key="/artikelverwaltung"
    path="/artikelverwaltung"
    exact
    component={ProductManagementListPage}
    requiredAbilities={[['show', 'product']]}
    rolesWhitelist={[
      'pestix.techniker',
      'pestix.buchhaltung',
      'admin',
      'pestix.niederlassungsleitung',
      'pestix.service-leitung',
    ]}
  />,
  <AuthorizedRoute
    key="/artikelverwaltung/neu"
    path="/artikelverwaltung/neu"
    exact
    component={ProductManagementCreateNewPage}
    requiredAbilities={[['create', 'product']]}
    rolesWhitelist={[
      'pestix.techniker',
      'pestix.buchhaltung',
      'admin',
      'pestix.niederlassungsleitung',
      'pestix.service-leitung',
    ]}
  />,
  <AuthorizedRoute
    key="/artikelverwaltung/:id/stammdaten"
    path="/artikelverwaltung/:id/stammdaten"
    exact
    component={ProductManagementDetailsPage}
    requiredAbilities={[['show', 'product']]}
    rolesWhitelist={[
      'pestix.techniker',
      'pestix.buchhaltung',
      'admin',
      'pestix.niederlassungsleitung',
      'pestix.service-leitung',
    ]}
  />,
  <AuthorizedRoute
    key="/artikelverwaltung/:id/dokumente"
    path="/artikelverwaltung/:id/dokumente"
    exact
    component={ProductManagementDocumentsPage}
    requiredAbilities={[['show', 'product']]}
    rolesWhitelist={[
      'pestix.techniker',
      'pestix.buchhaltung',
      'admin',
      'pestix.niederlassungsleitung',
      'pestix.service-leitung',
    ]}
  />,

  /* DOKUMENTATION */
  <AuthorizedRoute
    key="/dokumentation"
    path="/dokumentation"
    redirectTo="/dokumentation/uebersicht"
    exact
    component={Documentation}
    // TODO verify once implemented
    requiredAbilities={[['show', 'documentation']]}
  />,
  <AuthorizedRoute
    key="/dokumentation/uebersicht"
    path="/dokumentation/uebersicht"
    exact
    component={Documentation}
    requiredAbilities={[['show', 'documentation']]}
  />,
  <AuthorizedRoute
    key="/dokumentation/befaelle-und-maengel"
    path="/dokumentation/befaelle-und-maengel"
    exact
    component={DocumentationIncidents}
    // TODO verify once implemented
    requiredAbilities={[
      ['show', 'deficiency'],
      ['show', 'infestation'],
      ['show', 'documentation'],
    ]}
  />,
  <AuthorizedRoute
    key="/dokumentation/monitore"
    path="/dokumentation/monitore"
    exact
    component={DocumentationMonitors}
    // TODO verify once implemented
    requiredAbilities={[
      ['show', 'monitor'],
      ['show', 'documentation'],
    ]}
  />,
  <AuthorizedRoute
    key="/dokumentation/berichte"
    path="/dokumentation/berichte"
    exact
    component={DocumentationReportings}
    // TODO more detailed permission, once it's implemented?
    requiredAbilities={[['show', 'documentation']]}
  />,

  /* MONITORE */
  <AuthorizedRoute
    key="/monitore"
    path="/monitore"
    exact
    component={MonitorsListPage}
    requiredAbilities={[['show', 'monitor']]}
  />,
  <AuthorizedRoute
    key="/monitore/neu"
    path="/monitore/neu"
    exact
    component={MonitorCreatePage}
    requiredAbilities={[['create', 'monitor']]}
  />,
  <AuthorizedRoute
    key="/monitore/:id"
    path="/monitore/:id"
    exact
    component={MonitorDetailsPage}
    requiredAbilities={[['show', 'monitor']]}
  />,
  // <AuthorizedRoute
  //   key="/monitore/:id/historie"
  //   path="/monitore/:id/historie"
  //   exact
  //   component={MonitorHistoryPage}
  //   requiredAbilities={[['show', 'monitor.history']]}
  // />,

  /* AUFTRÄGE UND VERTRÄGE */
  <AuthorizedRoute
    key="/angebote"
    path="/angebote"
    exact
    component={OffersListPage}
    requiredAbilities={[['show', 'orderFile']]}
  />,
  <AuthorizedRoute
    key="/angebote/neu"
    path="/angebote/neu"
    exact
    component={ProcessCreatePage}
    requiredAbilities={[['create', 'orderFile']]}
  />,
  <AuthorizedRoute
    key="/angebote/:id/stammdaten"
    path="/angebote/:id/stammdaten"
    exact
    component={ProcessDetailsPage}
    requiredAbilities={[['show', 'orderFile']]}
  />,
  <AuthorizedRoute
    key="/angebote/:id/stammdaten/positionen"
    path="/angebote/:id/stammdaten/positionen"
    exact
    component={ProcessItemsPage}
    requiredAbilities={[['show', 'orderFile']]}
  />,
  <AuthorizedRoute
    key="/auftraege"
    path="/auftraege"
    exact
    component={ProcessesListPage}
    requiredAbilities={[['show', 'orderFile']]}
  />,
  <AuthorizedRoute
    key="/auftraege/neu"
    path="/auftraege/neu"
    exact
    component={ProcessCreatePage}
    requiredAbilities={[['create', 'orderFile']]}
  />,
  <AuthorizedRoute
    key="/auftraege/:id/stammdaten"
    path="/auftraege/:id/stammdaten"
    exact
    component={ProcessDetailsPage}
    requiredAbilities={[['show', 'orderFile']]}
  />,
  <AuthorizedRoute
    key="/auftraege/:id/positionen"
    path="/auftraege/:id/positionen"
    exact
    component={ProcessItemsPage}
    requiredAbilities={[['show', 'orderFile']]}
  />,
  <AuthorizedRoute
    key="/auftraege/:id/massnahmen"
    path="/auftraege/:id/massnahmen"
    exact
    component={ProcessJobsPage}
    requiredAbilities={[['show', 'appointment']]}
  />,
  <AuthorizedRoute
    key="/vertragsentwuerfe"
    path="/vertragsentwuerfe"
    exact
    component={ContractDraftsListPage}
    requiredAbilities={[['show', 'contract']]}
  />,
  <AuthorizedRoute
    key="/vertragsentwuerfe/neu"
    path="/vertragsentwuerfe/neu"
    exact
    component={ContractDetailsPage}
    requiredAbilities={[['create', 'contract']]}
  />,
  <AuthorizedRoute
    key="/vertragsentwuerfe/:id/stammdaten"
    path="/vertragsentwuerfe/:id/stammdaten"
    exact
    component={ContractDetailsPage}
    requiredAbilities={[['show', 'contract']]}
  />,
  <AuthorizedRoute
    key="/vertragsentwuerfe/:id/positionen"
    path="/vertragsentwuerfe/:id/positionen"
    exact
    component={ContractPositionsPage}
    requiredAbilities={[['show', 'contract']]}
  />,
  // <AuthorizedRoute
  //   key="/vertragsentwuerfe/:id/verlauf"
  //   path="/vertragsentwuerfe/:id/verlauf"
  //   exact
  //   component={ContractHistoryPage}
  //   requiredAbilities={[['show', 'contract']]}
  // />,
  <AuthorizedRoute
    key="/vertragsentwuerfe/:id/dokumente"
    path="/vertragsentwuerfe/:id/dokumente"
    exact
    component={ContractDocumentsPage}
    requiredAbilities={[['show', 'contract.documents']]}
  />,
  <AuthorizedRoute
    key="/vertraege/:id/stammdaten"
    path="/vertraege/:id/stammdaten"
    exact
    component={ContractDetailsPage}
    requiredAbilities={[['show', 'contract']]}
  />,
  <AuthorizedRoute
    key="/vertraege"
    path="/vertraege"
    exact
    component={ContractsListPage}
    requiredAbilities={[['show', 'contract']]}
  />,
  <AuthorizedRoute
    key="/vertraege/neu"
    path="/vertraege/neu"
    exact
    component={ContractDetailsPage}
    requiredAbilities={[['create', 'contract']]}
  />,
  <AuthorizedRoute
    key="/vertraege/:id/positionen"
    path="/vertraege/:id/positionen"
    exact
    component={ContractPositionsPage}
    requiredAbilities={[['show', 'contract']]}
  />,
  <AuthorizedRoute
    key="/vertraege/:id/dokumente"
    path="/vertraege/:id/dokumente"
    exact
    component={ContractDocumentsPage}
    requiredAbilities={[['show', 'contract.documents']]}
  />,
  // <AuthorizedRoute
  //   key="/vertraege/:id/verlauf"
  //   path="/vertraege/:id/verlauf"
  //   exact
  //   component={ContractHistoryPage}
  //   requiredAbilities={[['show', 'contract.history']]}
  // />,

  /* BUCHHALTUNG */
  // <AuthorizedRoute
  //   key="/buchhaltung/rechnungsvorschlaege"
  //   path="/buchhaltung/rechnungsvorschlaege"
  //   exact
  //   component={InvoiceProposalsPage}
  //   requiredAbilities={[]}
  // />,
  // <AuthorizedRoute
  //   key="/buchhaltung/offene-posten"
  //   path="/buchhaltung/offene-posten"
  //   redirectTo="/buchhaltung/offene-posten/offen"
  //   exact
  //   component={OpenItemsPage}
  // />,
  // <AuthorizedRoute
  //   key="/buchhaltung/offene-posten/offen"
  //   path="/buchhaltung/offene-posten/offen"
  //   exact
  //   component={OpenItemsPage}
  // />,
  // <AuthorizedRoute
  //   key="/buchhaltung/umsaetze"
  //   path="/buchhaltung/umsaetze"
  //   redirectTo="/buchhaltung/umsaetze/zahlungsvorschlaege"
  //   exact
  //   component={PaymentProposalsPage}
  // />,
  // <AuthorizedRoute
  //   key="/buchhaltung/umsaetze/zahlungsvorschlaege"
  //   path="/buchhaltung/umsaetze/zahlungsvorschlaege"
  //   exact
  //   component={PaymentProposalsPage}
  // />,
  <AuthorizedRoute
    key="/buchhaltung/export"
    path="/buchhaltung/export"
    exact
    component={AccountingExportPage}
    requiredAbilities={[['show', 'accounting.export']]}
  />,

  /* EINSTELLUNGEN */
  <AuthorizedRoute
    key="/systemeinstellungen/allgemein"
    path="/systemeinstellungen/allgemein"
    exact
    component={SettingsGeneralPage}
    requiredAbilities={[['show', 'settings.general']]}
    rolesWhitelist={['admin']}
  />,
  <AuthorizedRoute
    key="/systemeinstellungen/niederlassungen"
    path="/systemeinstellungen/niederlassungen"
    exact
    component={BranchListPage}
    requiredAbilities={[['show', 'branch']]}
    rolesWhitelist={['admin']}
  />,
  <AuthorizedRoute
    key="/systemeinstellungen/servicegebiete"
    path="/systemeinstellungen/servicegebiete"
    exact
    component={ServiceAreaListPage}
    requiredAbilities={[['show', 'serviceArea']]}
    rolesWhitelist={['admin']}
  />,
  <AuthorizedRoute
    key="/systemeinstellungen/schaedlinge"
    path="/systemeinstellungen/schaedlinge"
    exact
    component={SettingsPestsPage}
    requiredAbilities={[['show', 'pest']]}
    rolesWhitelist={['admin']}
  />,
  <AuthorizedRoute
    key="/systemeinstellungen/dokumentation"
    path="/systemeinstellungen/dokumentation"
    exact
    component={SettingsDocumentationPage}
    requiredAbilities={[['show', 'documentation']]}
    rolesWhitelist={['admin']}
  />,
  <AuthorizedRoute
    key="/systemeinstellungen/zahlungsbedingungen"
    path="/systemeinstellungen/zahlungsbedingungen"
    exact
    component={SettingsPaymentTermsPage}
    requiredAbilities={[['show', 'paymentTerms']]}
    rolesWhitelist={['admin']}
  />,
  <AuthorizedRoute
    key="/systemeinstellungen/umsatzsteuer"
    path="/systemeinstellungen/umsatzsteuer"
    exact
    component={SettingsVatPage}
    requiredAbilities={[
      ['show', 'valueAddedTaxRate'],
      ['show', 'valueAddedTaxValue'],
    ]}
    rolesWhitelist={['admin']}
  />,
  <AuthorizedRoute
    key="/systemeinstellungen/einheiten"
    path="/systemeinstellungen/einheiten"
    exact
    component={SettingsUnitsPage}
    requiredAbilities={[['show', 'unit']]}
    rolesWhitelist={['admin']}
  />,
  <AuthorizedRoute
    key="/systemeinstellungen/monitortypen"
    path="/systemeinstellungen/monitortypen"
    exact
    component={SettingsMonitorTypesPage}
    requiredAbilities={[['show', 'monitorTypes']]}
    rolesWhitelist={['admin']}
  />,
  <AuthorizedRoute
    key="/systemeinstellungen/texte"
    path="/systemeinstellungen/texte"
    exact
    component={SettingsTextsPage}
    requiredAbilities={[['show', 'settings.general']]}
    rolesWhitelist={['admin']}
  />,

  /* Unused */
  // <AuthorizedRoute
  //   key="/servicebereiche"
  //   path="/servicebereiche"
  //   exact
  //   component={ServiceAreas}
  // />,

  // <AuthorizedRoute key="/reporting" path="/reporting" exact component={Reporting} />,

  // <AuthorizedRoute
  //   key="/artikel"
  //   path="/artikel"
  //   exact
  //   component={Items}
  //   requiredRights={[]}
  // />,

  // <AuthorizedRoute path="/kalender" key="/kalender" exact component={Calendar} />,

  // <AuthorizedRoute
  //   key="/zeiterfassung"
  //   path="/zeiterfassung"
  //   exact
  //   component={TimeTracking}
  // />,
  // <AuthorizedRoute key="/mein_konto" path="/mein_konto" exact component={MyAccount} />,
];

export default routes;

export type RouteItem = {
  path: string;
  requiredAbilities: [Actions, Subject][];
};

export const getRouteItems = (): RouteItem[] => {
  return routes.flatMap(
    (routeElement): RouteItem => ({
      path: routeElement.props.path,
      requiredAbilities: routeElement.props.requiredAbilities,
    })
  );
};
