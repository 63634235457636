import parseLanguages from 'base-shell/lib/utils/locale';
import { lazy } from 'react';
import locales from 'config/locales';
import { selectMappings } from 'config/sharedConfig';
import routes from './routes';
import themes from './themes';

const fileFormats = {
  image: [
    'image/avif',
    'image/avif-sequence',
    'image/bmp',
    'image/gif',
    'image/heic',
    'image/heic-sequence',
    'image/heif',
    'image/heif-sequence',
    'image/jpeg',
    'image/png',
    'image/svg+xml',
    'image/tiff',
    'image/webp',
  ],
  audio: ['audio/midi', 'audio/mp4', 'audio/mpeg', 'audio/ogg', 'audio/x-wav'],
  video: [
    'video/mp4',
    'video/mpeg',
    'video/ogg',
    'video/quicktime',
    'video/webm',
    'video/x-flv',
    'video/x-matroska',
    'video/x-msvideo',
  ],
  document: [
    'application/msword',
    'application/pdf',
    'application/rtf',
    'application/vnd.ms-excel',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.presentationml.template',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
    'text/markdown,text/plain',
  ],
  other: [
    'application/x-7z-compressed',
    'application/x-rar-compressed',
    'application/x-x509-ca-cert',
    'application/xml',
    'application/zip',
    'text/calendar',
    'text/csv',
    'text/html',
    'text/vcard',
  ],
  size: '51200',
};

const config = {
  auth: {
    signInURL: '/anmeldung',
  },
  routes,
  locale: {
    locales,
    defaultLocale: parseLanguages(['de'], 'de'),
    onError: () => {
      // console.log('');
    },
  },
  components: {
    Menu: lazy(() => import('components/Menu/Menu')),
  },
  menu: {
    width: 264,
    offlineIndicatorHeight: 12,
    MenuContent: lazy(() => import('components/Menu/MenuContent')),
    MenuHeader: lazy(() => import('components/Menu/MenuHeader')),
  },
  theme: {
    themes,
    defaultThemeID: 'default',
    defaultIsDarkMode: false,
    defaultIsRTL: false, // change this to true for default Right to Left Language support
  },
  pages: {
    LandingPage: null,
    PageNotFound: lazy(() => import('../components/PageNotFound/PageNotFound')),
  },
  toolbar: {
    height: '72px',
  },
  formSpacing: {
    rowSpacing1: 3,
    columnSpacing1: 6,
    rowSpacing2: 3,
    columnSpacing2: 3,
    rowSpacing3: 2,
    columnSpacing3: 2,
  },
  whitelistFormats: {
    products: ['application/pdf', ...fileFormats.image],
  },
  selectMappings,
  debug: false,
  mediaHost: window.localStorage.getItem('activeEnvUrl'),
  mediaPrefix: 'api/media',
};

export default config;
