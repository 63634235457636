import { createSlice } from '@reduxjs/toolkit';
import { PageContextEnum } from 'features/enums';

export type ListSearchTermsState = {
  pages: {
    [key in PageContextEnum]: {
      searchTerm?: string;
    };
  };
};

const initialState: ListSearchTermsState = {
  pages: Object.fromEntries(
    Object.entries(PageContextEnum).map((value: [string, PageContextEnum]) => [
      value[1] as any,
      { searchTerm: undefined },
    ])
  ),
};

export const listSearchTermsSlice = createSlice({
  name: 'listSearch',
  initialState,
  reducers: {
    listSearch: (state, action) => {
      const { searchTerm, context } = action.payload;
      state.pages[context].searchTerm = searchTerm;
    },
  },
});

// Action creators are generated for each case reducer function
export const { listSearch } = listSearchTermsSlice.actions;

export default listSearchTermsSlice.reducer;
