import { createSlice } from '@reduxjs/toolkit';
import { v4 } from 'uuid';

export type InvoiceProposal = {
  customer: {
    id: string;
    name: string;
  };
  title: string;
  id: string;
  amount: string;
  type: 'vertrag' | 'auftrag' | 'lieferung';
  referenceId: number;
  date: string;
  isConsolidated?: boolean;
};

export type InvoiceProposalsState = {
  filter: {
    searchValue: string;
    filterValues: Array<'vertrag' | 'auftrag' | 'lieferung'>;
  };
  src: {
    filteredItems: Array<InvoiceProposal>;
    items: Array<InvoiceProposal>;
    selectedItems: Array<InvoiceProposal>;
    selectedCustomerIds: Array<string>;
  };
  dst: {
    items: Array<InvoiceProposal>;
    selectedItems: Array<InvoiceProposal>;
    selectedCustomerIds: Array<string>;
    consolidated: Record<string, Record<string, Array<InvoiceProposal>>>;
  };
};

const initialState: InvoiceProposalsState = {
  filter: {
    searchValue: '',
    filterValues: [],
  },
  src: {
    filteredItems: [],
    items: [],
    selectedItems: [],
    selectedCustomerIds: [],
  },
  dst: {
    items: [],
    selectedItems: [],
    selectedCustomerIds: [],
    consolidated: {},
  },
};

export const invoiceProposalsSlice = createSlice({
  name: 'invoiceProposals',
  initialState,
  reducers: {
    initInvoiceProposals: (state, action) => {
      const { data } = action.payload;
      state.src.items = data;
      state.src.filteredItems = data;
    },
    filterInvoiceProposals: (state, action) => {
      const { searchValue, filterValues } = action.payload;
      state.filter.searchValue = searchValue;
      state.filter.filterValues = filterValues;

      if (filterValues.length)
        state.src.filteredItems = state.src.items.filter((el) =>
          filterValues.includes(el.type)
        );

      if (searchValue)
        state.src.filteredItems = state.src.items.filter(
          (item) =>
            item.title.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.customer.name.toLowerCase().includes(searchValue.toLowerCase())
        );

      if (!searchValue && !filterValues.length) state.src.filteredItems = state.src.items;
    },
    selectInvoiceProposalsGroup: (state, action) => {
      const { customerId, scope } = action.payload;
      state[scope].selectedCustomerIds.push(customerId);

      let customerItems;
      if (scope === 'dst') {
        customerItems = state.dst.items.filter((el) => {
          return el.customer.id === customerId && !el.isConsolidated;
        });
      } else {
        customerItems = state.src.filteredItems.filter((el) => {
          return el.customer.id === customerId;
        });
      }
      const newItems = customerItems.filter(
        (item) => !state[scope].selectedItems.find((el) => el.id === item.id)
      );
      state[scope].selectedItems = [...state[scope].selectedItems, ...newItems];
    },
    deselectInvoiceProposalsGroup: (state, action) => {
      const { customerId, scope } = action.payload;
      state[scope].selectedItems = state[scope].selectedItems.filter(
        (el) => el.customer.id !== customerId
      );
      state[scope].selectedCustomerIds = state[scope].selectedCustomerIds.filter(
        (el) => el !== customerId
      );
    },
    selectInvoiceProposal: (state, action) => {
      const { id, scope } = action.payload;
      const elem = state[scope].items.find((item) => item.id === id);
      const index = state[scope].selectedItems.findIndex((item) => item.id === id);
      if (index < 0) state[scope].selectedItems.push(elem);
    },
    deselectInvoiceProposal: (state, action) => {
      const { id, scope } = action.payload;
      const index = state[scope].selectedItems.findIndex((item) => item.id === id);
      state[scope].selectedItems.splice(index, 1);
    },
    assignSelectedInvoiceProposals: (state) => {
      state.dst.items = [...state.dst.items, ...state.src.selectedItems];
      state.src.items = state.src.items.filter(
        (item) => !state.src.selectedItems.find((el) => el.id === item.id)
      );
      state.src.selectedItems = [];
      state.src.selectedCustomerIds = [];

      // reset filter
      if (state.filter.filterValues.length)
        state.src.filteredItems = state.src.items.filter((el) =>
          state.filter.filterValues.includes(el.type)
        );

      if (state.filter.searchValue)
        state.src.filteredItems = state.src.items.filter(
          (item) =>
            item.title.toLowerCase().includes(state.filter.searchValue.toLowerCase()) ||
            item.customer.name
              .toLowerCase()
              .includes(state.filter.searchValue.toLowerCase())
        );

      if (!state.filter.searchValue && !state.filter.filterValues.length)
        state.src.filteredItems = state.src.items;
    },
    unassignSelectedInvoiceProposals: (state) => {
      state.src.items = [...state.src.items, ...state.dst.selectedItems];
      state.dst.items = state.dst.items.filter(
        (item) => !state.dst.selectedItems.find((el) => el.id === item.id)
      );

      // reset filter
      if (state.filter.filterValues.length)
        state.src.filteredItems = state.src.items.filter((el) =>
          state.filter.filterValues.includes(el.type)
        );

      if (state.filter.searchValue)
        state.src.filteredItems = state.src.items.filter(
          (item) =>
            item.title.toLowerCase().includes(state.filter.searchValue.toLowerCase()) ||
            item.customer.name
              .toLowerCase()
              .includes(state.filter.searchValue.toLowerCase())
        );

      if (!state.filter.searchValue && !state.filter.filterValues.length)
        state.src.filteredItems = state.src.items;

      state.dst.selectedItems = [];
      state.dst.selectedCustomerIds = [];
    },
    selectAssignedInvoiceForConsolidation: (state, action) => {
      const { customerId, consolidationId } = action.payload;

      if (!state.dst.consolidated[customerId]) {
        state.dst.consolidated[customerId] = {};
      }

      if (state.dst.consolidated[customerId][consolidationId]) {
        state.dst.consolidated[customerId][consolidationId].push(
          ...state.dst.selectedItems.filter((item) => item.customer.id === customerId)
        );
      } else {
        state.dst.consolidated[customerId][v4()] = state.dst.selectedItems.filter(
          (item) => item.customer.id === customerId
        );
      }

      state.dst.items = state.dst.items.map((item) => {
        if (
          item.customer.id === customerId &&
          !!state.dst.selectedItems.find((el) => el.id === item.id)
        ) {
          item.isConsolidated = true;
        }
        return item;
      });

      state.dst.selectedItems = state.dst.selectedItems.filter(
        (el) => el.customer.id !== customerId
      );

      state.dst.selectedCustomerIds = [];
    },
    unselectAssignedInvoiceForConsolidation: (state, action) => {
      const { id, customerId, consolidationId } = action.payload;
      state.dst.items = state.dst.items.map((item) => {
        if (item.id === id) {
          item.isConsolidated = false;
        }
        return item;
      });

      state.dst.consolidated[customerId][consolidationId] = state.dst.consolidated[
        customerId
      ][consolidationId].filter((item) => item.id !== id);

      if (state.dst.consolidated[customerId][consolidationId].length === 0) {
        delete state.dst.consolidated[customerId][consolidationId];
      }

      if (Object.keys(state.dst.consolidated[customerId]).length === 0) {
        delete state.dst.consolidated[customerId];
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  initInvoiceProposals,
  filterInvoiceProposals,
  deselectInvoiceProposal,
  selectInvoiceProposal,
  selectInvoiceProposalsGroup,
  deselectInvoiceProposalsGroup,
  assignSelectedInvoiceProposals,
  unassignSelectedInvoiceProposals,
  selectAssignedInvoiceForConsolidation,
  unselectAssignedInvoiceForConsolidation,
} = invoiceProposalsSlice.actions;

export default invoiceProposalsSlice.reducer;
