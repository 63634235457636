/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { _Includes } from '../models/_Includes';
import type { ContractPosition } from '../models/ContractPosition';
import type { ListResponse } from '../models/ListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ContractPositionsService {

    /**
     * Get a paginated list of `ContractPosition`s.
     * @returns any A list of `ContractPosition`s.
     * @throws ApiError
     */
    public static getContractPositionsList({
        include,
        pageSize,
        pageNumber,
        filter,
        sort,
    }: {
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
        /** Number of items to return per page. **/
        pageSize?: number,
        /** Number of the page to load. **/
        pageNumber?: number,
        /** Filteroptionen. **/
        filter?: any,
        /** Sortierparameter.
         *
         * Es kann nach mehreren Attributen nacheinander sortiert werden. Dazu
         * werden die zu verwendenen Attribute als Komma-getrenntes Array
         * übergeben.
         *
         * Standardmäßig wird aufsteigend sortiert. Um die Reihenfolge umzukehren,
         * kann ein Minus vor den Attributnamen gesetzt werden.
         *
         * Beispiel: `?sort=-birthday,lastname` => Jüngste zuerst, Personen mit
         * gleichem Geburtstag werden nach Name sortiert.
         *  **/
        sort?: Array<string>,
    }): CancelablePromise<(ListResponse & {
        data?: Array<ContractPosition>;
    })> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/contract-positions',
            query: {
                'include': include,
                'page[size]': pageSize,
                'page[number]': pageNumber,
                'filter': filter,
                'sort': sort,
            },
            errors: {
                400: `Bad request. The request is invalid.`,
                401: `Unauthorized.`,
            },
        });
    }

    /**
     * Create new `ContractPosition`.
     * @returns any A single `ContractPosition`.
     * @throws ApiError
     */
    public static createContractPosition({
        requestBody,
    }: {
        /** A `ContractPosition` to create. **/
        requestBody?: ContractPosition,
    }): CancelablePromise<{
        data?: ContractPosition;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/contract-positions',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized.`,
                422: `Unprocessable entity.`,
            },
        });
    }

    /**
     * Delete the ContractPosition identified by the given ID.
     * @returns void
     * @throws ApiError
     */
    public static deleteContractPosition({
        id,
        force,
    }: {
        /** ID of the resource. **/
        id: string,
        /** Erzwingt die Durchführung des Requests, zum Beispiel endgültige Löschung
         * von soft deleted Entitäten.
         *
         * Aktiviert durch die Werte `true`, `1` oder `yes`. **/
        force?: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/contract-positions/{id}',
            path: {
                'id': id,
            },
            query: {
                'force': force,
            },
        });
    }

    /**
     * Get the ContractPosition identified by the given ID.
     * @returns any A single `ContractPosition`.
     * @throws ApiError
     */
    public static getContractPositionId({
        id,
        include,
    }: {
        /** ID of the resource. **/
        id: string,
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
    }): CancelablePromise<{
        data?: ContractPosition;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/contract-positions/{id}',
            path: {
                'id': id,
            },
            query: {
                'include': include,
            },
        });
    }

    /**
     * Update an ContractPosition.
     * @returns any A single `ContractPosition`.
     * @throws ApiError
     */
    public static updateContractPosition({
        id,
        requestBody,
    }: {
        /** ID of the resource. **/
        id: string,
        /** The `ContractPosition` object data to update.
         * - Send changed attributes only to minimize the risk of collisions during parallel updates. **/
        requestBody?: ContractPosition,
    }): CancelablePromise<{
        data?: ContractPosition;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/contract-positions/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}