import { Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import logoBackground from 'assets/img/GreyMouse.svg';
import logoColored from 'assets/img/pestix_farbig.svg';
import { useAuth } from 'base-shell/lib/providers/Auth';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { PestixTheme } from 'components/Input/FormikControl/ts/types';
import { DisplayFormikState } from 'components/Debug/DisplayFormikState';
import { serializeError } from 'serialize-error';

const useStyles = makeStyles((theme: PestixTheme) => ({
  outer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  wrapper: {
    height: '100%',
    width: '100%',
    backgroundColor: '#ffffff',
    backgroundImage: `url(${logoBackground})`,
    backgroundSize: '45%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left bottom',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-between',
    },
  },
  right: {
    width: '463px',
    marginLeft: 'calc(50% + 2.5rem)',
    [theme.breakpoints.down('lg')]: {
      margin: '0 auto',
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(3),
      width: '100%',
      margin: '0 auto',
      maxWidth: '463px',
    },
  },
  text: {
    color: theme.palette.primary.grey4,
  },
  emojiWrapper: {
    width: 60,
    height: 60,
    position: 'relative',
    marginBottom: theme.spacing(4.5),
  },
  emoji: {
    position: 'absolute',
    top: 0,
    left: 0,
    margin: 0,
    padding: 0,
    fontSize: '60px',
    lineHeight: '60px',
    maxWidth: 60,
    maxHeight: 60,
    width: '100%',
    height: '100%',
    display: 'block',
  },
  info: {
    padding: theme.spacing(0, 0, 3, 0),
    margin: theme.spacing(0, 3, 0, 0),
    display: 'flex',
    alignItems: 'flex-end',
    fontSize: '12px',
    lineHeight: '12px',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(3),
    },
    '& li': {
      listStyle: 'none',
      '& + li': {
        marginLeft: theme.spacing(2),
      },
    },
  },
  footerLink: {
    color: '#8493ab',
    textDecoration: 'none',
    transition: 'all .35s',
    '&:hover': {
      color: '#000000',
      transition: 'all .35s',
    },
  },
  logoMobile: {
    marginTop: theme.spacing(5),
    marginLeft: theme.spacing(3),
  },
  copyRight: {
    margin: 0,
    marginLeft: theme.spacing(3),
    fontSize: 12,
    lineHeight: 1,
    color: theme.palette.primary.grey4,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  heading: {
    fontSize: '100px',
    fontWeight: 'bold',
    lineHeight: 1,
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      fontSize: '80px',
    },
  },
  error: {
    fontSize: '32px',
    fontWeight: 'bold',
    lineHeight: 1,
    color: theme.palette.primary.grey4,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
    },
  },
  buttonWrapper: {
    marginTop: theme.spacing(7),
    '& > Button': {
      '& + Button': {
        marginLeft: theme.spacing(3),
      },
    },
  },
}));

type ErrorPageProps = {
  error?: any;
};

const ErrorPage = ({ error }: ErrorPageProps) => {
  const classes = useStyles();
  const history = useHistory();
  const auth = useAuth();

  return (
    <div className={classes.outer}>
      <div className={classes.wrapper}>
        <div>
          {!auth.auth.isAuthenticated && (
            <img src={logoColored} alt="#" className={classes.logoMobile} />
          )}
        </div>
        <div className={classes.right}>
          <div className={classes.emojiWrapper}>
            <span className={classes.emoji}>&#128555;</span>
          </div>
          <Typography variant="h1" className={classes.heading}>
            Oh Nein!
          </Typography>
          <Typography variant="h2" className={classes.error}>
            Ein Fehler ist aufgetreten
          </Typography>
          <Typography variant="body1" className={classes.text}>
            Die Seite, die Du besuchen möchtest, kann aufgrund eines Fehlers nicht
            aufgerufen werden.
          </Typography>
          <div className={classes.buttonWrapper}>
            {!auth.auth.isAuthenticated ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  history.push('/anmeldung');
                }}
              >
                Zum Login
              </Button>
            ) : (
              <>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Zurück
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    history.push('/dashboard');
                  }}
                >
                  Dashboard
                </Button>
              </>
            )}
          </div>
        </div>
        <DisplayFormikState props={serializeError(error)} />

        <div className={classes.footer}>
          <p className={classes.copyRight}>&copy; Pestix 2021</p>
          <ul className={classes.info}>
            <li>
              <Link to="/home" className={classes.footerLink}>
                Impressum{' '}
              </Link>
            </li>
            <li>
              <Link to="/home" className={classes.footerLink}>
                Datenschutz
              </Link>
            </li>
            <li>
              <Link to="/home" className={classes.footerLink}>
                Nutzungsbedinungen
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default ErrorPage;
