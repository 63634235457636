import { configureStore } from '@reduxjs/toolkit';
import appBarReducer from 'features/appBar/appBarSlice';
import listColumnsReducer from 'features/listColumns/listColumnsSlice';
import listFilterReducer from 'features/listFilter/listFilterSlice';
import listSearchReducer from 'features/listSearch/listSearchSlice';
import heartbeatReducer from 'features/heartbeat/heartbeatSlice';
import invoiceProposalsReducer from 'features/invoiceProposals/invoiceProposalsSlice';
import paymentProposalsReducer from 'features/paymentProposals/paymentProposalsSlice';
import openItemsReducer from 'features/openItems/openItemsSlice';

export const store = configureStore({
  reducer: {
    listFilter: listFilterReducer,
    listColumns: listColumnsReducer,
    listSearchTerms: listSearchReducer,
    appBar: appBarReducer,
    heartbeat: heartbeatReducer,
    invoiceProposals: invoiceProposalsReducer,
    paymentProposals: paymentProposalsReducer,
    openItems: openItemsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['listColumns/initColumns'],
        // Ignore these field paths in all actions
        ignoredActionPaths: ['meta.arg', 'payload.timestamp'],
        // Ignore these paths in the state
        ignoredPaths: ['listColumns'],
      },
    }),
});

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
