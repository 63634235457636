import ErrorBoundaryContext from 'app/ErrorBoundaryContext';
import { Component } from 'react';
import ErrorPage from 'components/ErrorBoundary/ErrorPage';

type ErrorBoundaryState = {
  hasError: boolean;
  error?: any;
};

class ErrorBoundary extends Component<any, ErrorBoundaryState> {
  state = { hasError: false, error: '' };

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error) {
    // eslint-disable-next-line no-console
    // console.log({ error });
    this.setState({ hasError: true, error });
  }

  triggerError = (error) => {
    this.setState({ hasError: true, error });
    return true;
  };

  resetError = () => this.setState({ hasError: false, error: null });

  render() {
    return (
      <ErrorBoundaryContext.Provider value={{ triggerError: this.triggerError }}>
        {this.state.hasError ? (
          <ErrorPage error={this.state.error} />
        ) : (
          this.props.children
        )}
      </ErrorBoundaryContext.Provider>
    );
  }
}

export default ErrorBoundary;
